import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants'

import forgotPasswordReducer, { forgotPasswordActions, forgotPasswordSelectors, STATE_KEY }  from 'pages/ForgotPasswordPage/modules/forgotPassword.slice';

import forgotPasswordSaga from '../modules/forgotPassword.saga';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import injectReducer from 'utils/injectReducer';


const mapStateToProps = (state, props) => ({
    isLoading: forgotPasswordSelectors.selectForgotPasswordPending(state)
});

const withConnect = connect(mapStateToProps, forgotPasswordActions);

const withSaga = injectSaga({
    key: 'forgotPasswordSaga',
    saga: forgotPasswordSaga,
    mode: DAEMON,
});

const withReducer = injectReducer({
    key: STATE_KEY,
    reducer: forgotPasswordReducer
});

export default compose(
    withSaga,
    withReducer,
    withConnect
)(ForgotPasswordForm);

