import React from 'react';
import { Icon, Checkbox } from 'antd';

import FormFactory from 'components/FormFactory';
import { ForgotPasswordLink, WhiteText } from 'components/AuthPageBase';
import { FormattedMessage } from 'react-intl';
import {intl} from 'containers/IntlGlobalProvider';

import messages from './messages';


const Form = FormFactory.create({ name: 'loginForm' });

const LoginForm = (props) => {

	const onSubmit = values => {
		props.login(values);
	};

	const onForgotPasswordClick = () => {
		return props.push('/forgotPassword');
	};

	const fields = [
		{
			name:        'username',
			type:        'text',
			rules:       [{ required: true, message: 'Please input your username' }],
			// initialValue: 'nadav@biot-med.com',
			prefix:      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
			placeholder: 'Username',
			autoFocus:   true
		},
		{
			name:        'password',
			type:        'password',
			rules:       [{ required: true, message: 'Please input your Password!' }],
			// initialValue: "Abc12345",
			prefix:      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />,
			placeholder: 'Password'
		},
		//remember me removed - UMS.loginWithToken() saves to localStorage hard coded, making "remember me" behavior even when unchecked
		/*{
			type:  'checkbox',
			name:  'remember',
			label: 'Remember me',
			style: { color: 'white' }
		}*/
	];

	const extraButtons = <ForgotPasswordLink onClick={onForgotPasswordClick}>
		<FormattedMessage {...messages.forgotPassword} />
	</ForgotPasswordLink>;

	const { isLoading } = props;
	const submitBtnText = intl.formatMessage(messages.login);
	return (
		<Form
			onSubmit={onSubmit}
			fields={fields}
			submitButtonText={submitBtnText}
			isButtonLoading={isLoading}
			extraButtons={extraButtons}
			style={{ width: 250 }}
		/>
	);
};

export default LoginForm;