import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import messages from "../messages";
import { Checkbox, Button, Typography, Spin, Select, Divider } from "antd";
import ActionSelector, { actions } from "containers/ActionSelector";
import AppConfig from "config/AppConfig";
import ServiceCommandsTable from "./ServiceCommandsTable";
import { toLocalTime } from "utils/momentUtils";
import { COMMAND_STATES } from "../../general/CommandsConstants";

const { Text, Title } = Typography;
const { Option } = Select;

const Separator = styled.div`
  width: 10px;
`;

const generateLogsColumns = [
  {
    title: "Retention Days",
    pathArray: ["parameters", "retentionDays"],
  },
  {
    title: "Logs since",
    pathArray: ["parameters", "logsFromTimestamp"],
    renderFunction: (value) => toLocalTime(value),
  },
];

const LOG_STATUSES = {
  noLogs: "noLogs",
  inProgress: "logGenerationInProgress",
  ready: "logsReady",
};

const determineLogStatus = (isFilesAvailable, command) => {
  if (isFilesAvailable && command?.status === COMMAND_STATES.COMPLETED) {
    return LOG_STATUSES.ready;
  }
  if (command?.status === COMMAND_STATES.IN_PROGRESS) {
    return LOG_STATUSES.inProgress;
  }
  return LOG_STATUSES.noLogs;
};

const ComboBoxContainer = styled.div`
  display: flex;
  alignitems: baseline;
`;

const DeviceLogsTab = (props) => {
  const {
    device,
    downloadDeviceFiles,
    isDownloadDeviceFilesLoading = false,
    onMount,
    onUnmount,
    history,
    intl,
    command,
    availableFiles,
  } = props;

  const availableFileIds = availableFiles.map((file) => file.id);

  const [selectedLogsRequest, setSelectedRequestLogs] = useState([]);
  const [logMode, setLogMode] = useState(null);
  const [retentionDays, setRetentionDays] = useState(7);
  const [daysAgo, setDaysAgo] = useState(7);
  const { formatMessage } = intl;

  const logOptions = [
    {
      label: formatMessage(messages.sw),
      value: "SW",
    },
    {
      label: formatMessage(messages.lumenisX),
      value: "LUM_X",
    },
  ];

  const logStatus = determineLogStatus(availableFileIds.length, command);

  const isLogsSupported =
    device.xplatformVersion > AppConfig.LOG_SUPPORT_VERSION;

  const isLogsV2Supported = device.xplatformVersion > AppConfig.LOG_V2_SUPPORT_VERSION;

  useEffect(() => {
    onMount();
    return () => {
      onUnmount();
    };
  }, []);

  const extractLogMod = () => {
    return logMode instanceof Array ? logMode[0] : logMode;
  }

  return isLogsSupported ? (
    <Spin spinning={isDownloadDeviceFilesLoading} size="large">
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <Text strong>{formatMessage(messages.currentStatus)}:</Text>
          <Separator />
          <Text>{formatMessage(messages[logStatus])}</Text>
        </div>
        <div style={{ display: "flex" }}>
          <ActionSelector
            style={{ width: 300, height: 30 }}
            onChange={(value) => setLogMode(value)}
          />
          <Separator />
          <Button
            styled={{ size: 150 }}
            onClick={() =>
              props.changeLogMode({
                deviceSerialNumber: device.deviceSerialNumber,
                deviceType: device.deviceType.type,
                data: [
                  {
                    deviceLogsState: "SW",
                    mode: extractLogMod(),
                  },
                  {
                    deviceLogsState: "LUM_X",
                    mode: extractLogMod(),
                  },
                ],
              })
            }
            disabled={!logMode}
          >
            {formatMessage(messages.execute)}
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <Text strong>{formatMessage(messages.currentSwMode)}</Text>
          <Separator />
          <Text>{device.swLogsMode}</Text>
        </div>
        <div style={{ display: "flex" }}>
          <Text strong>{formatMessage(messages.currentLumMode)}</Text>
          <Separator />
          <Text>{device.lumXLogsMode}</Text>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            styled={{ size: 150 }}
            disabled={isLogsV2Supported && logStatus !== LOG_STATUSES.ready}
            onClick={() => downloadDeviceFiles({ ids: availableFileIds })}
          >
            {formatMessage(messages.downloadLogs)}
          </Button>
        </div>
        <Divider style={{ backgroundColor: "silver" }}></Divider>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text strong>{formatMessage(messages.requestLogTypes)}</Text>

          <div>
            <Checkbox.Group
              options={logOptions}
              style={{ display: "flex", flexDirection: "column" }}
              onChange={setSelectedRequestLogs}
            ></Checkbox.Group>
          </div>
        </div>
        <ComboBoxContainer>
          <Text strong>{formatMessage(messages.forTheLast)}</Text>
          <Separator />
          <Select defaultValue={[7]} onChange={setDaysAgo}>
            <Option value={7}>{intl.formatNumber(7)}</Option>
            <Option value={30}>{intl.formatNumber(30)}</Option>
          </Select>
          <Separator />
          <Text strong>{formatMessage(messages.days)}</Text>
        </ComboBoxContainer>

        <ComboBoxContainer>
          <Text strong>{formatMessage(messages.retainFor)}</Text>
          <Separator />
          <Select defaultValue={[7]} onChange={setRetentionDays}>
            <Option value={7}>{intl.formatNumber(7)}</Option>
            <Option value={30}>{intl.formatNumber(30)}</Option>
          </Select>
          <Separator />
          <Text strong>{formatMessage(messages.days)}</Text>
        </ComboBoxContainer>
        <div>
          <Button
            styled={{ size: 150 }}
            disabled={
              selectedLogsRequest.length === 0 ||
              (
                isLogsV2Supported &&
                logStatus !== LOG_STATUSES.noLogs
              )
            }
            onClick={() =>
              props.generateLogs({
                retentionDays,
                daysAgo,
                selectedLogsRequest,
                logStates: selectedLogsRequest,
              })
            }
          >
            {formatMessage(messages.requestLogsButton)}
          </Button>
        </div>
      </div>
      <Divider style={{ backgroundColor: "silver" }}></Divider>
      <ServiceCommandsTable
        data={history}
        columns={generateLogsColumns}
        intl={intl}
      />
    </Spin>
  ) : (
    <div>
      <Title level={3}>{formatMessage(messages.logsNotSupported)}</Title>
      <Title level={3}>
        {formatMessage(messages.logsSupportMinimumVersion)}
        {AppConfig.LOG_SUPPORT_VERSION}
      </Title>
    </div>
  );
};

DeviceLogsTab.propTypes = {
  device: PropTypes.object.isRequired,
  downloadDeviceFiles: PropTypes.func.isRequired,
  isDownloadDeviceFilesLoading: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default DeviceLogsTab;
