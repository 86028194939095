import { call, put, takeLatest, select } from "redux-saga/effects";
import BackendService from "services/BackendService";
import { swVersionEntityActions } from "containers/App/modules/entities/swVersions/swVersionEntity.slice";
import { swVersionSelectors } from '../../../../../pages/SwVersions/modules/swVersions.slice';
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { parseFetchAllSuccess } from "containers/App/modules/entities/swVersions/swVersions.parsers";
import {
	createEntitySuccess,
	updateEntityFailure,
} from "containers/App/modules/entities/entities.utils";
import { showSuccessToast } from "../../toasts/toasts.actions";
import { ENTITY_TYPES } from "../entities.constants";
import { calculateFileChecksum } from "../../app.saga";

function* fetchSwVersions(action) {
	yield call(api, {
		apiFn: BackendService.fetchSwVersions,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.swVersion],
		params: action.payload,
		actions: [
			swVersionEntityActions.fetchSwVersionsPending,
			swVersionEntityActions.fetchSwVersionsSuccess,
			swVersionEntityActions.fetchSwVersionsFailure,
		],
	});
}

function* onUploadSwVersion(action) {
	const params = action.payload;
	const file = params.upload[0];
	
	yield put(swVersionEntityActions.createSwVersionPending());
	const uploadUrlData = yield call(BackendService.getUploadUrl);

	const {url, versionId} = uploadUrlData.data;
	const fileToUpload = new File([file.originFileObj], versionId, {
		type: file.originFileObj.type
	});

	yield call(BackendService.uploadFile, url, fileToUpload);

	const checksum = yield call(calculateFileChecksum, fileToUpload);

	const data = {
		comments: params.comments,
		name: params.version,
		deviceTypeId: params.deviceTypeFilter,
		checksum,
		algorithm: 'sha256',
		fileSize: fileToUpload.size,
		fileType: params.fileType,
		installType: params.installTypeSelector,
		supportedVersions: params.supportedVersions,
	};

	yield call(api, {
		apiFn: BackendService.createSwVersion,
		params: { data, versionId },
		actions: [
			swVersionEntityActions.createSwVersionPending,
			swVersionEntityActions.createSwVersionSuccess,
			swVersionEntityActions.createSwVersionFailure,
		],
	});
}

function* updateSwVersion(action) {
	const data = action.payload;
	const versionId = action.payload.versionId
	delete data.versionId;
	
	yield call(api, {
		apiFn:   BackendService.patchSwVersion,
		params:  {data, versionId},
		actions: [
			swVersionEntityActions.updateSwVersionPending,
			swVersionEntityActions.updateSwVersionSuccess,
			swVersionEntityActions.updateSwVersionFailure
		]
	});
}

function* onUpdateSwVersionSuccess() {
	yield put(showSuccessToast({title: 'Version updated successfully'}));
	const swVersionsGrid = yield select(swVersionSelectors.selectSwVersionsGrid);
	yield put(swVersionEntityActions.fetchSwVersions(swVersionsGrid.params));
}

export default function* watchSwVersionsSaga() {
	yield takeLatest(swVersionEntityActions.fetchSwVersions, fetchSwVersions);

	yield takeLatest(swVersionEntityActions.createSwVersion, onUploadSwVersion);
	yield takeLatest(swVersionEntityActions.createSwVersionSuccess,createEntitySuccess, ENTITY_TYPES.SW_VERSIONS);

	yield takeLatest(swVersionEntityActions.updateSwVersion, updateSwVersion);
	yield takeLatest(swVersionEntityActions.updateSwVersionSuccess, onUpdateSwVersionSuccess);
	yield takeLatest(swVersionEntityActions.updateSwVersionFailure, updateEntityFailure);
}
