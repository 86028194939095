import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form as AntForm, Button, Spin } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';

import { FIELD_TYPES } from 'components/FormFactory/constants';
import FormContext from 'components/FormFactory/FormContext';

import CheckboxField from 'components/FormFactory/fields/CheckboxField';
import CustomField from 'components/FormFactory/fields/CustomField';
import EmailField from 'components/FormFactory/fields/EmailField';
import PasswordField from 'components/FormFactory/fields/PasswordField';
import SelectField from 'components/FormFactory/fields/SelectField';
import TextField from 'components/FormFactory/fields/TextField';


const ButtonsFormItem = styled(AntForm.Item)`
	text-align: right;
	
	&.ant-form-item {
		margin-bottom: 0;
	}  
	
	.ant-btn:not(:last-of-type) {
		margin-right: 8px;
	}
`;

const SubmitButton = styled(Button)``;

const FieldTypeToFieldClassMap = {
	[FIELD_TYPES.TEXT]:     TextField,
	[FIELD_TYPES.PASSWORD]: PasswordField,
	[FIELD_TYPES.CHECKBOX]: CheckboxField,
	[FIELD_TYPES.EMAIL]:    EmailField,
	[FIELD_TYPES.SELECT]:   SelectField,
	[FIELD_TYPES.CUSTOM]:   CustomField,

};

const Form = (props) => {
	const {
		      children,
		      contextItem,
		      extraButtons,
		      fields,
		      form,
		      hideSubmitButton,
		      hideCancelButton = true,
		      isButtonLoading  = false,
		      isLoading        = false,
		      layout           = 'vertical',
		      onSubmit,
		      onCancel,
		      onReset,
		      showResetButton,
		      style,
		      submitButtonText,
	      } = props;

	if (!form) throw new Error('FormComponent must be wrapped with AntFormFactory.create()');

	useEffect(() => {
		if (contextItem) {
			form.setFieldsValue(contextItem);
		}
	}, [contextItem]);


	const handleSubmit = e => {
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				onSubmit(values);
			}
		});
	};

	const handleCancel = () => {
		onCancel && onCancel(form);
	}

	const handleReset = () => {
		form.resetFields();
		onReset && onReset(form);
	};

	return (
		<FormContext.Provider value={form}>
			<Spin spinning={isLoading}>
				<AntForm
					onSubmit={handleSubmit}
					style={style}
					layout={layout}>
					{
						fields ? fields.map((fieldProps, key) => {
							const { type, ...classProps } = fieldProps;
							const FieldClass = FieldTypeToFieldClassMap[type];
							return <FieldClass key={key} {...classProps} />;
						}) : _.isFunction(children) ? children(form) : children
					}

					<ButtonsFormItem key="submitButtonAntFormItem">
						{extraButtons}

						{
							showResetButton &&
							<Button onClick={handleReset}>Reset</Button>
						}

						{
							!hideCancelButton &&
							<Button onClick={onCancel}>Cancel</Button>
						}

						{
							!hideSubmitButton &&
							<SubmitButton type="primary"
							              htmlType="submit"
							              loading={isButtonLoading}
							>
								{submitButtonText || 'Submit'}
							</SubmitButton>
						}

					</ButtonsFormItem>

				</AntForm>
			</Spin>
		</FormContext.Provider>
	);
};

Form.propTypes = {
	extraButtons:     PropTypes.element,
	fields:           PropTypes.array,
	hideCancelButton: PropTypes.bool,
	hideSubmitButton: PropTypes.bool,
	isButtonLoading:  PropTypes.bool,
	isLoading:        PropTypes.bool,
	layout:           PropTypes.string,
	onCancel:         PropTypes.func,
	onReset:          PropTypes.func,
	onSubmit:         PropTypes.func,
	showResetButton:  PropTypes.bool,
	style:            PropTypes.object,
	submitButtonText: PropTypes.string,
};

export default React.memo(Form);