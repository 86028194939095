import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'containers/App/modules/async.saga';
import BackendService from 'services/BackendService';
import { usersActions } from 'pages/Users/modules/users.slice';
// import { setEntitiesAction } from 'containers/App/modules/entities/entities.utils';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';


/*
function* fetchPotentialManagers(action) {
	const userGroupId = action.payload;
	yield call(api, {
		apiFn:   BackendService.fetchPotentialManagers,
		params:  userGroupId,
		actions: [
			usersActions.fetchPotentialManagersPending,
			usersActions.fetchPotentialManagersSuccess,
			usersActions.fetchPotentialManagersFailure
		]
	});
}
*/

/*
function* onFetchPotentialManagersSuccess(action) {
	console.log(`onFetchPotentialManagersSuccess saga:`, action);
	yield put(setEntitiesAction({
		entityType: ENTITY_TYPES.USERS,
		entities:   action.payload
	}));
}
*/

export default function* watchUsersSaga() {
	// yield takeLatest(usersActions.fetchPotentialManagers, fetchPotentialManagers);
	// yield takeLatest(usersActions.fetchPotentialManagersSuccess, onFetchPotentialManagersSuccess);
}