import React from 'react';
import EntityGridToolbar from 'containers/Grid/components/EntityGridToolbar';
import { MODAL_TYPES } from 'containers/ModalRoot/components/constants';
import FormField from 'components/FormFactory/FormField';
import { PERMISSIONS } from 'services/PermissionsService';

import UserGroupSelector from 'containers/UserGroupSelector';


const UsersGridToolbar = (props) => {
	const {
		      clearEditedUser,
		      setGridParams,
          grid,
          refreshGrid,
		      showAddNew,
		      showModal
	      } = props;

	const onFiltersChange = (filterValues) => {
		const { userGroupFilter } = filterValues;
		const filters = {
			groupId: userGroupFilter
		};

		setGridParams({
			...grid.params,
			pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
			filters
		});
	};


	const onResetFilters = (form) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
			search:     '',
			filters:    {}
		});
	};

	const onAddNew = () => {
		clearEditedUser();
		showModal({
			modalType:  MODAL_TYPES.CREATE_USER_MODAL,
			modalProps: {
				title:  "Create User",
				footer: null,
				width:  1100,
			},
		});
	};

	const onSearchChange = (value) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1 }, //return to page 1 on new search
			search:     value
		});
	};

	const filterFormElements = (
		<>
			<FormField
				name="userGroupFilter"
				placeholder="Select User Group..."
				style={{ minWidth: 200 }}>
				<UserGroupSelector allowedOnly={false} />
			</FormField>
		</>
	);

	return (
		<EntityGridToolbar
			onResetFilters={onResetFilters}
			onAddNew={onAddNew}
      onFiltersChange={onFiltersChange}
      onRefresh={refreshGrid}
			onSearchChange={onSearchChange}
			filterFormElements={filterFormElements}
			showAddNew={showAddNew}
			addNewPermission={PERMISSIONS.UI_CREATE_USER}
		/>
	);
};

UsersGridToolbar.propTypes = {};

export default React.memo(UsersGridToolbar);