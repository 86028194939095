import React, { useEffect, useState } from "react";
import { Button } from "antd";
import _ from "lodash";
import { withErrorBoundary } from "components/ErrorBoundary";
import DevicesGridContainer from "pages/Devices/containers/DevicesGridContainer";
import styled from "styled-components";
import AssignAllRenderer from "./AssignAllRenderer";
import WarningModal from "./WarningModal";
import { deviceGridColumns } from "./DeviceGridColumns";

const PaddedDiv = styled.div`
  padding-bottom: 1.5em;
`;

const UpdateAssignedDevicesButton = styled(Button).attrs({ type: "primary" })`
  float: right;
`;

const BoldText = styled.div`
  font-weight: 700;
`;

const GridTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`;

const AssignDevicesModal = (props) => {
  const {
    groupId,
    assignedDevices,
    setAssignedDevices,
    canAssignDevices = true,
    updateAssignedDevices,
    currentDeviceIds,
    updateAssignedDevicesWithAllMode,
    groupName,
    isAtLeastOneDeviceAttachedToComposedGroup,
    resetAssignedDevices,
    devicesGrid,
  } = props;

  const [isAssignedAllMode, setIsAssignedAllMode] = useState(false);
  const [excludedFromAll, setExcludedFromAll] = useState([]);
  const [prevSelectedRows, setPrevSelectedRows] = useState([]);
  const [warningModalProps, setWarningModalProps] = useState({
    visible: false,
    headerText: "",
    warningText: "",
    areYouSureText: "",
  });

  const selectedRowKeys = isAssignedAllMode
    ? _.difference(currentDeviceIds, excludedFromAll)
    : _.map(assignedDevices, (device) => device.id);

  useEffect(() => {
    return resetAssignedDevices;
  }, []);

  useEffect(() => {
    setPrevSelectedRows(assignedDevices);
  }, [assignedDevices]);

  const onUpdateDevicesAssignment = () => {
    if (isAssignedAllMode) {
      const { filters, search } = devicesGrid.params;
      const deviceTypes = filters["deviceInfo.deviceType"];
      const locations = filters["customerDetails.address.location"];

      updateAssignedDevicesWithAllMode({
        excludeDevices: excludedFromAll,
        search,
        groupId,
        locations,
        deviceTypes,
      });
    } else {
      updateAssignedDevices({ devicesIds: selectedRowKeys, groupId });
    }
  };

  const handleAssignedAllModeSelectionChange = (selectedRowKeys) => {
    const uncheckedRows = _.difference(currentDeviceIds, selectedRowKeys);
    const withoutReselectedRows = _.difference(
      excludedFromAll,
      selectedRowKeys
    ); //remove rows that were unchecked and checked again
    const excludedFromAllArr = _.union(uncheckedRows, withoutReselectedRows);

    setExcludedFromAll(excludedFromAllArr);
  };

  const handleNormalModeSelectionChange = (selectedRowKeys, selectedRows) => {
    const assignedDevices = _.map(selectedRowKeys, (rowKey) => ({
      id: rowKey,
    }));

    if (prevSelectedRows.length <= selectedRows.length) {
      const selectedRow = _.differenceWith(
        selectedRows,
        prevSelectedRows,
        (a, b) => b.id === a.id
      )[0];

      const isDeviceAssociatedWithAnotherGroup =
        !!selectedRow.composedGroupId &&
        selectedRow.composedGroupId !== groupId;

      if (isDeviceAssociatedWithAnotherGroup) {
        const deviceSerialNumber = selectedRow.deviceSerialNumber;

        setWarningModalProps({
          visible: true,
          headerText: "Warning",
          warningText: `Device ${deviceSerialNumber} is already assigned to group ${selectedRow.composedGroupName}`,
          areYouSureText: "Are you sure you want to continue?",
          onOk: () => onApproveGroupOverride(assignedDevices),
          onCancel: hideWarningModal,
        });
      } else {
        setAssignedDevices(assignedDevices);
      }
    } else {
      setAssignedDevices(assignedDevices);
    }
  };

  const onSelectionChange = (selectedRowKeys, selectedRows) => {
    if (isAssignedAllMode) {
      handleAssignedAllModeSelectionChange(selectedRowKeys);
    } else {
      handleNormalModeSelectionChange(selectedRowKeys, selectedRows);
    }
  };

  const onApproveGroupOverride = (assignedDevices) => {
    setAssignedDevices(assignedDevices);
    hideWarningModal();
  };

  const hideWarningModal = () => {
    setWarningModalProps({
      visible: false,
    });
  };

  const onApproveGroupOverrideAllMode = () => {
    setIsAssignedAllMode(true);
    hideWarningModal();
  };

  const onCancelGroupOverride = () => {
    hideWarningModal();
  };

  const onAssignAllDevicesClick = () => {
    if (!isAssignedAllMode) {
      if (isAtLeastOneDeviceAttachedToComposedGroup) {
        setWarningModalProps({
          visible: true,
          headerText: "Warning",
          warningText: "At least one device is already assigned to a group",
          areYouSureText: "Are you sure you want to continue?",
          onOk: onApproveGroupOverrideAllMode,
          onCancel: onCancelGroupOverride,
        });
      } else {
        onApproveGroupOverrideAllMode();
      }
    }
  };

  const rowSelection = {
    columnTitle: (
      <AssignAllRenderer onSelectAllClick={onAssignAllDevicesClick} />
    ),
    columnWidth: 130,
    onChange: onSelectionChange,
    selectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: false, // !canAssignDevices,
      name: record.name,
    }),
    hideDefaultSelections: false,
  };

  return (
    <PaddedDiv>
      <WarningModal {...warningModalProps} />
      <DevicesGridContainer
        showAddNew={false}
        columns={deviceGridColumns(groupId)}
        rowSelection={rowSelection}
        treeSelectorMaxTagCount={1}
        composedGroupId={groupId}
        shouldDisplayClassifications={false}
        shouldDisplayLastEvent={false}
        shouldDisplayConnectionType={false}
        GridTitle={
          <GridTitleWrapper>
            <BoldText>Group Name: &nbsp;</BoldText> {groupName}
          </GridTitleWrapper>
        }
      />

      {canAssignDevices && (
        <UpdateAssignedDevicesButton onClick={onUpdateDevicesAssignment}>
          Update Device Assignment
        </UpdateAssignedDevicesButton>
      )}
    </PaddedDiv>
  );
};

export default withErrorBoundary(AssignDevicesModal);
