import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import TokenOperation from '@biotmed/js-token-operation';
import { Spin } from 'antd';
import styled from 'styled-components';
import ErrorComponent from 'components/ErrorComponent';
import Logo from 'components/Logo';
import { STATUS, getScreenStatusByErrorCode } from '../constants';
import AppConfig from 'config/AppConfig';

const StatusLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #142846;
    height: 100%;
    max-width: 300px;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 25px;
`;

function AdfsLoginComponent(props) {
  const {intl, push, loginWithAdfsSuccess} =props;
  const [status, setStatus] = useState(STATUS.LOADING);

  function handleError(error) {
    setStatus(getScreenStatusByErrorCode(error.code));
  }

  function onExecuteSuccess(response) {
    setStatus(STATUS.SUCCESS);
    loginWithAdfsSuccess(response);
  }

  function retryLogin() {
    push('/login');
  }

  const STATUS_COMPONENT_MAPPER = {
    [STATUS.LOADING]: <Spin size="large" />,
    [STATUS.TOKEN_NOT_VALID]: <ErrorComponent description={intl.formatMessage(messages.tokenNotValidError)} buttonText={intl.formatMessage(messages.retry)} onClick={retryLogin}></ErrorComponent>,
    [STATUS.GENERAL_ERROR]: <ErrorComponent description={intl.formatMessage(messages.generalError)} buttonText={intl.formatMessage(messages.retry)} onClick={retryLogin}></ErrorComponent>,
  }


  return (
    <React.Fragment>
      <TokenOperation
        baseURL={`${AppConfig.API_URL}/ums`}
        onValidateFail={handleError}
        onExecuteSuccess={onExecuteSuccess}
        onExecuteFail={handleError}
        shouldExecute
      />

      
      <StatusLayout>
        <StyledLogo />
        { STATUS_COMPONENT_MAPPER[status] }
      </StatusLayout>
    </React.Fragment>

  )
}

AdfsLoginComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  push: PropTypes.func,
}

export const scope = "AdfsLogin";
const messages = defineMessages({
  generalError: {
    id: `${scope}.generalError`,
    defaultMessage: 'A General Error Occurred',
  },
  tokenNotValidError: {
    id: `${scope}.tokenNotValidError`,
    defaultMessage: 'Token Not Valid',
  },
  retry: {
    id: `${scope}.retry`,
    defaultMessage: 'Retry',
  },
});

export default injectIntl(AdfsLoginComponent);
