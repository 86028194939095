import React, { useReducer, useEffect, useRef } from 'react';
import { Button, Icon, List, Table, Tooltip } from 'antd';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

import Box from 'components/Box';
import DeviceTypeTreeSelector from 'containers/DeviceTypeTreeSelector';
import LocationTreeSelector from 'containers/LocationTreeSelector';
import { WhiteText } from 'components/AuthPageBase';


const Container = styled.section`
	.ant-select-selection {
		//fix ant design's placeholder location
		position: relative;
	}
`;

const StyledTable = styled(Table)`
	.selected {
		background-color: #ddd;	
	}
`;

const PAGE_SIZE = 7;

function usePrevious(value) {
	const ref = useRef();
	
	useEffect(() => {
	  ref.current = value;
	}, [value]); // Only re-run if value changes
	
	// Return previous value (happens before update in useEffect above)
	return ref.current;
}

const RegionDevicePairs = (props, ref) => {
	const {
		      size,
		      value,
		      onChange,
		      disabled,
		      deviceTypesIdToLabelMap,
			  locationsIdToLabelMap,
			  defaultDataSource,
			  shouldSetDefaultDataSource,
		  } = props;

	const initialState = {
		id:          undefined,
		locations:   undefined,
		deviceTypes: undefined,
	};
	const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), initialState);

	const prevShouldSetDefaultDataSource = usePrevious(shouldSetDefaultDataSource);
	
	useEffect(()=>{
		if(shouldSetDefaultDataSource){
			defaultDataSource && setDefaultValue(defaultDataSource);
		} else if(prevShouldSetDefaultDataSource){
			//if previous shouldSetDefaultDataSource prop was true => reset
			resetAll();
		}// else - do not change anything
	},[shouldSetDefaultDataSource]);


	const resetAll = ()=>{
		triggerChange([]);
	}

	const setDefaultValue = (defaultDataSource) => {
		const newValue = 
			defaultDataSource.map((item, index) => {
				const { locations, deviceTypes } = item;
				return {
					id: uuid(), //table requires a key prop to be unique, also helps removing
					locations,
					deviceTypes
				};
			});
		triggerChange(newValue);
		setState(initialState);
	};

	const addOrSaveRow = () => {
		const { id, locations, deviceTypes } = state;

		const newItem = {
			id: uuid(), //table requires a key prop to be unique, also helps removing
			locations,
			deviceTypes
		};

		const isAddNew = !id;
		const newValue = isAddNew ?
			value ? [...value, newItem] : [newItem] :
			value.map((item, index) => {
				if (item.id !== state.id) {
					// This isn't the item we care about - keep it as-is
					return item
				}

				// Otherwise, this is the one we want - return an updated value
				return state;
			});
		triggerChange(newValue);
		setState(initialState);
	};

	const resetForm = () => setState(initialState);

	const handleRegionChange = locations => {
		// const locations = _.map(locationObjects, location => location.value);
		// setState({ locations });
		setState({ locations });
	};

  	const handleDeviceTypesChange = deviceTypes => {
		setState({ deviceTypes })
	};

	const triggerChange = changedValue => {
		if (onChange) {
			onChange(changedValue);
		}
	};

	const removeItem = row => e => {
		const newValue = _.filter(value, record => record.id !== row.id);
		triggerChange(newValue);
	};

	const editItem = row => e => {
		// const {locations, deviceTypes, id} = row;
		setState(row);
	};

	const createTooltipForArray = (arr) =>
		<List size="small" bordered={false}>
			{
				arr && arr.map((obj, i) => <List.Item key={i}>
					<WhiteText>{obj}</WhiteText>
				</List.Item>)
			}
		</List>;

	const columns = [
		{
			title:     'Region',
			dataIndex: 'locations',
			ellipsis:  true,
			render:    (locationsArr, row) => {
				const locationsLabels = _.map(locationsArr, locationId => locationsIdToLabelMap[locationId]);
				const title = createTooltipForArray(locationsLabels);
				return <Tooltip title={title}>
					<span>{
						_.join(locationsLabels, ', ')
					}</span>
				</Tooltip>;
			},
		},
		{
			title:     'Device Types',
			dataIndex: 'deviceTypes',
			ellipsis:  true,
			render:    (deviceTypesArr, row) => {
				const deviceTypesLabels = _.map(deviceTypesArr, deviceTypeId => deviceTypesIdToLabelMap[deviceTypeId]);
				const title = createTooltipForArray(deviceTypesLabels);
				return <Tooltip title={title}>
					<span>{
						_.join(deviceTypesLabels, ', ')
					}</span>
				</Tooltip>;
			},
		},
	];

	if (!disabled) {
		columns.push({
			title:  '',
			key:    'action',
			width:  160,
			render: (text, row) =>
				        <Button.Group>
					        <Button
						        type="link"
						        disabled={!!state.id}
						        onClick={removeItem(row)}>
						        Remove
					        </Button>

					        <Button
						        type="link"
						        disabled={!!state.id}
						        onClick={editItem(row)}>
						        Edit
					        </Button>
				        </Button.Group>
		});
	}

	const addBtnDisabled = _.isEmpty(state.locations) || _.isEmpty(state.deviceTypes);
	const cancelBtnDisabled = _.isEmpty(state.locations) && _.isEmpty(state.deviceTypes);
	const buttonText = state.id ? 'Save' : 'Add';
	const pagination = _.get(value, 'length') >= PAGE_SIZE ? { pageSize: PAGE_SIZE } : false;

	return (
		<Container ref={ref}>
			<Box>
				<LocationTreeSelector
					size={size}
					onChange={handleRegionChange}
					value={state.locations}
					style={{ marginRight: 20, maxWidth: 200 }}
					withAll
					disabled={disabled}
				/>

				<DeviceTypeTreeSelector
					size={size}
					onChange={handleDeviceTypesChange}
					value={state.deviceTypes}
					style={{ marginRight: '20px' }}
					withAll
					disabled={disabled}
				/>

				<Button onClick={addOrSaveRow} disabled={addBtnDisabled}>{buttonText}</Button>
				<Button onClick={resetForm} disabled={cancelBtnDisabled}>X</Button>
			</Box>

			<StyledTable
				columns={columns}
				dataSource={value}
				rowKey="id"
				style={{ marginTop: 10 }}
				pagination={pagination}
				size="small"
				bordered
				rowClassName={record => record.id === state.id && 'selected'}
			/>
		</Container>
	);
};

export default React.forwardRef(RegionDevicePairs);