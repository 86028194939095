import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Select } from 'antd';
import { injectIntl } from 'react-intl';
import messages from './installTypeMessages';
import PropTypes from "prop-types";

const InstallTypeSelector = React.forwardRef((props, ref) => {
    const { ...rest } = props;
    const { formatMessage } = props.intl;
    const manualMsg = formatMessage(messages.manual);
    const forceMsg = formatMessage(messages.force);

    const installTypeSelectorData = [
        { key: 'MANUAL', value: manualMsg },
        { key: 'FORCE', value: forceMsg }
    ];

    return (
        <Select
            name="supportedVersions"
            label="{supportedVersionsText}"
            ref={ref}
            {...rest}>
            {
                installTypeSelectorData.map((installTypeSelectorSingleData, i) => {
                    return (
                        <Select.Option
                            key={installTypeSelectorSingleData.key}
                            value={installTypeSelectorSingleData.key}
                            label={installTypeSelectorSingleData.value}>
                            {installTypeSelectorSingleData.value}
                        </Select.Option>
                    );
                })
            }
        </Select>
    )
});

InstallTypeSelector.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default compose(
    connect(
        null,
        null,
        null,
        { forwardRef: true }
    ),
    injectIntl
)(InstallTypeSelector);
