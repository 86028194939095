import { defineMessages } from 'react-intl';

export const scope = 'installTypeSelector';

export default defineMessages({
    manual: {
      id: `${scope}.manual`,
      defaultMessage: 'Manual',
    },
    force: {
        id: `${scope}.force`,
        defaultMessage: 'Force',
    }
});
