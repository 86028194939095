import { connect } from "react-redux";
import { compose } from "redux";
import ViewLicenseModal from "pages/Licenses/components/ViewLicenseModal";
import injectReducer from "utils/injectReducer";
import licenseReducer, { licenseSelectors } from "../modules/licenses.slice";

import { modalActions } from "containers/ModalRoot/modules/modal.slice";



const withReducer = injectReducer({
    key: "licenses",
    reducer: licenseReducer,
});

const mapStateToProps = (state, ownProps) => {
    return {
        decryptedLicense: licenseSelectors.selectDecryptedLicense(state)
    };
};

const withConnect = connect(mapStateToProps, {
    ...modalActions
});

export default compose(withReducer, withConnect)(ViewLicenseModal);
