import React from 'react';
import { Input } from 'antd';
import _ from 'lodash';

import FormField from 'components/FormFactory/FormField';


const emailRule = { type: 'email', message: 'The input is not valid E-mail!' };

const EmailField = (props) => {
	const _props = {...props}; //React v14 and up - props object is frozen
	_props.rules ? _props.rules.push(emailRule) : _props.rules = [emailRule];

	return (
		<FormField {..._props}>
			<Input />
		</FormField>
	);
};

EmailField.propTypes = {
	...FormField.propTypes
};

export default EmailField;