import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Spin, Tabs } from "antd";
import _ from "lodash";

import DeviceFormContainer from "pages/Devices/containers/DeviceFormContainer";
import UsersGrid from "pages/Users/containers/UsersGridContainer";
import DevicePropertiesTab from "pages/Devices/components/DevicePropertiesTab";
import DeviceActivationTab from "pages/Devices/components/DeviceActivationTab";
import DeviceServicesTabContainer from "../containers/DeviceServicesTabContainer";
import V2CTab from "./V2C/V2CTab";
import AlarmsGridContainer from "../../Alarms/containers/DeviceAlarmHistoryGridContainer";
import V2CHistoryGridContainer from "../containers/V2CHistoryGridContainer";

const DeviceTabs = styled(Tabs)`
  height: 750px;
`;

const UpdateAssignedUsersButton = styled(Button).attrs({ type: "primary" })`
  float: right;
`;

const { TabPane } = Tabs;

const DeviceProperties = (props) => {
  const {
    deviceId,
    device,
    assignedUsers,
    canAssignUsers,
    fetchAssignedUsers,
    fetchDeviceProperties,
    setAssignedUsers,
    updateAssignedUsers,
    fetchCustomerDetails,
    clearEditData,
    isLoading,
    canUploadV2C,
    canManageLogs,
    intl,
    hideModal,
    fetchDeviceCommands,
  } = props;

  const selectedRowKeys = _.map(assignedUsers, (user) => user.userId);
  const implicitAssignedUsers = _.filter(
    assignedUsers,
    (user) => !user.explicitAssign
  );
  const implicitAssignedUsersIds = _.map(
    implicitAssignedUsers,
    (user) => user.userId
  ); //implicit = users assigned due to ALL value, not explicitly for this device
  const explicitAssignedUserIds = _.difference(
    selectedRowKeys,
    implicitAssignedUsersIds
  );
  const [activeTabKey, setActiveTabKey] = useState("general");

  useEffect(() => {
    if (device) {
      const {
        deviceType: { type },
        deviceSerialNumber,
      } = device;
      fetchAssignedUsers({ deviceType: type, deviceSerialNumber });
      fetchCustomerDetails({ deviceType: type, deviceSerialNumber });
      fetchDeviceProperties({ deviceType: type, deviceSerialNumber });
      fetchDeviceCommands({ deviceType: type, deviceSerialNumber });
    }
  }, [deviceId]);

  useEffect(
    () => () => {
      clearEditData();
    },
    []
  );

  const onUpdateUserAssignment = () => {
    const {
      deviceType: { type },
      deviceSerialNumber,
    } = device;
    updateAssignedUsers({
      deviceType: type,
      deviceSerialNumber,
      explicitAssignedUserIds,
    });
  };

  const usersGridcolumns = [
    {
      key: "firstName",
      title: "Name",
      render: (text, row) => `${row.firstName} ${row.lastName}`,
      sorter: true,
    },
    {
      key: "group.name",
      dataIndex: "group.name",
      title: "User Group",
    },
  ];

  const onSelectionChange = (selectedRowKeys, selectedRows) => {
    const assignedUsers = _.map(selectedRowKeys, (rowKey) => ({
      userId: rowKey,
      explicitAssign: !_.includes(implicitAssignedUsersIds, rowKey),
    }));
    setAssignedUsers(assignedUsers);
  };

  const rowSelection = {
    columnTitle: "Assign",
    columnWidth: 100,
    onChange: onSelectionChange,
    selectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled:
        _.includes(implicitAssignedUsersIds, record.id) || !canAssignUsers,
      name: record.name,
    }),
    hideDefaultSelections: true,
  };

  const switchToUploadV2CTab = () => {
    setActiveTabKey("uploadV2C");
  };

  return (
    <Spin spinning={isLoading}>
      <DeviceTabs
        activeKey={activeTabKey}
        onChange={(activeKey) => setActiveTabKey(activeKey)}
        tabPosition="left"
        style={{ overflow: "auto" }}
        destroyInactiveTabPane
      >
        <TabPane tab="General" key="general">
          <DeviceFormContainer device={device} />
        </TabPane>

        <TabPane tab="Properties" key="properties">
          <DevicePropertiesTab device={device} />
        </TabPane>
        {canAssignUsers && (
          <TabPane tab="Assign" key="assign">
            <UsersGrid
              showAddNew={false}
              columns={usersGridcolumns}
              rowSelection={rowSelection}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ backgroundColor: "red", color: "white" }}
                onClick={hideModal}
              >
                Cancel
              </Button>
              <div style={{ width: "10px" }}></div>
              <UpdateAssignedUsersButton onClick={onUpdateUserAssignment}>
                Update User Assignment
              </UpdateAssignedUsersButton>
            </div>
          </TabPane>
        )}
        {canUploadV2C && (
          <TabPane tab="Upload V2C" key="uploadV2C">
            <V2CTab
              updateV2C={props.updateV2C}
              updateV2CStatus={props.updateV2CStatus}
              showSuccessToast={props.showSuccessToast}
              showErrorToast={props.showErrorToast}
              device={device}
            />
          </TabPane>
        )}
        <TabPane tab="V2C History" key="V2CHistory">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <V2CHistoryGridContainer deviceId={deviceId} />
            {canUploadV2C && (
              <Button
                type="primary"
                style={{ alignSelf: "flex-end", marginTop: "3em" }}
                onClick={switchToUploadV2CTab}
              >
                Upload
              </Button>
            )}
          </div>
        </TabPane>
        <TabPane tab="Alarm History" key="history">
          <AlarmsGridContainer
            deviceSerialNumber={device.deviceSerialNumber}
            deviceType={device.deviceType.type}
          />
        </TabPane>

        <TabPane tab="Activation" key="activation">
          <DeviceActivationTab
            device={device}
            intl={intl}
            deactivateDevice={props.deactivateDevice}
            reactivateDevice={props.reactivateDevice}
            resetDevice={props.resetDevice}
            isDeactivateDeviceLoading={props.isDeactivateDeviceLoading}
            isReactivateDeviceLoading={props.isReactivateDeviceLoading}
            isResetDeviceLoading={props.isResetDeviceLoading}
          />
        </TabPane>
        {canManageLogs && (
          <TabPane tab="Service" key="service">
            <DeviceServicesTabContainer
              device={device}
              intl={intl}
              canManageLogs={canManageLogs}
              changeLogMode={props.changeLogMode}
              downloadDeviceFiles={props.downloadDeviceFiles}
              isDownloadDeviceFilesLoading={props.isDownloadDeviceFilesLoading}
            ></DeviceServicesTabContainer>
          </TabPane>
        )}
      </DeviceTabs>
    </Spin>
  );
};

DeviceProperties.propTypes = {
  device: PropTypes.object,
  deactivateDevice: PropTypes.func.isRequired,
  reactivateDevice: PropTypes.func.isRequired,
  resetDevice: PropTypes.func.isRequired,
  isDeactivateDeviceLoading: PropTypes.bool.isRequired,
  isReactivateDeviceLoading: PropTypes.bool.isRequired,
  isResetDeviceLoading: PropTypes.bool.isRequired,
  updateV2C: PropTypes.func.isRequired,
  updateV2CStatus: PropTypes.string.isRequired,
  showSuccessToast: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
};

export default DeviceProperties;
