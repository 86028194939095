import _ from 'lodash';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import { parseFetchAllRequest } from './alarms.parsers';

const extraAsyncActions = [
	{
		type:           'fetchAlarmStates',
		setStateDataFn: (state, action) => {
			state.states = action.payload.data;
			return state;
	  }
	},
	{
		type:           'fetchActiveAlarmsCount',
	},
];

const alarmsEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.ALARMS,
	extraAsyncActions,
	parseFetchAllRequest
});

const { reducer, actions, selectors } = alarmsEntitySlice;
const {
	      fetchAll:        fetchAlarms,
	      fetchAllPending: fetchAlarmsPending,
	      fetchAllSuccess: fetchAlarmsSuccess,
	      fetchAllFailure: fetchAlarmsFailure,

		  fetchAlarmStates,
	      fetchAlarmStatesFailure,
	      fetchAlarmStatesPending,
		  fetchAlarmStatesSuccess,
		  
		  fetchActiveAlarmsCount,
		  fetchActiveAlarmsCountFailure,
	      fetchActiveAlarmsCountPending,
		  fetchActiveAlarmsCountSuccess,
      } = actions;

export const alarmsEntityActions = {
	fetchAlarms,
	fetchAlarmsPending,
	fetchAlarmsSuccess,
	fetchAlarmsFailure,

	fetchAlarmStates,
	fetchAlarmStatesFailure,
	fetchAlarmStatesPending,
	fetchAlarmStatesSuccess,

	fetchActiveAlarmsCount,
	fetchActiveAlarmsCountFailure,
	fetchActiveAlarmsCountPending,
	fetchActiveAlarmsCountSuccess,
};

const selectFetchAlarmStatesPending = state => {
	return _.get(state, 'entities.alarms.fetchAlarmsStatesLoading');
}

const selectAlarmStates = state => {
	return _.get(state, 'entities.alarms.states');
}

const {
	      selectAll:              selectAlarms,
	      selectFetchAllPending:  selectAlarmsPending,
	      selectFetchByIdPending: selectFetcAlarmsPending,
      } = selectors;


export const alarmsEntitySelectors = {
	selectAlarms,
	selectAlarmsPending,
	selectFetcAlarmsPending,

	selectFetchAlarmStatesPending,
	selectAlarmStates,
};

export default reducer;