
import { createActionWithScope, createFailureReducer, createPendingReducer, createSuccessReducer, getFormattedScopeName } from 'containers/App/modules/redux.util';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const STATE_KEY = 'forgotPassword';
export const INITIAL_STATE = {};

const prepareForgotPasswordRequestFn = payload => ({
	payload: _.get(payload, 'email')
});

const forgotPassword = createActionWithScope(STATE_KEY, 'forgotPassword', prepareForgotPasswordRequestFn);

const forgotPasswordSlice = createSlice({
	name:         getFormattedScopeName(STATE_KEY),
	initialState: INITIAL_STATE,
	reducers:     {
		// forgotPassword: NOOP,
		forgotPasswordPending: createPendingReducer(),
		forgotPasswordSuccess: createSuccessReducer(),
		forgotPasswordFailure: createFailureReducer(),
	}
});

export const forgotPasswordActions = {
	forgotPassword,
	...forgotPasswordSlice.actions
};

const getForgotPasswordState = state => state.forgotPassword;
const selectForgotPasswordPending = createSelector(
	getForgotPasswordState,
	fpState => fpState.isLoading
);

export const forgotPasswordSelectors = {
	selectForgotPasswordPending
};
export default forgotPasswordSlice.reducer;