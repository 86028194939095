import { defineMessages } from 'react-intl';

export const scope = 'users.page';

export default defineMessages({
	createUser: {
		id: `${scope}.createUser`,
		defaultMessage: 'Create User',
	},
	firstName: {
		id: `${scope}.firstName`,
		defaultMessage: 'First Name',
	},
	lastName: {
		id: `${scope}.lastName`,
		defaultMessage: 'Last Name',
	},
	phoneNumber: {
		id: `${scope}.phoneNumber`,
		defaultMessage: 'Phone Number',
	},
	email: {
		id: `${scope}.email`,
		defaultMessage: 'Email',
	},
	userGroup: {
		id: `${scope}.userGroup`,
		defaultMessage: 'User Group',
	},
	deviceType: {
		id: `${scope}.deviceType`,
		defaultMessage: 'Device assignment',
	},
	manager: {
		id: `${scope}.manager`,
		defaultMessage: 'Manager',
	},
	locked: {
		id: `${scope}.locked`,
		defaultMessage: 'User Disabled',
	},
});
