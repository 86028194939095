import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { getFormattedScopeName } from 'containers/App/modules/redux.util';


export const MODAL_STATE_KEY = 'modal';

const INITIAL_STATE = {
	visible:        false,
	modalType:      null,
	modalProps:     {},
	componentProps: {},
};

const modalSlice = createSlice({
	name:         getFormattedScopeName(MODAL_STATE_KEY),
	initialState: INITIAL_STATE,
	reducers:     {
		showModal: (state, action) => {
			state.visible = true;
			state.modalType = _.get(action, 'payload.modalType');
			state.modalProps = _.get(action, 'payload.modalProps');
			state.componentProps = _.get(action, 'payload.componentProps');
		},
		hideModal: () => INITIAL_STATE
	}
});

const {
	      showModal, hideModal
      } = modalSlice.actions;

export const modalActions = {
	showModal,
	hideModal
};

export default modalSlice.reducer;
