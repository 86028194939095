import { defineMessages } from "react-intl";

export const scope = "commonMessages";

export default defineMessages({
	save: {
		id: `${scope}.save`,
		defaultMessage: "Save",
	},
});
