import React from "react";
import { Button, Avatar } from "antd";
import styled from "styled-components";
import {
  getDifferenceInDays,
  daysToWeeks,
  daysToMonths,
  daysToYears,
} from "utils/dateUtils";
import moment from "moment";

import { MODAL_TYPES } from "containers/ModalRoot/components/constants";

import EntityGrid, {
  loadData as entityGridLoadData,
} from "containers/Grid/components/EntityGrid";
import DevicesGridToolbar from "pages/Devices/components/DevicesGridToolbar";
import DeviceStatus from "pages/Devices/components/DeviceStatus";

export const classifications = {
  CLINIC: "Clinic",
  VnV: "V&V",
  SERVICE: "Service",
  DEMO: "Demo",
};

const LastEventAvatar = styled(Avatar)`
  background-color: ${(props) => props.color};
  color: black;
`;

const getLastEventColor = (days) => {
  if (days === null || days === undefined) {
    return "white";
  } else if (days < 7) {
    return "green";
  } else if (days < 14) {
    return "gold";
  }
  return "red";
};

const getLastEventText = (days) => {
  if (days === null || days === undefined) {
    return "";
  } else if (days < 7) {
    return `${days}d`;
  } else if (days < 30) {
    return `${daysToWeeks(days)}w`;
  } else if (days < 365) {
    return `${daysToMonths(days)}M`;
  }
  return `${daysToYears(days)}y`;
};

const DevicesGrid = (props) => {
  const {
    fetchDevices,
    isDevicesLoading,
    devices,
    showModal,
    hideModal,

    grid,
    setGridParams,
    resetGridData,
    rowSelection,
    showAddNew,
    treeSelectorMaxTagCount,
    GridTitle,
    composedGroupId,

    shouldDisplayDeviceType,
    shouldDisplayLocations,
    shouldDisplayClassifications,
    shouldDisplayLastEvent,
    shouldDisplayConnectionType,
  } = props;

  const onEditDeviceClick = (row) => () => {
    showModal({
      modalType: MODAL_TYPES.DEVICE_PROPERTIES_MODAL,
      modalProps: {
        title: "Device Properties",
        footer: null,
        width: 1000,
      },
      componentProps: {
        deviceId: row.id,
        hideModal,
      },
    });
  };

  const devicesGrid = {
    ...grid,
    params: {
      ...grid.params,
      additionalData: {
        composedGroupId,
      },
    },
  };

  const columns = props.columns || [
    {
      key: "lastEvent",
      title: "Last Event",
      dataIndex: "lastEvent",
      sorter: true,
      render: (lastEvent) => {
        const currentTime = moment();
        const lastEventTime = lastEvent ? moment(lastEvent) : null;
        const daysAgo = lastEventTime
          ? getDifferenceInDays(lastEventTime, currentTime)
          : null;
        return lastEvent ? (
          <LastEventAvatar color={getLastEventColor(daysAgo)} title={lastEvent}>
            {getLastEventText(daysAgo)}
          </LastEventAvatar>
        ) : (
          <div>Never sent</div>
        );
      },
    },
    {
      key: "deviceInfo.deviceSerialNumber",
      title: "Serial Number",
      dataIndex: "deviceSerialNumber",
      sorter: true,
    },
    {
      key: "deviceInfo.deviceType",
      title: "Device Type",
      dataIndex: "deviceType.type",
      sorter: true,
      render: (text, record) => `${record.deviceType.materialFamily}/${text}`,
    },
    {
      key: "deviceInfo.status",
      title: "Activation Status",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => <DeviceStatus status={text} />,
    },
    {
      key: "deviceInfo.classification",
      title: "Classification",
      dataIndex: "classification",
      sorter: true,
      render: (classificationValue, record) =>
        `${classificationValue ? classifications[classificationValue] : ""}`,
    },
    {
      key: "customerDetails.clinicId",
      title: "Clinic ID",
      dataIndex: "customerDetails.clinicId",
      sorter: true,
    },
    {
      key: "customerDetails.clinicName",
      title: "Clinic Name",
      dataIndex: "customerDetails.clinicName",
      sorter: true,
    },
    {
      key: "customerDetails.address.country",
      title: "Country",
      dataIndex: "customerDetails.address.country",
      sorter: true,
    },
    {
      key: "connection.connectionType",
      title: "Connection Type",
      dataIndex: "connection.connectionType",
    },
    {
      key: "actions",
      title: "",
      render: (text, row) => (
        <Button type="link" onClick={onEditDeviceClick(row)}>
          Properties
        </Button>
      ),
    },
  ];

  const refreshGrid = () => {
    entityGridLoadData(devicesGrid, fetchDevices);
  };

  const toolbarComponent = (
    <DevicesGridToolbar
      resetGridData={resetGridData}
      setGridParams={setGridParams}
      grid={devicesGrid}
      refreshGrid={refreshGrid}
      showModal={showModal}
      showAddNew={showAddNew}
      treeSelectorMaxTagCount={treeSelectorMaxTagCount}
      shouldDisplayDeviceType={shouldDisplayDeviceType}
      shouldDisplayLocations={shouldDisplayLocations}
      shouldDisplayClassifications={shouldDisplayClassifications}
      shouldDisplayLastEvent={shouldDisplayLastEvent}
      shouldDisplayConnectionType={shouldDisplayConnectionType}
    />
  );

  return (
    <EntityGrid
      toolbarComponent={toolbarComponent}
      grid={devicesGrid}
      fetchData={fetchDevices}
      fetchDataPending={isDevicesLoading}
      data={devices}
      columns={columns}
      resetGridData={resetGridData}
      setGridParams={setGridParams}
      rowSelection={rowSelection}
      GridTitle={GridTitle}
    />
  );
};
export default DevicesGrid;
