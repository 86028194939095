import { defineMessages } from "react-intl";

export const scope = "swVersions";

export default defineMessages({
	type: {
		id: `${scope}.type`,
		defaultMessage: "Device Type",
	},
	version: {
		id: `${scope}.version`,
		defaultMessage: "Version",
	},
	uploadDate: {
		id: `${scope}.uploadDate`,
		defaultMessage: "Upload Date",
	},
	uploadedBy: {
		id: `${scope}.uploadedBy`,
		defaultMessage: "Uploaded By",
	},
	upload: {
		id: `${scope}.upload`,
		defaultMessage: "Upload",
	},
	comments: {
		id: `${scope}.comments`,
		defaultMessage: "Comments",
	},
	uploadVersion: {
		id: `${scope}.uploadVersion`,
		defaultMessage: "Upload Software Version",
	},
	deviceTypes: {
		id: `${scope}.deviceTypes`,
		defaultMessage: "Device Types",
	},
	deviceTypeNonSelectable: {
		id: `${scope}.deviceTypeNonSelectable`,
		defaultMessage: "Only Device Family or Device Type are assignable",
	},
	valid: {
		id: `${scope}.valid`,
		defaultMessage: 'Valid',
	},
	invalid: {
		id: `${scope}.invalid`,
		defaultMessage: 'Invalid',
	},
	fileType: {
		id: `${scope}.fileType`,
		defaultMessage: 'File Type',
	},
	supportedVersions: {
		id: `${scope}.supportedVersions`,
		defaultMessage: 'Supported Versions',
	},
	installType: {
		id: `${scope}.installType`,
		defaultMessage: 'Install Type',
	},
});