import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message, TreeSelect } from 'antd';
import _ from 'lodash';

import { ALL_VALUE, EMPTY_ARRAY } from 'containers/App/app.constants';
import styled from 'styled-components';

const NON_SELECTABLE_MESSAGE_DELAY = 5;

const StyledTreeSelect = styled(TreeSelect)`
	&.ant-select-disabled .ant-select-selection {
		font-weight: 600;
	}
`;

const getTagCountByScreenWidth = (innerWidth) => {
	const tagWeight = 800;

	return Math.floor(innerWidth/tagWeight);
}

const TreeSelector = React.forwardRef((props, ref) => {
	const {
		      treeData,
		      withAll,
		      multiSelect             = true,
		      nonSelectableTreeLevels = EMPTY_ARRAY,
		      nonSelectableMessage    = 'Cannot select this level in the tree',
			  onChange,
			  maxTagCount,
		      ...treeSelectProps
		  } = props;
	const TAG_COUNT_INITIAL_STATE = maxTagCount || getTagCountByScreenWidth(window.innerWidth);
	const [tagCount, setTagCount] = useState(TAG_COUNT_INITIAL_STATE);

	useEffect(() => {
		if(!maxTagCount) {
	  		const handleWindowResize = () => {
		  		setTagCount(getTagCountByScreenWidth(window.innerWidth));
	  		}

			  window.addEventListener('resize', handleWindowResize);
			  
			  return () => window.removeEventListener('resize', handleWindowResize);
		}
	})

	const compiledTreeData = withAll ?
		[{
			value:    ALL_VALUE,
			key:      ALL_VALUE,
			title:    ALL_VALUE,
			level:    -1,
			children: treeData
		}] : treeData;

	const handleChange = (value, label, extra) => {
		const curLevel = _.get(extra, 'triggerNode.props.level');
		if (nonSelectableTreeLevels.includes(curLevel)) {
			message.info(nonSelectableMessage, NON_SELECTABLE_MESSAGE_DELAY);
			return;
		}
		onChange && onChange(value);
	};

	return (
		<StyledTreeSelect
			ref={ref}
			treeData={compiledTreeData}
			multiple={multiSelect}
			treeCheckable={multiSelect}
			showSearch
			showArrow
			autoClearSearchValue={false}
			dropdownClassName="tree-selector-drop-down"
			treeDefaultExpandedKeys={[ALL_VALUE]}
			showCheckedStrategy={TreeSelect.SHOW_PARENT}
			maxTagCount={tagCount}
			// labelInValue
			treeNodeLabelProp="fullPath"
			treeNodeFilterProp="title"
			{...treeSelectProps}
			onChange={handleChange}
		/>
	)
});

TreeSelector.propTypes = {
	withAll:                 PropTypes.bool,
	multiSelect:             PropTypes.bool,
	nonSelectableTreeLevels: PropTypes.arrayOf(PropTypes.number),
	nonSelectableMessage:    PropTypes.string,
	treeData:                PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			key:   PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		})
	).isRequired
};

export default TreeSelector;