import { parseFetchAllRequest as parseAllRequest } from 'containers/App/modules/entities/users/users.parsers';

export const parseFetchAllRequest = payload => {
	const filters = payload.filters;
	const newFilters = {};
	for (const key in filters) {
		newFilters[key] = filters[key].split(',');
	}
	return parseAllRequest({ ...payload, filters: newFilters });
}

export const parseFetchAllSuccess = responseData => {
	return responseData.data.map(e => { return { ...e.license, actions: e.actions } });
};

export const prepareLicenseRequest = payload => {
	return payload;
}



