import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { ALL_VALUE } from "containers/App/app.constants";

import { Cascader } from "antd";
import { selectDeviceTypesCascadingData } from "containers/App/modules/entities/deviceTypes/deviceTypeEntity.selectors";
import styled from "styled-components";

const StyledCascader = styled(Cascader)`
  .ant-cascader-picker-label {
    font-weight: 600;
  }
`;

//TODO URGENT fix this component to work standalone (not only in form).
// I manipulate the value to be a deviceType object and not array of strings.
const DeviceTypeSelector = React.forwardRef((props, ref) => {
  const { deviceTypes, dispatch, onChange, withAll, value, ...rest } = props;

  const filter = (inputValue, path) => {
    return path.some((option) =>
      _.includes(option.label.toLowerCase(), inputValue.toLowerCase())
    );
  };

  //to produce a different value, take control over the value and onChange
  const onDeviceTypeChange = (valueArray, [, , deviceTypeObject]) => {
    let value;
    if (_.isEmpty(valueArray)) {
      value = null;
    } else if (withAll && _.get(valueArray, "[0]") === ALL_VALUE) {
      value = ALL_VALUE;
    } else {
      value = deviceTypeObject.object;
    }

    onChange && onChange(value);
  };
  //convert value (=DeviceTypeObject) to a value cascader knows (['', '', ''])
  const newValue = value
    ? value === ALL_VALUE
      ? [ALL_VALUE]
      : [value.productFamily1, value.materialFamily, value.type]
    : value;

  //add "All" option if needed
  const newOptions =
    withAll && !_.isEmpty(deviceTypes)
      ? [{ value: ALL_VALUE, label: ALL_VALUE }, ...deviceTypes]
      : deviceTypes;

  return (
    <StyledCascader
      suffixIcon={<></>}
      ref={ref}
      options={newOptions}
      showSearch={{ filter }}
      onChange={onDeviceTypeChange}
      value={newValue}
      placeholder="Device Type"
      {...rest}
    />
  );
});

DeviceTypeSelector.propTypes = {
  ...Cascader.propTypes,
  withAll: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  deviceTypes: selectDeviceTypesCascadingData(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  DeviceTypeSelector
);
