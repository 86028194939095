import React from "react";
import EntityGridToolbar from "containers/Grid/components/EntityGridToolbar";
import _ from "lodash";
import { MODAL_TYPES } from "containers/ModalRoot/components/constants";
import { FormattedMessage } from "react-intl";
import { PERMISSIONS } from "services/PermissionsService";

import FormField from "components/FormFactory/FormField";

import messages from "pages/Devices/components/messages";
import DeviceTypeTreeSelector from "containers/DeviceTypeTreeSelector";
import LocationTreeSelector from "containers/LocationTreeSelector";
import SelectField from "components/FormFactory/fields/SelectField";
import { Select } from "antd";
import { classifications } from "./DeviceForm";
import moment from "moment";

const { Option } = Select;

const iso8601format = "YYYY-MM-DDTHH:mm:ss";

const connectionTypeFilters = {
  lan: "Lan",
  cellular: "Cellular",
  wifi: "Wifi",
};

const lastEventTimeframesMap = {
  green: {
    display: "Under one week",
    filters: [
      {
        name: "lastEventAfter",
        date: moment().subtract(7, "days"),
      },
    ],
  },
  yellow: {
    display: "Between one week and two",
    filters: [
      {
        name: "lastEventAfter",
        date: moment().subtract(14, "days"),
      },
      {
        name: "lastEventBefore",
        date: moment().subtract(7, "days"),
      },
    ],
  },
  red: {
    display: "over two weeks",
    filters: [
      {
        name: "lastEventBefore",
        date: moment().subtract(14, "days"),
      },
    ],
  },
  blank: {
    display: "Never sent",
    filters: [
      {
        name: "lastEventNull",
        date: null,
      },
    ],
  },
};

const DevicesGridToolbar = (props) => {
  const {
    setGridParams,
    grid,
    showAddNew = true,
    showModal,
    treeSelectorMaxTagCount,
    refreshGrid,
    shouldDisplayDeviceType = true,
    shouldDisplayLocations = true,
    shouldDisplayClassifications = true,
    shouldDisplayLastEvent = true,
    shouldDisplayConnectionType = true,
  } = props;

  const onFiltersChange = (filterValues) => {
    const {
      deviceTypeFilter,
      countryFilter,
      classificationFilter,
      lastEventFilter,
      connectionTypeFilter,
    } = filterValues;
    const filters = {};
    if (!_.isEmpty(connectionTypeFilter)) {
      const selectedFilter = connectionTypeFilters[connectionTypeFilter];
      if (selectedFilter === connectionTypeFilters.none) {
        filters["connectionTypeNull"] = true;
      } else {
        filters["deviceInfo.connection.connectionType"] = selectedFilter;
      }
    }
    if (!_.isEmpty(deviceTypeFilter)) {
      filters["deviceInfo.deviceType"] = deviceTypeFilter;
    }
    if (!_.isEmpty(countryFilter)) {
      filters["customerDetails.address.location"] = countryFilter;
    }
    if (!_.isEmpty(classificationFilter)) {
      filters["deviceInfo.classification"] = classificationFilter;
    }
    if (!_.isEmpty(lastEventFilter)) {
      const selectedFilters = lastEventTimeframesMap[lastEventFilter].filters;
      const lastEventAfterFilter = selectedFilters.filter(
        (f) => f.name === "lastEventAfter"
      );
      const lastEventBeforeFilter = selectedFilters.filter(
        (f) => f.name === "lastEventBefore"
      );
      const lastEventNullFilter = selectedFilters.filter(
        (f) => f.name === "lastEventNull"
      );
      if (lastEventAfterFilter.length > 0) {
        filters["lastEventAfter"] =
          lastEventAfterFilter[0].date.format(iso8601format);
      }
      if (lastEventBeforeFilter.length > 0) {
        filters["lastEventBefore"] =
          lastEventBeforeFilter[0].date.format(iso8601format);
      }
      if (lastEventNullFilter.length > 0) {
        filters["lastEventNull"] = true;
      }
    }
    setGridParams({
      ...grid.params,
      pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
      filters,
    });
  };

  const onSearchChange = (value) => {
    setGridParams({
      ...grid.params,
      pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
      search: value,
    });
  };

  const onAddNew = () => {
    showModal({
      modalType: MODAL_TYPES.CREATE_DEVICE_MODAL,
      modalProps: {
        title: <FormattedMessage {...messages.createDevice} />,
        footer: null,
        width: 1000,
      },
    });
  };

  const onResetFilters = (form) => {
    setGridParams({
      ...grid.params,
      pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
      search: "",
      filters: {},
    });
  };

  const filterFormElements = (
    <>
      {shouldDisplayDeviceType && (
        <FormField name="deviceTypeFilter" style={{ minWidth: 350 }}>
          <DeviceTypeTreeSelector
            allowClear={true}
            maxTagCount={treeSelectorMaxTagCount}
          />
        </FormField>
      )}
      {shouldDisplayLocations && (
        <FormField name="countryFilter" style={{ minWidth: 200 }}>
          <LocationTreeSelector
            allowClear={true}
            maxTagCount={treeSelectorMaxTagCount}
          />
        </FormField>
      )}
      {shouldDisplayClassifications && (
        <SelectField
          name="classificationFilter"
          style={{ minWidth: 200 }}
          placeholder="Classifications"
        >
          {classifications.map((classificationObject) => (
            <Option key={classificationObject.value}>
              {classificationObject.display}
            </Option>
          ))}
        </SelectField>
      )}
      {shouldDisplayLastEvent && (
        <SelectField
          name="lastEventFilter"
          style={{ minWidth: 200 }}
          placeholder="Last events"
        >
          {Object.keys(lastEventTimeframesMap).map((lastEventKey) => (
            <Option key={lastEventKey}>
              {lastEventTimeframesMap[lastEventKey].display}
            </Option>
          ))}
        </SelectField>
      )}
      {shouldDisplayConnectionType && (
        <SelectField
          name="connectionTypeFilter"
          style={{ minWidth: 200 }}
          placeholder="Connection Type"
        >
          {Object.keys(connectionTypeFilters).map((connectionTypeKey) => (
            <Option key={connectionTypeKey}>
              {connectionTypeFilters[connectionTypeKey]}
            </Option>
          ))}
        </SelectField>
      )}
    </>
  );

  return (
    <EntityGridToolbar
      onResetFilters={onResetFilters}
      onAddNew={onAddNew}
      onFiltersChange={onFiltersChange}
      onRefresh={refreshGrid}
      onSearchChange={onSearchChange}
      filterFormElements={filterFormElements}
      showAddNew={showAddNew}
      addNewPermission={PERMISSIONS.UI_CREATE_DEVICE}
    />
  );
};

DevicesGridToolbar.propTypes = {};

export default DevicesGridToolbar;
