import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { ACTION_STATUS } from 'containers/App/app.constants';
import { ENTITIES_STATE_KEY, ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { devicesActions } from 'pages/Devices/modules/devices.slice';

export const STATE_KEY = ENTITY_TYPES.V2C;

const STATE_KEYS = {
  updateV2CStatus: "updateV2CStatus",
  fetchV2CHistoryStatus: "fetchV2CHistoryStatus",
}

export const INITIAL_STATE = {
  [STATE_KEYS.updateV2CStatus]: ACTION_STATUS.IDLE,
};

const clearEditDataStateFn = (state, action) => {
	state.updateV2CStatus = ACTION_STATUS.IDLE
};

const slice = createSlice({
	name:          STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		updateV2C: (state) => {
      state[STATE_KEYS.updateV2CStatus] = ACTION_STATUS.RUNNING;
      
      return state;
    },
    updateV2CSuccess: (state, action) => {
      state[STATE_KEYS.updateV2CStatus] = ACTION_STATUS.SUCCESS;
      
      return state;
    },
    updateV2CFailed: (state) => {
      state[STATE_KEYS.updateV2CStatus] = ACTION_STATUS.ERROR;
      
      return state;
    },
    fetchV2CHistory: (state) => {
      return state;
    },
    fetchV2CHistoryPending: (state, action) => {
      state[STATE_KEYS.fetchV2CHistoryStatus] = ACTION_STATUS.RUNNING;
      
      return state;
    },
    fetchV2CHistorySuccess: (state, action) => {
      state[STATE_KEYS.fetchV2CHistoryStatus] = ACTION_STATUS.SUCCESS;
      state.byId = action.payload.data.entities.v2c
     
      return state;
    },
    fetchV2CHistoryFailure: (state) => {
      state[STATE_KEYS.fetchV2CHistoryStatus] = ACTION_STATUS.ERROR;

      return state;
    },
  },
  extraReducers: {
    [devicesActions.clearEditData]: clearEditDataStateFn
  },
});

const getEntitiesState = state => state[ENTITIES_STATE_KEY] || {};

const getState = createSelector(
	getEntitiesState,
	state => _.get(state, STATE_KEY)
);

const selectUpdateV2CStatus = createSelector(
	getState,
	state => _.get(state, STATE_KEYS.updateV2CStatus)
);

export const selectors = {
  selectUpdateV2CStatus,
};

const {
  fetchV2CHistory,
  fetchV2CHistoryPending,
  fetchV2CHistorySuccess,
  fetchV2CHistoryFailure,

  updateV2C,
  updateV2CFailed,
  updateV2CSuccess,
} = slice.actions;

export const actions = {
  fetchV2CHistory,
  fetchV2CHistoryPending,
  fetchV2CHistorySuccess,
  fetchV2CHistoryFailure,

  updateV2C,
  updateV2CFailed,
  updateV2CSuccess,
};
export default slice.reducer;