import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormFactory from "components/FormFactory";
import FormField from "components/FormFactory/FormField";
import _ from "lodash";
import TextField from "components/FormFactory/fields/TextField";
import { withErrorBoundary } from "components/ErrorBoundary";
import DeviceTypeTreeSelector from "containers/DeviceTypeTreeSelector";
import LocationTreeSelector from "containers/LocationTreeSelector/index";
import messages from "../messages";

const Form = FormFactory.create({ name: "createGroupForm" }); //onFieldsChange: (props, changedFields, allFields) => console.log(changedFields)

const GroupForm = (props) => {
	const {
		isGroupOperationLoading,
		group,
		isGroupFetchLoading,
		editBlocked,
		clearEditedGroup,
		updateGroup,
		createGroup,
		hideModal,
		intl
	} = props;

	useEffect(() => {
		clearEditedGroup();
	}, []);

	const updateText = intl.formatMessage(messages.update);
	const createText = intl.formatMessage(messages.create);
	const groupNameText = intl.formatMessage(messages.groupName);
	const deviceTypeText = intl.formatMessage(messages.deviceType);
	const locationsText = intl.formatMessage(messages.locations);
	const deviceTypeNonSelectableText = intl.formatMessage(messages.deviceTypeNonSelectable);


	const onSubmit = (groupValues) => {
		if (group) {
			updateGroup({ groupId: group.id, groupValues });
		} else {
			createGroup(groupValues);
		}
	};

	const onCancel = (form) => {
		hideModal();
	};


	const isEditMode = !!group;
	const submitButtonText = isEditMode ? updateText : createText;
	const locationsDefaultValue = group && group.locationIds;

	return (
		<Form
			submitButtonText={submitButtonText}
			hideSubmitButton={editBlocked}
			onSubmit={onSubmit}
			onCancel={onCancel}
			hideCancelButton={false}
			isLoading={isGroupFetchLoading}
			isButtonLoading={isGroupOperationLoading}
		>
			<TextField
				name="name"
				label={groupNameText}
				initialValue={isEditMode ? [_.get(group, "groupName")]: null}
				required
			/>

			<FormField
				name="deviceType"
				label={deviceTypeText}
				initialValue={isEditMode ? [_.get(group, "deviceTypeId")] : null}
				required
				disabled={isEditMode}
			>
				<DeviceTypeTreeSelector
					treeDefaultExpandedKeys={[_.get(group, "deviceTypeId")]}
					multiSelect={false}
					nonSelectableTreeLevels={[0]}
					nonSelectableMessage={deviceTypeNonSelectableText}
				/>
			</FormField>

			<FormField name="locations"
					   label={locationsText}
					   initialValue={isEditMode ? locationsDefaultValue : null}
			           required
					   disabled={isEditMode}
					   >
				<LocationTreeSelector
					withAll
				/>
			</FormField>
		</Form>
	);
};

export default withErrorBoundary(GroupForm);
