/* eslint import/no-mutable-exports: 0 */
/* eslint prefer-const: 0 */

let DEV_CONFIG = {
  API_URL: 'https://api.dev.lumenisx.lumenis.com',
  GOOGLE_ANALYTICS_TRACKER_ID: '',
  QLIK_URL: 'https://qlik.lumenis.com/hub/stream/3cbd1f1b-a493-4739-9b4d-58b165cf7857',
  LOGIN_VIA_ADFS: false,
  EVENT_VIEWER_URL: 'https://eventViewer.dev.lumenisX.lumenis.com/setup',
  LOG_SUPPORT_VERSION: process.env.REACT_APP_MIN_LMX_VERSION_SUPPORT_LOGS,
  LOG_V2_SUPPORT_VERSION: process.env.REACT_APP_MIN_LMX_VERSION_SUPPORT_LOGS_V2,
  OTHER_REGION_URL: 'https://web.dev.lumenisx.lumenis.com.cn/',
  OTHER_REGION_ICON_FILE_PATH: '/chinese_icon.png',
  HIDE_LICENSES: process.env.REACT_APP_HIDE_LICENSES.toLowerCase() === "true"
};

export default DEV_CONFIG;
