import React from 'react';
import EntityGridToolbar from 'containers/Grid/components/EntityGridToolbar';

const V2CGridToolbar = (props) => {
	const {
        refreshGrid,
		  } = props;

	return (
		<EntityGridToolbar
      		onRefresh={refreshGrid}
      		showRefreshButton={true}
			showSearch={false}
		/>
	);
};

V2CGridToolbar.propTypes = {};

export default V2CGridToolbar;