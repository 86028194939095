import { connect } from 'react-redux';
import { compose } from 'redux';
import {push} from 'connected-react-router';
import LoginForm from '../components/LoginForm';
import { loginActions } from 'pages/LoginPage/modules/login.slice';
import { selectLoginIsLoading } from 'pages/LoginPage/modules/login.selectors';

const mapStateToProps = (state, props) => {
	return {
		isLoading: selectLoginIsLoading(state),
	}
};

const withConnect = connect(mapStateToProps, {...loginActions, push});


export default compose(
	withConnect
)(LoginForm);

