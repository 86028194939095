import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { appSelectors, selectDeviceTypesIdToLabelMap } from 'containers/App/modules/app.slice';
import RegionDevicePairs from 'pages/Users/components/RegionDevicePairs';

const mapStateToProps = createStructuredSelector({
		locationsIdToLabelMap: appSelectors.selectLocationsIdToLabelMap,
		deviceTypesIdToLabelMap: appSelectors.selectDeviceTypesIdToLabelMap
	}
);

export default connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(RegionDevicePairs);