import {call , put, takeLatest } from 'redux-saga/effects';
import UserManagement from "../../../libs/client-user-management";
import { showSuccessToast, showErrorToast } from 'containers/App/modules/toasts/toasts.actions';
import { push } from 'connected-react-router';
import { resetPasswordActions } from 'pages/ResetPasswordPage/modules/resetPassword.slice';
import { api } from 'containers/App/modules/async.saga';


export function* resetPassword(action) {

	yield call(api, {
		apiFn:   [UserManagement, UserManagement.resetPassword],
		params:  action.payload,
		actions: [
			resetPasswordActions.resetPasswordPending,
			resetPasswordActions.resetPasswordSuccess,
			resetPasswordActions.resetPasswordFailure,
		]
	});
}

function* resetPasswordSuccess() {
	yield put(showSuccessToast({ title: 'Password Changed' }));
	yield put(push('/login'));
}

function* resetPasswordFailure(action) {
	yield put(showErrorToast({ title: 'Password reset failed' }));
}

export default function* watchForgotPasswordSaga() {
	yield takeLatest(resetPasswordActions.resetPassword, resetPassword);
	yield takeLatest(resetPasswordActions.resetPasswordSuccess, resetPasswordSuccess);
	yield takeLatest(resetPasswordActions.resetPasswordFailure, resetPasswordFailure);
}