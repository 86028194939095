import { createActionWithScope, createFailureReducer, createPendingReducer, createSuccessReducer, getFormattedScopeName } from 'containers/App/modules/redux.util';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { loginActions } from '../../LoginPage/modules/login.slice';

export const CONSENT_STATE_KEY = 'consent';
export const INITIAL_STATE = {
	allUserConfirmedConsentsData: [],
	confirmedConsentsIds: [],
	data: null,
};

const fetchUserConsents = createActionWithScope(CONSENT_STATE_KEY, 'fetchUserConsents');
const fetchAllUserConfirmedConsents = createActionWithScope(CONSENT_STATE_KEY, 'fetchAllUserConfirmedConsents');

const consentSlice = createSlice({
	name:          getFormattedScopeName(CONSENT_STATE_KEY),
	initialState:  INITIAL_STATE,
	reducers:      {
        fetchUserConsentsPending: createPendingReducer(),
        fetchUserConsentsSuccess: createSuccessReducer({setStateDataFn:(state, action) => {
			state.data = action.payload.data;
		}}),
        fetchUserConsentsFailure: createFailureReducer(),
		
        getConsentPending: createPendingReducer(),
        getConsentSuccess: 	createSuccessReducer({setStateDataFn:(state, action) => {
			reducerHelperFunctions.updateConsentDetails(state, action)
		}}),
        getConsentFailure: createFailureReducer(),
		
		confirmConsentPending: createPendingReducer(),
		confirmConsentSuccess: createSuccessReducer({setStateDataFn:(state, action) => {
			reducerHelperFunctions.updateConsentToConfirmed(state, action)
		}}),
		confirmConsentFailure: createFailureReducer(),
		
		fetchUserConfirmedConsentsPending: createPendingReducer(),
		fetchUserConfirmedConsentsSuccess: createSuccessReducer({setStateDataFn:(state, action) => {
			// extracting the id of the first consent, because this feature does not support more than one consent.
			state.confirmedConsentsIds = action.payload?.data?.consents?.[0].id;
		}}),
		fetchUserConfirmedConsentsFailure: createFailureReducer(),
		
		getUserConfirmedConsentPending: createPendingReducer(),
		getUserConfirmedConsentSuccess: createSuccessReducer({setStateDataFn:(state, action) => {
			state.allUserConfirmedConsentsData = new Array(action.payload.data);
		}}),
		getUserConfirmedConsentFailure: createFailureReducer(),
	},
	extraReducers:{
		[loginActions.logoutSuccess]:  () => INITIAL_STATE,
	}
});

export const consentActions = {
    fetchUserConsents,
	fetchAllUserConfirmedConsents,
    confirmConsent: createActionWithScope(CONSENT_STATE_KEY, 'confirmConsent',(index) => ({payload: { index }})),
	...consentSlice.actions
};

const getState = state => state[CONSENT_STATE_KEY] || INITIAL_STATE;

export const consentSelectors = {
	selectConsentsLoading: createSelector(
		getState,
		consentState => {
			return !consentState.data;
		}
	),
	getConsents: createSelector(
		getState,
		consentState => {
			return consentState.data;
		}
	),
	getConsent: createSelector(
		getState,
		(_, props) => props.payload.index, // in order to pass arguments (ignores state)
		(consentState, index) => {
			return consentState.data?.[index];
		}
	),
	getConsentDetails: createSelector(
		getState,
		(_, props) => props.payload.index, // in order to pass arguments (ignores state)
		(consentState, index) => {
			return consentState.data?.[index]?.details;
		}
	),
	getConfirmedConsentsIds: createSelector(
		getState,
		(consentState) => {
			return consentState.confirmedConsentsIds;
		}
	),
	getAreAllConsentsConfirmed: createSelector(
		getState,
		(consentState) => {
			return new Set(consentState?.confirmedConsentsIds)?.size === consentState?.data?.length;
		}
	),
	getAllUserConfirmedConsentDownloadUrl: createSelector(
		getState,
		(consentState) => {
			return consentState.allUserConfirmedConsentsData?.[0]?.downloadUrl;
		}
	),
};

const reducerHelperFunctions = {
	updateConsentDetails: (state, action)=> {
		const consentToUpdate = state?.data?.find(consent => consent.id === action.payload.data.id);
		if(consentToUpdate){
			consentToUpdate.details = action.payload.data;
		}
	},
	updateConsentToConfirmed: (state, action)=>{
		const {requestParams:{data:{revisionId, localeName}}} = action.payload
		const consentToUpdate = state?.data?.find(consent => consent?.details?.revisionId === revisionId  && consent?.details?.localeName === localeName);
		if(consentToUpdate){
			consentToUpdate.isAccepted = true;
			state.confirmedConsentsIds.push(consentToUpdate.id);
		}
	},
};


export default consentSlice.reducer;