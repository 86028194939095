import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createActionWithScope } from 'containers/App/modules/redux.util';
import { userGroupsActions } from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import _ from 'lodash';
import { countriesActions } from 'containers/App/modules/entities/countries/countries.slice';
import { deviceTypesActions } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import { alarmsEntityActions } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';


export const APP_STATE_KEY = 'app';

const appStart = createActionWithScope(APP_STATE_KEY, 'appStart');
const pullActiveAlarmsCount = createActionWithScope(APP_STATE_KEY, 'pullActiveAlarmsCount');

export const INITIAL_STATE = {};

const userGroupsSuccessReducer = (state, action) => {
	const userGroups = _.get(action, 'payload.data.entities.userGroups');
	state.allowedUserGroups = _.sortBy(_.values(userGroups), 'name');
};

const locationsCaseReducer = (state, action) => {
	const { tree, idToLabelMap } = _.get(action, 'payload.data');
	state.locationsTree = tree;
	state.locationsIdToLabelMap = idToLabelMap;
};

const deviceTypesReducer = (state, action) => {
	const { tree, idToLabelMap } = _.get(action, 'payload.data');
	state.deviceTypesTree = tree;
	state.deviceTypesIdToLabelMap = idToLabelMap;
};

const activeAlarmsCountReducer = (state, action) => {
	state.activeAlarmsCount = action.payload.metadata.page.totalResults;
};

const appSlice = createSlice({
	name:          '[app]',
	initialState:  INITIAL_STATE,
	extraReducers: {
		[userGroupsActions.fetchUserGroupsSuccess]:        userGroupsSuccessReducer,
		[countriesActions.fetchLocationsHierarchySuccess]: locationsCaseReducer,
		[deviceTypesActions.fetchDeviceTypesHierarchySuccess]: deviceTypesReducer,
		[alarmsEntityActions.fetchActiveAlarmsCountSuccess]: activeAlarmsCountReducer,
	}

});

export const appActions = {
	appStart,
	pullActiveAlarmsCount,
};

const getAppState = state => state.app || INITIAL_STATE;

const selectAllowedUserGroups = createSelector(
	getAppState,
	appState => appState.allowedUserGroups
);

//todo for now - probably should be in its own reducer for domain data which is not db entities
export const selectLocationsTreeDataNew = createSelector(
	getAppState,
	appState => appState.locationsTree
);

export const selectLocationsIdToLabelMap = createSelector(
	getAppState,
	appState => {
		return appState.locationsIdToLabelMap
	}
);

export const selectDeviceTypesTreeDataNew = createSelector(
	getAppState,
	appState => appState.deviceTypesTree
);

export const selectDeviceTypesIdToLabelMap = createSelector(
	getAppState,
	appState => {
		return appState.deviceTypesIdToLabelMap
	}
);

export const selectActiveAlarmsCount = createSelector(
	getAppState,
	appState => appState.activeAlarmsCount
);

export const appSelectors = {
	selectAllowedUserGroups,
	selectLocationsTreeDataNew,
	selectLocationsIdToLabelMap,
	selectDeviceTypesTreeDataNew,
	selectDeviceTypesIdToLabelMap,
	selectActiveAlarmsCount
};

const { reducer } = appSlice;
export default reducer;
