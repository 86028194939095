import {useEffect} from 'react';

const AdfsLogoutComplete = ({logoutSuccess}) => {

  useEffect(() => {
    logoutSuccess();
  }, [])

  return null;
}

export default AdfsLogoutComplete;