import React from 'react';
import { Input } from 'antd';
import FormField from 'components/FormFactory/FormField';

const TextAreaField = (props) => {
	return (
		<FormField {...props}>
			<Input.TextArea />
		</FormField>
	);
};

TextAreaField.propTypes = {
	...FormField.propTypes,
};

export default TextAreaField;