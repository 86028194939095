import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';

import { selectCountriesTreeData } from 'containers/App/modules/countries/countries.selectors';
import { Cascader } from 'antd';


const CountrySelector = React.forwardRef((props, ref) => {
	const { countries, dispatch, ...rest } = props;

	const filter = (inputValue, path) => {
		return path.some(option => _.includes(option.label.toLowerCase(), inputValue.toLowerCase()));
	};

	return (
		<Cascader
			ref={ref}
			options={countries}
			showSearch={{ filter }}
			placeholder="Country"
			{...rest}
		/>
	)
});

CountrySelector.propTypes = Cascader.propTypes;

const mapStateToProps = (state) => {
	const countries = selectCountriesTreeData(state);
	return { countries };
};

export default compose(
	connect(
		mapStateToProps,
		null,
		null,
		{ forwardRef: true }
	)
)(CountrySelector);