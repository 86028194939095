import React, { useEffect, useState } from 'react';
import AppConfig from '../../../config/AppConfig';
import { Icon, Menu } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import messages from 'containers/App/messages';
import Box from 'components/Box';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';

const { SubMenu } = Menu;

const MenuWrapper = styled.div`
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const TermsAndConditions = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 0px 30px 24px;
	*:not(:last-child) {
		margin-bottom: 5px;
	}
`;

const SideMenu = props => {
	const { alarmsCount, pullActiveAlarmsCount, confirmedConsentDownloadUrl } = props;

	useEffect(() => {
		hasPermission(PERMISSIONS.UI_VIEW_ALARMS) && pullActiveAlarmsCount();
	}, []);

	const [selectedItem, setSelectedItem] = useState(window.location.pathname.split('/')[1] || 'home')
	const onSelect = (e) => setSelectedItem(e.key);

	return (
		<MenuWrapper>
			<Menu
				theme="dark"
				mode="inline"
				selectedKeys={[selectedItem]}
				onSelect={onSelect}
			>
				<Menu.Item key="home">
					<RouterLink to="/">
						<Box hAligned>
							<Icon type="home" />
							<FormattedMessage {...messages.home} />
						</Box>
					</RouterLink>
				</Menu.Item>
				{
					//todo-permissions: need to decouple permissions from UI! VerifyChildPermissions caused problems with antd here (class name undefined)
					hasPermission(PERMISSIONS.UI_VIEW_USERS) &&
					<Menu.Item key="users">
						<RouterLink to="/users">
							<Box hAligned>
								<Icon type="user" />
								<FormattedMessage {...messages.users} />
							</Box>
						</RouterLink>
					</Menu.Item>
				}
				{
					hasPermission(PERMISSIONS.UI_VIEW_DEVICES) &&
					<Menu.Item key="devices">
						<RouterLink to="/devices">
							<Box hAligned>
								<Icon type="cloud-server" />
								<FormattedMessage {...messages.devices} />
							</Box>
						</RouterLink>
					</Menu.Item>
				}
				{
					hasPermission(PERMISSIONS.UI_VIEW_DEVICES) &&
					<Menu.Item key="licenses" hidden={AppConfig.HIDE_LICENSES}>
						<RouterLink to="/licenses">
							<Box hAligned>
								<Icon type="safety-certificate" />
								<FormattedMessage {...messages.licenses} />
							</Box>
						</RouterLink>
					</Menu.Item>
				}
				{
					hasPermission(PERMISSIONS.UI_VIEW_ALARMS) &&
					<Menu.Item key="alarms">
						<RouterLink to="/alarms">
							<Box hAligned>
								<Icon type="info-circle" />
								{Number.isInteger(alarmsCount) ?
									<FormattedMessage {...messages.alarmsCount} values={{ alarmsCount }} /> :
									<FormattedMessage {...messages.alarms} />
								}
							</Box>
						</RouterLink>
					</Menu.Item>
				}
				{
					hasPermission(PERMISSIONS.UI_VIEW_QLIK_MENU) &&
					<Menu.Item key="qlik">
						<RouterLink to="/qlik">
							<Icon type="bar-chart" />
							<FormattedMessage {...messages.qlik} />
						</RouterLink>
					</Menu.Item>
				}
				<Menu.Item key="connectWise">
					<RouterLink to="/connectWise">
						<Icon type="block" rotate={90} />
						<FormattedMessage {...messages.connectWise} />
					</RouterLink>
				</Menu.Item>

				{
					hasPermission(PERMISSIONS.UI_VIEW_FIRMWARE_MANAGER_TAB) &&
					<SubMenu key="fota"
						title={
							<Box hAligned>
								<Icon type="fullscreen" />
								<FormattedMessage {...messages.fota} />
							</Box>}
					>
						{
							hasPermission(PERMISSIONS.UI_VIEW_GROUPS) &&
							<Menu.Item key="groups">
								<RouterLink to="/groups">
									<Icon type="gold" />
									<FormattedMessage {...messages.groups} />
								</RouterLink>
							</Menu.Item>
						}
						{
							hasPermission(PERMISSIONS.UI_VIEW_SOFTWARE_VERSIONS) &&
							<Menu.Item key="swVersions">
								<RouterLink to="/swVersions">
									<Icon type="number" />
									<FormattedMessage {...messages.swVersions} />
								</RouterLink>
							</Menu.Item>
						}
						{
							hasPermission(PERMISSIONS.UI_VIEW_LUMX_VERSIONS) &&
							<Menu.Item key="lumenisXVersions">
								<RouterLink to="/lumenisXVersions">
									<Icon type="number" />
									<FormattedMessage {...messages.lumenisXVersions} />
								</RouterLink>
							</Menu.Item>
						}
					</SubMenu>
				}
				<Menu.Item key="eventViewer">
					<RouterLink to="/eventViewer">
						<Box hAligned>
							<Icon type="container" />
							<FormattedMessage {...messages.eventViewer} />
						</Box>
					</RouterLink>
				</Menu.Item>
			</Menu>
			<TermsAndConditions>
				<a href={confirmedConsentDownloadUrl} target="blank"><FormattedMessage {...messages.termsOfUse} /></a>
				<a href={`${confirmedConsentDownloadUrl}#_100:2433`} target="blank"><FormattedMessage {...messages.userPrivacyNotice} /></a>
			</TermsAndConditions>
		</MenuWrapper>
	)
};

export default SideMenu;