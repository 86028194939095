import { call, put, takeLatest } from "redux-saga/effects";
import BackendService from "services/BackendService";
import { licenseEntityActions } from "containers/App/modules/entities/licenses/licenseEntity.slice";
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { parseFetchAllSuccess } from "containers/App/modules/entities/licenses/license.parsers";
import { successToast, errorToast } from "containers/App/modules/entities/entities.utils";
import licenseMessages from "pages/Licenses/messages";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import { licensesActions } from '../../../../../pages/Licenses/modules/licenses.slice';
import { MODAL_TYPES } from "containers/ModalRoot/components/constants";

function* fetchLicenses(action) {
	yield call(api, {
		apiFn: BackendService.searchLicenses,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.licenses],
		params: action.payload,
		actions: [
			licenseEntityActions.fetchLicensesPending,
			licenseEntityActions.fetchLicensesSuccess,
			licenseEntityActions.fetchLicensesFailure,
		],
	});
}

function* regenerateLicense(action) {
	yield call(api, {
		apiFn: BackendService.regenerateLicense,
		params: action.payload,
		actions: [
			licenseEntityActions.regenerateLicensePending,
			licenseEntityActions.regenerateLicenseSuccess,
			licenseEntityActions.regenerateLicenseFailure,
		],
	});
	yield call(action.payload.refresh);
}

function* validateLicense(action) {
	yield call(api, {
		apiFn: BackendService.validateLicense,
		params: action.payload.params,
		actions: [
			licenseEntityActions.validateLicensePending,
			licenseEntityActions.validateLicenseSuccess,
			licenseEntityActions.validateLicenseFailure,
		],
	});
	yield call(action.payload.refresh);
}

function* disableLicense(action) {
	yield call(api, {
		apiFn: BackendService.disableLicense,
		params: action.payload.params,
		actions: [
			licenseEntityActions.disableLicensePending,
			licenseEntityActions.disableLicenseSuccess,
			licenseEntityActions.disableLicenseFailure,
		],
	});
	yield call(action.payload.refresh);
}

function* enableLicense(action) {
	yield call(api, {
		apiFn: BackendService.enableLicense,
		params: action.payload.params,
		actions: [
			licenseEntityActions.enableLicensePending,
			licenseEntityActions.enableLicenseSuccess,
			licenseEntityActions.enableLicenseFailure,
		],
	});
	yield call(action.payload.refresh);
}

function* viewLicense(action) {
	yield call(api, {
		apiFn: BackendService.viewLicense,
		params: action.payload,
		actions: [
			licenseEntityActions.viewLicensePending,
			licenseEntityActions.viewLicenseSuccess,
			licenseEntityActions.viewLicenseFailure,
		],
	});
}

function* abortLicenseActions(action) {
	yield call(api, {
		apiFn: BackendService.abortLicenseActions,
		params: action.payload.params,
		actions: [
			licenseEntityActions.abortLicenseActionsPending,
			licenseEntityActions.abortLicenseActionsSuccess,
			licenseEntityActions.abortLicenseActionsFailure,
		],
	});
	yield call(action.payload.refresh);
}

function* regenerateLicenseSuccess() {
	yield call(successToast, licenseMessages.regenerateSuccess.defaultMessage);
}

function* regenerateLicenseError(action) {
	yield call(errorToast, action, licenseMessages.regenerateError.defaultMessage);
}

function* validateLicenseSuccess() {
	yield call(successToast, licenseMessages.validateSuccess.defaultMessage);
}

function* validateLicenseError(action) {
	yield call(errorToast, action, licenseMessages.validateError.defaultMessage);
}

function* disableLicenseSuccess() {
	yield call(successToast, licenseMessages.disableSuccess.defaultMessage);
}

function* disableLicenseError(action) {
	yield call(errorToast, action, licenseMessages.disableError.defaultMessage);
}

function* enableLicenseSuccess() {
	yield call(successToast, licenseMessages.enableSuccess.defaultMessage);
}

function* enableLicenseError(action) {
	yield call(errorToast, action, licenseMessages.enableError.defaultMessage);
}

function* viewLicenseError(action) {
	yield call(errorToast, action, licenseMessages.viewError.defaultMessage);
}

function* abortLicenseActionsSuccess() {
	yield call(successToast, licenseMessages.abortLicenseActionsSuccess.defaultMessage);
}

function* abortLicenseActionsError(action) {
	yield call(errorToast, action, licenseMessages.abortLicenseActionsError.defaultMessage);
}

function* viewLicenseSuccess(action) {
	yield put(licensesActions.setDecryptedLicense(action.payload.data.decryptedLicense));
	yield put(modalActions.showModal(
		{
			modalType: MODAL_TYPES.VIEW_LICENSE_MODAL,
			modalProps: {
				title: "View Decrypted License",
				footer: null,
				width: 700,
			},
			componentProps: {},
		}
	));
}

export default function* watchLicensesSaga() {
	yield takeLatest(licenseEntityActions.fetchLicenses, fetchLicenses);

	yield takeLatest(licenseEntityActions.regenerateLicense, regenerateLicense);
	yield takeLatest(licenseEntityActions.regenerateLicenseSuccess, regenerateLicenseSuccess);
	yield takeLatest(licenseEntityActions.regenerateLicenseFailure, regenerateLicenseError);

	yield takeLatest(licenseEntityActions.validateLicense, validateLicense);
	yield takeLatest(licenseEntityActions.validateLicenseSuccess, validateLicenseSuccess);
	yield takeLatest(licenseEntityActions.validateLicenseFailure, validateLicenseError);

	yield takeLatest(licenseEntityActions.disableLicense, disableLicense);
	yield takeLatest(licenseEntityActions.disableLicenseSuccess, disableLicenseSuccess);
	yield takeLatest(licenseEntityActions.disableLicenseFailure, disableLicenseError);

	yield takeLatest(licenseEntityActions.enableLicense, enableLicense);
	yield takeLatest(licenseEntityActions.enableLicenseSuccess, enableLicenseSuccess);
	yield takeLatest(licenseEntityActions.enableLicenseFailure, enableLicenseError);

	yield takeLatest(licenseEntityActions.viewLicense, viewLicense);
	yield takeLatest(licenseEntityActions.viewLicenseSuccess, viewLicenseSuccess);
	yield takeLatest(licenseEntityActions.viewLicenseFailure, viewLicenseError);

	yield takeLatest(licenseEntityActions.abortLicenseActions, abortLicenseActions);
	yield takeLatest(licenseEntityActions.abortLicenseActionsSuccess, abortLicenseActionsSuccess);
	yield takeLatest(licenseEntityActions.abortLicenseActionsFailure, abortLicenseActionsError);
}
