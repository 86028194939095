import React, { useEffect } from 'react';
import AppConfig from 'config/AppConfig';


const Qlik = () => {
	useEffect(() => {
		window.open(AppConfig.QLIK_URL, "_blank")
	})
	// useEffect is without [] so it will render on every update
	// because we want that for each click on Qlik option on the side menu a new tab will open
	return <div />
};

export default Qlik;