import React from 'react';

import FormField from 'components/FormFactory/FormField';

const CustomField = (props) => {
	const {formItem, ...rest} = props;
	return (
		<FormField {...rest}>
			{formItem}
		</FormField>
	);
};

CustomField.propTypes = {
	...FormField.propTypes
};

export default CustomField;