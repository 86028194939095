import { createSlice } from '@reduxjs/toolkit';
import { swVersionEntityActions } from 'containers/App/modules/entities/swVersions/swVersionEntity.slice';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { DEFAULT_LOADING_PROP } from 'containers/App/modules/redux.contants';
import {
	createResetGridReducer,
	createSetGridParamsReducer,
	createUpdateGridReducer,
	GRID_INITIAL_STATE,
	createSetGridParamsAction,
	createResetGridDataAction,
} from 'containers/Grid/grid.utils';


const SW_VERSIONS_STATE_KEY = 'swVersions';
const SW_VERSIONS_GRID_STATE_KEY = 'swVersionsGrid';
const setGridParams = createSetGridParamsAction(SW_VERSIONS_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(SW_VERSIONS_GRID_STATE_KEY);

export const INITIAL_STATE = {
	editedSwVersionId:           undefined,
	editedSwVersion:             undefined,
	[SW_VERSIONS_GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const swVersionsSlice = createSlice({
	name:          SW_VERSIONS_STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		clearEditedSwVersion:
			(state, action) => {
				delete state.editedSwVersionId;
				delete state.editedSwVersion;
				delete state.potentialManagers;
			},
	},
	extraReducers: {
		[swVersionEntityActions.fetchSwVersionSuccess]:              (state, action) => {
			const { data: { result }, metadata: {editable} } = action.payload;
			state.editedSwVersionId = result;
		},

		//GRID
		[swVersionEntityActions.fetchSwVersionsSuccess]: createUpdateGridReducer(SW_VERSIONS_GRID_STATE_KEY),
		[setGridParams]:                       createSetGridParamsReducer(SW_VERSIONS_GRID_STATE_KEY),
		[resetGridData]:                       createResetGridReducer(SW_VERSIONS_GRID_STATE_KEY),
	}
});


const getSwVersionsState = state => state[SW_VERSIONS_STATE_KEY] || INITIAL_STATE;

const selectEditedSwVersionId = createSelector(
	getSwVersionsState,
	swVersionsState => _.get(swVersionsState, 'editedSwVersionId')
);

const selectEditedSwVersionPotentialManagersIds = createSelector(
	getSwVersionsState,
	swVersionsState => _.get(swVersionsState, 'potentialManagers')
);

//todo fix
const selectPotentialSwVersionsPending = createSelector(
	getSwVersionsState,
	swVersionsState => _.get(swVersionsState, DEFAULT_LOADING_PROP)
);

const selectSwVersionsGrid = createSelector(
	getSwVersionsState,
	swVersionsState => {
		return swVersionsState[SW_VERSIONS_GRID_STATE_KEY]
	}
);

const selectSwVersionIdsForPage = (state, props) => {
	const gridState = selectSwVersionsGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

export const swVersionSelectors = {
	selectEditedSwVersionId,
	selectEditedSwVersionPotentialManagersIds,
	selectPotentialSwVersionsPending,
	selectSwVersionsGrid,
	selectSwVersionIdsForPage
};

const {
	      clearEditedSwVersion,
      } = swVersionsSlice.actions;
export const swVersionsActions = {
	clearEditedSwVersion,
	setGridParams,
	resetGridData
};
export default swVersionsSlice.reducer;