import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from 'antd';
import styled from 'styled-components';

import { hasPermission } from 'services/PermissionsService';
import FormFactory from 'components/FormFactory';
import Search from 'components/Search';


const EntityToolbar = styled.section`
	max-height: 40px;

	display: flex;
	align-items: center;
	margin-bottom: 16px;
	
	& > * {
		margin-right: 20px;
	}
`;

const Form = FormFactory.create({
	name:           'entityToolbarFilters',
	onValuesChange: (props, changedValues, allValues) => {
		const { onChange } = props;
		onChange && onChange(allValues);
	}
});

const EntityGridToolbar = (props) => {
	const {
		      addNewPermission,
		      showAddNew,
		      onAddNew,
		      filterFormElements,
		      onSearchChange,
          onFiltersChange,
          onRefresh,
		      onResetFilters,
		      showRefreshButton = true,
			  showSearch = true,
	      } = props;

	const searchRef = React.createRef();

	const onReset = () => {
		const searchInput = searchRef.current;
		searchInput.setValue("");
		onResetFilters && onResetFilters();
	};

	//todo-permissions rethink the model. should not be side effects from here
	//todo this mode requires an addNewPermission - maybe sometimes there's no permission for it so maybe showAddNewButton should come from outside
	const showAddNewButton = showAddNew && _.isFunction(onAddNew) && (addNewPermission ? hasPermission(addNewPermission) : true);
	return (
		<EntityToolbar>
			{
				showAddNewButton &&
				<Button onClick={onAddNew}
				        type="primary">
					+
				</Button>
			}
			{
				showSearch &&
				<Search
							ref={searchRef}
							onChange={onSearchChange} style={{ width: 200 }}
						/>
			}
			{
				filterFormElements &&
				<Form
					layout="inline"
					hideSubmitButton
					showResetButton
					onReset={onReset}
					onChange={onFiltersChange}>
					{filterFormElements}
				</Form>
			}

			{
				showRefreshButton &&
				<Button type="primary" shape="circle" icon="reload" onClick={onRefresh} style={{display: 'flex', justifyContent:'center', alignItems: 'center', marginLeft: 'auto'}} />
			}
		</EntityToolbar>
	);
};

EntityGridToolbar.propTypes = {
	addNewPermission:   PropTypes.string,
	showAddNew:         PropTypes.bool,
	onAddNew:           PropTypes.func,
	filterFormElements: PropTypes.object,
	onFiltersChange:    PropTypes.func,
	onSearchChange:     PropTypes.func,
	onResetFilters:     PropTypes.func,
	showSearch:         PropTypes.bool,
};

export default EntityGridToolbar;