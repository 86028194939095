import { defineMessages } from "react-intl";

export const scope = "validSelector";

export default defineMessages({
  selectLogMode: {
    id: `${scope}.selectLogMode`,
    defaultMessage: "Select Log Mode",
  },
  information: {
    id: `${scope}.information`,
    defaultMessage: "Information",
  },
  debug: {
    id: `${scope}.debug`,
    defaultMessage: "Debug",
  },
  action: {
    id: `${scope}.action`,
    defaultMessage: "Action",
  },
});
