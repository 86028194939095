import { defineMessages } from "react-intl";

export const scope = "formFactory";

export default defineMessages({
	clickToUpload: {
		id: `${scope}.clickToUpload`,
		defaultMessage: "Click To Upload",
	},
});
