import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { createSelector } from "reselect";

import { deviceEntityActions } from "containers/App/modules/entities/devices/deviceEntity.slice";
import { DEFAULT_LOADING_PROP } from "containers/App/modules/redux.contants";
import {
  createResetGridDataAction,
  createResetGridReducer,
  createSetGridParamsAction,
  createSetGridParamsReducer,
  createUpdateGridReducer,
  GRID_INITIAL_STATE,
} from "containers/Grid/grid.utils";
import { dbSelectors } from "containers/App/modules/entities/entities.selectors";
import entitySchemas from "containers/App/modules/api/schema";

export const DEVICES_STATE_KEY = "devices";
const DEVICES_GRID_STATE_KEY = "devicesGrid";
const LATEST_COMMANDS_STATE_KEY = "latestCommands";
const COMMANDS_HISTORY_STATE_KEY = "commandsHistory";
const setGridParams = createSetGridParamsAction(DEVICES_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(DEVICES_GRID_STATE_KEY);

export const INITIAL_STATE = {
  assignedUsers: undefined,
  customerDetails: undefined,
  latestCommands: {},
  commandsHistory: {},
  availableFiles: [],
  [DEVICES_GRID_STATE_KEY]: GRID_INITIAL_STATE,
};

const setAssignedUsersStateFn = (state, action) => {
  state.assignedUsers = action.payload.data;
};

const setLatestCommandsStateFn = (state, action) => {
  const newCommands = {};
  action.payload.data.forEach((command) => {
    newCommands[command.commandType] = command;
  });
  state.latestCommands = newCommands;
};

const setCommandsHistoryStateFn = (state, action) => {
  const newCommands = {};
  action.payload.data.data.forEach((command) => {
    if (!newCommands[command.commandType]) {
      newCommands[command.commandType] = [];
    }
    newCommands[command.commandType].push(command);
  });
  state.commandsHistory = { ...newCommands, ...state.commandsHistory };
};

const onFetchDevicesSuccess = (state, action) => {
  const { metadata } = action.payload;

  state.isAtLeastOneDeviceAttachedToComposedGroup =
    metadata.atLeastOneDeviceAttachedToUpdateGroup;
  createUpdateGridReducer(DEVICES_GRID_STATE_KEY)(state, action);
};

const onFetchFilesSuccess = (state, action) => {
  state.availableFiles = action.payload.data.data;
};

const devicesSlice = createSlice({
  name: DEVICES_STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setAssignedUsers: (state, action) => {
      state.assignedUsers = action.payload;
    },

    clearEditData: (state) => {
      delete state.assignedUsers;
      delete state.customerDetails;
    },

    clearCommandState: (state, action) => {
      const commandType = action.payload;
      const { [commandType]: _, ...rest } = state.commandsHistory;
      state.commandsHistory = rest;
    },
  },
  extraReducers: {
    [deviceEntityActions.fetchAssignedUsersSuccess]: setAssignedUsersStateFn,
    [deviceEntityActions.fetchDeviceCommandsSuccess]: setLatestCommandsStateFn,
    [deviceEntityActions.searchCommandsSuccess]: setCommandsHistoryStateFn,
    [deviceEntityActions.fetchDeviceFilesSuccess]: onFetchFilesSuccess,
    //GRID
    [deviceEntityActions.fetchDevicesSuccess]: onFetchDevicesSuccess,
    [setGridParams]: createSetGridParamsReducer(DEVICES_GRID_STATE_KEY),
    [resetGridData]: createResetGridReducer(DEVICES_GRID_STATE_KEY),
  },
});

const getDevicesState = (state) => state[DEVICES_STATE_KEY] || INITIAL_STATE;

const selectDevicesAssignedToGroup = (state, groupId) => {
  const devices =
    dbSelectors.selectEntitiesDenormalized(
      [entitySchemas.device],
      _.get(state, "devices.devicesGrid.currentIds")
    )(state) || [];

  return devices.filter((device) => device.composedGroupId === groupId);
};

const selectEditedDeviceId = createSelector(getDevicesState, (devicesState) =>
  _.get(devicesState, "editedDeviceId")
);

const selectEditedDevicePotentialManagersIds = createSelector(
  getDevicesState,
  (devicesState) => _.get(devicesState, "potentialManagers")
);

const selectPotentialDevicesPending = createSelector(
  getDevicesState,
  (devicesState) => _.get(devicesState, DEFAULT_LOADING_PROP)
);

const selectDevicesGrid = createSelector(getDevicesState, (devicesState) => {
  return devicesState[DEVICES_GRID_STATE_KEY];
});

const selectCommands = createSelector(getDevicesState, (devicesState) => {
  return devicesState[LATEST_COMMANDS_STATE_KEY];
});

const selectCommandsHistory = createSelector(getDevicesState, (deviceState) => {
  return deviceState[COMMANDS_HISTORY_STATE_KEY];
});

const selectDeviceIdsForPage = (state, props) => {
  const gridState = selectDevicesGrid(state);
  const pageNumber = _.get(gridState, "params.pagination.current");
  return gridState.pages[pageNumber];
};

const selectAssignedUsers = createSelector(
  getDevicesState,
  (devicesState) => devicesState.assignedUsers
);

const selectAssignedUsersPending = createSelector(
  getDevicesState,
  (devicesState) => devicesState.assignedUsersPending
);

const selectAvailableFiles = createSelector(
  getDevicesState,
  (deviceState) => deviceState.availableFiles
);

export const deviceSelectors = {
  selectEditedDeviceId,
  selectEditedDevicePotentialManagersIds,
  selectPotentialDevicesPending,
  selectDevicesGrid,
  selectDeviceIdsForPage,
  selectAssignedUsers,
  selectDevicesAssignedToGroup,
  selectCommands,
  selectCommandsHistory,
  selectAvailableFiles,
};

const {
  clearEditedDevice,
  setAssignedUsers,
  clearEditData,
  clearCommandState,
} = devicesSlice.actions;
export const devicesActions = {
  clearEditedDevice,
  setAssignedUsers,
  clearEditData,
  setGridParams,
  resetGridData,
  clearCommandState,
};
export default devicesSlice.reducer;
