import _ from 'lodash';

export const parseFetchAllSuccess = responseData => {
	return responseData.data;
};

export const parseFetchAllRequest = payload => {
	if (!payload) return payload;
	const { pagination, additionalData } = payload;
	const { current, pageSize } = pagination;

	return {
		limit: pageSize,
		page:  current - 1,
		...additionalData,
	}
};
