import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import _ from 'lodash';
import { NOOP } from 'containers/App/app.constants';
import { getHierarchyData } from 'containers/App/app.utils';
import { createSelector } from '@reduxjs/toolkit';

export const parseFetchAllSuccess = responseData => _.get(responseData, 'deviceTypes');

export const parseFetchHierarchySuccess = responseData => {
	return getHierarchyData(responseData.deviceTypeItems);
};

export const deviceTypeEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.DEVICE_TYPES,
	parseFetchAllSuccess,
	extraAsyncActions: [
		{
			type: 'fetchDeviceTypesHierarchy',
			setDataFn: NOOP
		}
	]
});

const { reducer, actions, selectors } = deviceTypeEntitySlice;
const {
	      fetchAll:        fetchDeviceTypes,
	      fetchAllPending: fetchDeviceTypesPending,
	      fetchAllSuccess: fetchDeviceTypesSuccess,
	      fetchAllFailure: fetchDeviceTypesFailure,

	      fetchDeviceTypesHierarchy,
	      fetchDeviceTypesHierarchyPending,
	      fetchDeviceTypesHierarchySuccess,
	      fetchDeviceTypesHierarchyFailure
      } = actions;


export const deviceTypesActions = {
	fetchDeviceTypes,
	fetchDeviceTypesPending,
	fetchDeviceTypesSuccess,
	fetchDeviceTypesFailure,

	fetchDeviceTypesHierarchy,
	fetchDeviceTypesHierarchyPending,
	fetchDeviceTypesHierarchySuccess,
	fetchDeviceTypesHierarchyFailure,
};

const {
	      selectAll: selectDeviceTypes,
	      selectFetchAllPending: selectDeviceTypesPending
      } = selectors;

const getDeviceTypesState = state => state.entities.deviceTypes;
const selectDeviceTypesHierarchyLoading = createSelector(
	getDeviceTypesState,
	deviceTypesState => deviceTypesState.fetchDeviceTypesHierarchyLoading
);

export const deviceTypesSelectors = {
	selectDeviceTypes,
	selectDeviceTypesPending,
	selectDeviceTypesHierarchyLoading
};


export default reducer;