import { parseFetchAllRequest as parseAllRequest } from 'containers/App/modules/entities/users/users.parsers';

export const parseFetchAllRequest = payload =>
	parseAllRequest(payload);

export const parseFetchAllSuccess = responseData => {
	return responseData.data;
};

export const parseFetchAlarmStates = responseData => {
	const states = {};

	responseData.states.forEach(state => states[state.id] = { ...state });

	return states;
};
