import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select } from 'antd';
import { ALL_VALUE } from 'containers/App/app.constants';
import { selectRegions } from 'containers/App/modules/countries/countries.selectors';
import styled from 'styled-components';

const RegionSelect = styled(Select)`
	min-width: 100px;
`;

const RegionSelector = React.forwardRef((props, ref) => {
	const { regions, dispatch, withAll, ...rest } = props;

	const newRegions = withAll && regions ? [ALL_VALUE, ...regions] : regions;

	return (
		<RegionSelect
			placeholder="Region"
			{...rest}>
			{
				newRegions && newRegions.map((region, i) =>
					<Select.Option key={i} value={region}>{region}</Select.Option>
				)
			}
		</RegionSelect>
	)
});

RegionSelector.propTypes = {
	...Select.propTypes,
	withAll: PropTypes.bool
};

const mapStateToProps = (state) => ({
	regions: selectRegions(state)
});

export default connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(RegionSelector);