import { defineMessages } from "react-intl";

export const scope = "groups";

export default defineMessages({
	update: {
		id: `${scope}.update`,
		defaultMessage: "Update",
    },
    create: {
		id: `${scope}.create`,
		defaultMessage: "Create",
    },
    groupName: {
		id: `${scope}.groupName`,
		defaultMessage: "Group Name",
    },
    deviceType: {
		id: `${scope}.deviceType`,
		defaultMessage: "Device Type / Family",
    },
    locations: {
		id: `${scope}.locations`,
		defaultMessage: "Locations",
    },
    deviceTypeNonSelectable: {
		id: `${scope}.deviceTypeNonSelectable`,
		defaultMessage: "In Group, only Device Family or Device Type are assignable",
	},
	publishUpdateButton: {
		id: `${scope}.publishUpdateButton`,
		defaultMessage: "Publish Update",
	},
	swVersion: {
		id: `${scope}.swVersion`,
		defaultMessage: "Software Version",
	},
	allowRemoteSoftwareUpdate: {
		id: `${scope}.allowRemoteSoftwareUpdate`,
		defaultMessage: "Allow Remote Software Update(*)",
	},
	lumXVersion: {
		id: `${scope}.lumXVersion`,
		defaultMessage: "LumenisX Version",
	},
});
