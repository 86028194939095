import React, { useEffect, useState } from 'react';
import AppConfig from 'config/AppConfig';
import UserManagement from '../../../libs/client-user-management';

const STATUS = Object.freeze({
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
});

const EventViewer = () => {

  const [status, setStatus] = useState(STATUS.LOADING);

  const statusComponent = {
    [STATUS.LOADING]: <div style={{ color: 'transparent' }}>Loading...</div>,
    [STATUS.SUCCESS]: <div style={{ color: 'transparent' }}>Success</div>,
    [STATUS.ERROR]: <div style={{ color: 'transparent' }}>Error</div>,
  }

  const sendRequest = async () => {
    try {
      setStatus(STATUS.SUCCESS);
      await openWindowWithPostRequest();
    } catch (err) {
      console.error(err);
      setStatus(STATUS.ERROR);
    }
  }

  async function openWindowWithPostRequest() {
    const userData = await UserManagement.getUserData();
    const winName = "EventViewer";
    const winURL = AppConfig.EVENT_VIEWER_URL;

    const params = {
      //the access and refresh token expiration can only be gotten this way so we take the token themsleves too without using the helper functions
      "accessToken": userData.accessJwt.token,
      "accessTokenExpiration": userData.accessJwt.expiration,
      "refreshToken": userData.refreshJwt.token,
      "refreshTokenExpiration": userData.refreshJwt.expiration
    }

    console.log("Logging opening event viewer window with data: \n" + JSON.stringify({
      ...params, accessToken: params.accessToken.slice(0, 10),
      refreshToken: params.refreshToken.slice(0, 10)
    }));

    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", winURL);
    form.setAttribute("target", winName);
    for (var param in params) {
      if (params[param]) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = param;
        input.value = params[param];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    window.open('', winName);
    form.target = winName;
    form.submit();

  }


  useEffect(() => {
    sendRequest();
  })
  // useEffect is without [] so it will render on every update
  // because we want that for each click on Qlik option on the side menu a new tab will open
  return statusComponent[status];
};

export default EventViewer;