export const ENTITY_TYPES = {
	USERS: 'users',
	USER_GROUPS: 'userGroups',
	DEVICE_TYPES: 'deviceTypes',
	DEVICES: 'devices',
	MANAGERS: 'managers',
	COUNTRIES: 'countries',
	ALARMS: 'alarms',
	CUSTOMER_DETAILS: 'customerDetails',
	GROUPS: 'groups',
	SW_VERSIONS: 'swVersions',
	V2C: 'v2c',
	LUMENISX_VERSIONS: 'lumenisXVersions',
	DEVICE_ALARMS: 'deviceAlarms',
	DEVICE_FILES: 'deviceFiles',
	LICENSES: 'licenses',
};

export const API_ERROR_CODES = {
	USER_NOT_FOUND: 'User does not exist',
	LOGIN_FAIL: 'Password is not valid',
	REQUEST_VALIDATION_FAILED: 'Request Failed',
	EMAIL_NOT_VALID: 'This site is restricted to Lumenis employees only. Please use the Lumenis email address',
	USERNAME_ALREADY_EXIST: 'User already exists',
	ACCESS_DENIED: 'You do not have access permission to perform the requested operation',
	USER_CAN_NOT_BE_ASSIGNED: 'Assigning this user to the manager is not valid',
	MANUFACTURER_ID_ALREADY_EXISTS: 'This device already exists',
	DEVICE_TYPE_NOT_FOUND: 'There is no device type associated with this device',
	MISMATCH_COUNTRY_STATE: 'Wrong state for selected country',
	DEVICE_NOT_FOUND: 'The device does not exist',
	GATEWAY_NOT_FOUND: 'The device does not exist',
	DEVICE_TYPE_LOGS_NOT_EXISTS: 'Device logs not supported for this device type.',
	'timeout of 60000ms exceeded': 'Failed due to very long request.',
};

export const ENTITIES_STATE_KEY = 'entities';
export const ENTITIES_INITIAL_STATE = {};

// export const SET_ENTITIES_ACTION_TYPE = 'setEntities';