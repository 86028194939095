import saga from './modules/resetPassword.saga';

import ResetPasswordPage from './components/ResetPasswordPage';
import { resetPasswordActions, resetPasswordSelectors } from 'pages/ResetPasswordPage/modules/resetPassword.slice';

export default {
	component: ResetPasswordPage,
	actions: resetPasswordActions,
	selectors: resetPasswordSelectors,
	saga
};