import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';

//TODO probably a better way to build the tree?
const getCountriesTreeData = (countriesList) => {
	let treeData = [];

	const groupByRegion = _.groupBy(countriesList, 'region');
	_.forEach(groupByRegion, (value, key) => {
		const countriesPerRegion = _.map(value, (countryObj => {
			const { country, states } = countryObj;
			const sortedStates = states.slice().sort();

			return {
				label: country,
				value: country,
				states: sortedStates,
			};
		}));
		treeData.push({
			label:    key,
			value:    key,
			children: countriesPerRegion
		});
	});

	return treeData;
};

const getLocationsTreeData = (countriesList) => {
	let treeData = [];

	const groupByRegion = _.groupBy(countriesList, 'region');
	_.forEach(groupByRegion, (countriesInRegion, regionName) => {
		const countriesPerRegion = _.map(countriesInRegion, (countryObj => {
			const { country, states } = countryObj;

			const countryValue = `${regionName}/${country}`;
			return {
				title:    country,
				value:    countryValue,
				children: _.map(
					_.sortBy(states), state => ({
					title: state,
					value: `${countryValue}/${state}`
				}))
			};
		}));
		treeData.push({
			title:    regionName,
			value:    regionName,
			children: countriesPerRegion
		});
	});

	return treeData;
};

const getRegions = (allCountries) =>
	_.keys(_.groupBy(allCountries, 'region'));


const selectAllCountries = dbSelectors.selectAllEntities(ENTITY_TYPES.COUNTRIES);

export const selectCountriesTreeData = createSelector(
	selectAllCountries,
	countries => {
		return getCountriesTreeData(countries)
	}
);

export const selectLocationsTreeData = createSelector(
	selectAllCountries,
	countries => {
		return getLocationsTreeData(countries)
	}
);

export const selectRegions = createSelector(
	selectAllCountries,
	countries => getRegions(countries)
);
