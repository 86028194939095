import { connect } from 'react-redux';
import { compose } from 'redux';

import ModalRoot from '../components/ModalRoot';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';


const mapStateToProps = (state) => state.modal;

export default compose(
	connect(mapStateToProps, modalActions)
)(ModalRoot);