import { connect } from 'react-redux';
import { compose } from 'redux';
import {push} from 'connected-react-router';
import AdfsLoginComponent from '../components/AdfsLoginComponent'
import {loginActions} from '../modules/login.slice';


const withConnect = connect(null, {...loginActions, push});


export default compose(
	withConnect
)(AdfsLoginComponent);

