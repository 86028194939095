import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';

import userReducer, { userSelectors, usersActions } from '../modules/users.slice';

import UsersGrid from 'pages/Users/components/UsersGrid';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { userEntitySelectors } from 'containers/App/modules/entities/users/userEntity.slice';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import schema from 'containers/App/modules/api/schema';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';
import { selectLoggedInUser } from 'pages/LoginPage/modules/login.selectors';
import entitySchemas from 'containers/App/modules/api/schema';
// import { gridActions } from 'containers/Grid/grid.utils';

const withReducer = injectReducer({
	key:     'users',
	reducer: userReducer
});

const mapStateToProps = (state, props) => {
	//todo find a better paradigm for data selection?
	const grid = userSelectors.selectUsersGrid(state);
	const currentUserIds = _.get(state, 'users.usersGrid.currentIds');
	//todo should not be dependent on schema here - move to selectors
	const users = dbSelectors.selectEntitiesDenormalized([schema.user], currentUserIds)(state);
	const canUpdateUser = hasPermission(PERMISSIONS.UI_UPDATE_USER);
	const loggedInUserId = selectLoggedInUser(state);
	const loggedInUser = dbSelectors.selectEntitiesDenormalized(entitySchemas.user, loggedInUserId)(state);

	return {
		isUsersLoading: userEntitySelectors.selectUsersPending(state),
		canUpdateUser,
		users,
		grid,
		loggedInUserGroup: loggedInUser.group.id,
	}

};
const withConnect = connect(mapStateToProps, { ...userEntityActions, ...modalActions, ...usersActions });

export default compose(
	withReducer,
	withConnect
)(UsersGrid);