import React, { useEffect } from 'react';
import AuthPageBase from 'components/AuthPageBase/AuthPageBase';
import LoginForm from '../containers/LoginFormContainer';
import { STORAGE_LOGIN_DATA_KEY } from '../../../containers/App/app.constants';

const LoginPage = () => {

    useEffect(() => {
        sessionStorage.removeItem(STORAGE_LOGIN_DATA_KEY);
    }, [])

    return <AuthPageBase form={<LoginForm/>} /> 

}

export default LoginPage;