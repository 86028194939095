import React from 'react';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Typography } from 'antd';

import FormFactory from 'components/FormFactory';
import PasswordField from 'components/FormFactory/fields/PasswordField';
import Box from 'components/Box';


const { Text, Title } = Typography;
const Form = FormFactory.create({ name: 'resetPasswordForm' });
const passwordIcon = <Icon type="lock" />;

const ResetPasswordForm = (props) => {
	const { token, userid } = props;

	const onSubmit = ({ password }) => {
		props.resetPassword({ userid, token, password });
	};

	const { isLoading } = props;

	return (
		!token || !userid ?
			<Text type="danger"><FormattedMessage {...messages.viewNotValid} /></Text> :
			<Box direction="column" gutter={20}>
				<Title level={4} style={{ color: 'white', textAlign: 'center' }}>Password Reset</Title>

				<Form
					onSubmit={onSubmit}
					submitButtonText="Reset Password"
					isButtonLoading={isLoading}
					style={{ width: 250 }}
				>
					{
						form => {
							function compareToFirstPassword(rule, value, callback) {
								if (value && value !== form.getFieldValue('password')) {
									callback('Password must match');
								} else {
									callback();
								}
							}

							return (
								<React.Fragment>
									<PasswordField
										name="password"
										required
										prefix={passwordIcon}
										placeholder="Password"
										rules={[
											{
												pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d$@$!%*#?&]{8,}$',
												message: 'Password should be at least 8 characters long, and contain an upper case letter, a lower case letter, and a number'
											}
										]}
									/>

									<PasswordField
										name="passwordConfirm"
										required
										prefix={passwordIcon}
										placeholder="Password Confirm"
										rules={[
											{ validator: compareToFirstPassword }
										]}
									/>

								</React.Fragment>
							);
						}}
				</Form>
			</Box>
	);
};

export default ResetPasswordForm;
