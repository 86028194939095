import React from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';


const errorDetailsStyle = { whiteSpace: 'pre-wrap' };

class ErrorBoundary extends React.Component {
	static propTypes = {
		title: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		})
		// You can also log error messages to an error reporting service here
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			return (
				<Box direction="column" centered>
					<h2>{this.props.title || 'Something went wrong.'}</h2>
					<details style={errorDetailsStyle}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</Box>
			);
		}

		// Normally, just render children
		return this.props.children;
	}
}

export const withErrorBoundary = (Component) => props =>
	(
		<ErrorBoundary>
			<Component {...props} />
		</ErrorBoundary>
	);

export default ErrorBoundary;