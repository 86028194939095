import { takeLatest, call } from 'redux-saga/effects';
import { countriesActions } from 'containers/App/modules/entities/countries/countries.slice';
import { api } from 'containers/App/modules/async.saga';
import BackendService from 'services/BackendService';
import { parseFetchAllSuccess, parseFetchHierarchySuccess } from 'containers/App/modules/entities/countries/countries.slice';
import schemas from 'containers/App/modules/api/schema';


function* fetchAllCountries(action) {
	yield call(api, {
		apiFn:   BackendService.fetchCountries,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.country],
		actions: [
			countriesActions.fetchCountriesPending,
			countriesActions.fetchCountriesSuccess,
			countriesActions.fetchCountriesFailure,
		]
	});
}

function* fetchLocationsHierarchy(action) {
	yield call(api, {
		apiFn:   BackendService.fetchLocationsHierarchy,
		parseSuccessResponseFn: parseFetchHierarchySuccess,
		actions: [
			countriesActions.fetchLocationsHierarchyPending,
			countriesActions.fetchLocationsHierarchySuccess,
			countriesActions.fetchLocationsHierarchySuccess,
		]
	});
}

export default function* watchCountriesSaga() {
	yield takeLatest(countriesActions.fetchCountries, fetchAllCountries);
	yield takeLatest(countriesActions.fetchLocationsHierarchy, fetchLocationsHierarchy);
}