import Loadable from 'pages/Devices/Loadable';
import { devicesActions } from 'pages/Devices/modules/devices.slice';
// import reducer from './modules/user.reducer';
// import saga from './modules/device.saga'

export default {
	component: Loadable,
	actions: devicesActions,
	// saga,
	// reducer
};