import React from 'react';
import PropTypes from 'prop-types';
import FormField from 'components/FormFactory/FormField';
import { Input } from 'antd';


const PasswordField = (props) => {

	return (
		<FormField {...props}>
			<Input type="password"/>
		</FormField>

	);
};

PasswordField.propTypes = {
	...FormField.propTypes
};

export default PasswordField;