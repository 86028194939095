import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';

import {
	parseFetchAllRequest,
	parseCreateRequest,
	parseCreateSuccess,
	parseUpdateRequest,
	parseUpdateSuccess,
} from './users.parsers';
import { NOOP } from 'containers/App/app.constants';


const extraAsyncActions = [
	{
		type: 'fetchPotentialManagers',
		/*setStateDataFn: (state, action) => {
			state.potentialManagers = _.get(action, 'payload.data.result');
		}*/
	},
	{
		type: 'fetchLoggedInUser'
	},
	{
		type:           'updatePreferences',
		setStateDataFn: NOOP
	},
];

const mergeCustomizerFn = (objValue, srcValue, key, object, source, stack) => {
	if (key === 'devices') {
		return source[key];
	}
};

const userEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.USERS,
	extraAsyncActions,
	mergeCustomizerFn,
	parseFetchAllRequest,
	parseCreateRequest,
	parseCreateSuccess,
	parseUpdateRequest,
	parseUpdateSuccess,
});

const { reducer, actions, selectors } = userEntitySlice;
const {
	      fetchAll:        fetchUsers,
	      fetchAllPending: fetchUsersPending,
	      fetchAllSuccess: fetchUsersSuccess,
	      fetchAllFailure: fetchUsersFailure,

	      fetchById: fetchUser,
	      fetchByIdPending: fetchUserPending,
	      fetchByIdSuccess: fetchUserSuccess,
	      fetchByIdFailure: fetchUserFailure,

	      create: createUser,
	      createPending: createUserPending,
	      createSuccess: createUserSuccess,
	      createFailure: createUserFailure,

	      update: updateUser,
	      updatePending: updateUserPending,
	      updateSuccess: updateUserSuccess,
	      updateFailure: updateUserFailure,

	      fetchPotentialManagers,
	      fetchPotentialManagersFailure,
	      fetchPotentialManagersPending,
	      fetchPotentialManagersSuccess,

	      fetchLoggedInUser,
	      fetchLoggedInUserFailure,
	      fetchLoggedInUserPending,
	      fetchLoggedInUserSuccess,

	      updatePreferences,
	      updatePreferencesFailure,
	      updatePreferencesPending,
	      updatePreferencesSuccess,

      } = actions;

export const userEntityActions = {
	fetchUsers,
	fetchUsersPending,
	fetchUsersSuccess,
	fetchUsersFailure,

	fetchUser,
	fetchUserPending,
	fetchUserSuccess,
	fetchUserFailure,

	createUser,
	createUserPending,
	createUserSuccess,
	createUserFailure,

	updateUser,
	updateUserPending,
	updateUserSuccess,
	updateUserFailure,

	fetchPotentialManagers,
	fetchPotentialManagersFailure,
	fetchPotentialManagersPending,
	fetchPotentialManagersSuccess,

	fetchLoggedInUser,
	fetchLoggedInUserFailure,
	fetchLoggedInUserPending,
	fetchLoggedInUserSuccess,

	updatePreferences,
	updatePreferencesFailure,
	updatePreferencesPending,
	updatePreferencesSuccess,
};


const {
	      selectAll:              selectUsers,
	      selectFetchAllPending:  selectUsersPending,
	      createSelectByIdOrIds:  createSelectUsersByIdOrIds,
	      selectCreatePending:    selectCreateUserPending,
	      selectFetchByIdPending: selectFetchUserPending,
      } = selectors;

const selectIsPotentialManagersPending = state => {
	return state.entities.users.fetchPotentialManagersLoading;
};

const selectFetchLoggedInUserPending = state => {
	return state.entities.users.fetchLoggedInUserLoading;
};

export const userEntitySelectors = {
	selectUsers,
	selectUsersPending,
	createSelectUsersByIdOrIds,
	selectCreateUserPending,
	selectFetchUserPending,
	selectIsPotentialManagersPending,
	selectFetchLoggedInUserPending,
};

export default reducer;