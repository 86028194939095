import { call, takeLatest, put } from 'redux-saga/effects';
import BackendService from 'services/BackendService';
import { actions as v2cActions } from './v2cEntity.slice';
import { api } from '../../async.saga';
import entitySchemas from '../../api/schema';
import { parseFetchAllSuccess, parseFetchAllRequest } from './v2c.parsers';


function* fetchV2CHistory(action) {
  const request = parseFetchAllRequest(action.payload);

	yield call(api, {
    apiFn:                  BackendService.searchConfigurations,
    parseSuccessResponseFn: parseFetchAllSuccess,
		schema:                 [entitySchemas.v2cHistory],
		params:                 {data: request},
		actions:                [
			v2cActions.fetchV2CHistoryPending,
			v2cActions.fetchV2CHistorySuccess,
			v2cActions.fetchV2CHistoryFailure
		]
	});
}


function* updateV2C(action) {
  const v2cData = action.payload;
  const file = v2cData['V2C File'][0].originFileObj;

  try {
    const uploadURLData = yield call(BackendService.getConfigurationUploadUrl);
    const { url, configurationId } = uploadURLData.data;

    const createUpdateConfigurationRequest = {
      clientId: v2cData.manufacturerId,
      type: 'v2c',
      name: file.name,
      description: v2cData.comments
    }

    yield call(BackendService.uploadFile, url, file);
    yield call(BackendService.createConfiguration, {data: createUpdateConfigurationRequest, configurationId});
    yield put(v2cActions.updateV2CSuccess());
  } catch (error) {
    yield put(v2cActions.updateV2CFailed());
  }
}

export default function* watchV2CSaga() {
  yield takeLatest(v2cActions.fetchV2CHistory, fetchV2CHistory);
  
  yield takeLatest(v2cActions.updateV2C, updateV2C);
}