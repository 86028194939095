import { defineMessages } from "react-intl";

export const scope = "licenses";

export default defineMessages({
	regenerateSuccess: {
		id: `${scope}.regenerateSuccess`,
		defaultMessage: "Regenerate License Command Sent Successfully.",
	},
	regenerateError: {
		id: `${scope}.regenerateError`,
		defaultMessage: "Failed Regenerating License.",
	},
	validateSuccess: {
		id: `${scope}.validateSuccess`,
		defaultMessage: "Validate License Command Sent Successfully. Please refresh the table in a couple of minutes.",
	},
	validateError: {
		id: `${scope}.validateError`,
		defaultMessage: "Failed Sending License validation command.",
	},
	disableSuccess: {
		id: `${scope}.disableSuccess`,
		defaultMessage: "Disable License Command Sent Successfully.",
	},
	disableError: {
		id: `${scope}.disableError`,
		defaultMessage: "Failed Disabling License.",
	},
	enableSuccess: {
		id: `${scope}.enableSuccess`,
		defaultMessage: "Enable License Command Sent Successfully.",
	},
	enableError: {
		id: `${scope}.enableError`,
		defaultMessage: "Failed Enabling License.",
	},
	viewError: {
		id: `${scope}.viewError`,
		defaultMessage: "Failed Decrypting and Viewing License.",
	},
	abortLicenseActionsSuccess: {
		id: `${scope}.enableSuccess`,
		defaultMessage: "Successfully aborted all ongoing actions of this license.",
	},
	abortLicenseActionsError: {
		id: `${scope}.abortLicenseActionsError`,
		defaultMessage: "Failed Aborting License Actions.",
	},
});
