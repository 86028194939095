import { createActionWithScope, createFailureReducer, createPendingReducer, createSuccessReducer, getFormattedScopeName } from 'containers/App/modules/redux.util';
import { createSlice } from '@reduxjs/toolkit';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';
import _ from 'lodash';

export const STATE_KEY = 'login';
export const INITIAL_STATE = {};

const login = createActionWithScope(STATE_KEY, 'login');
const loginWithToken = createActionWithScope(STATE_KEY, 'loginWithToken');
const loginWithAdfsSuccess = createActionWithScope(STATE_KEY, 'loginWithAdfsSuccess');
const logout = createActionWithScope(STATE_KEY, 'logout');


const loginSlice = createSlice({
	name:          getFormattedScopeName(STATE_KEY),
	initialState:  INITIAL_STATE,
	reducers:      {
		// login: NOOP,
		loginRequest: createPendingReducer(),
		loginSuccess: createSuccessReducer(),
		loginFailure: createFailureReducer(),

		// loginWithToken: NOOP,
		loginWithTokenRequest: createPendingReducer(),
		loginWithTokenSuccess: createSuccessReducer(),
		loginWithTokenFailure: createFailureReducer(),

		logoutRequest: createPendingReducer(),
		logoutSuccess: createSuccessReducer(),
		logoutFailure: createFailureReducer(),
	},
	extraReducers: {
		[userEntityActions.fetchLoggedInUserSuccess]:
			(state, action) => {
				state.loggedInUser = _.get(action, 'payload.data.result');
			}
	}
});

export const loginActions = {
	login,
	loginWithToken,
	loginWithAdfsSuccess,
	logout,
	...loginSlice.actions
};
export default loginSlice.reducer;