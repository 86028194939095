import { takeLatest, call } from 'redux-saga/effects';
import { deviceTypesActions, parseFetchHierarchySuccess } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import { api } from 'containers/App/modules/async.saga';
import BackendService from 'services/BackendService';
import { parseFetchAllSuccess } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import schemas from 'containers/App/modules/api/schema';


function* fetchAllDeviceTypes(action) {
	yield call(api, {
		apiFn:   BackendService.fetchDeviceTypes,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.deviceType],
		actions: [
			deviceTypesActions.fetchDeviceTypesPending,
			deviceTypesActions.fetchDeviceTypesSuccess,
			deviceTypesActions.fetchDeviceTypesFailure,
		]
	});
}

function* fetchDeviceTypesHierarchy(action) {
	yield call(api, {
		apiFn:   BackendService.fetchDeviceTypesHierarchy,
		parseSuccessResponseFn: parseFetchHierarchySuccess,
		actions: [
			deviceTypesActions.fetchDeviceTypesHierarchyPending,
			deviceTypesActions.fetchDeviceTypesHierarchySuccess,
			deviceTypesActions.fetchDeviceTypesHierarchyFailure,
		]
	});
}

export default function* watchDeviceTypesSaga() {
	yield takeLatest(deviceTypesActions.fetchDeviceTypes, fetchAllDeviceTypes);
	yield takeLatest(deviceTypesActions.fetchDeviceTypesHierarchy, fetchDeviceTypesHierarchy);
}