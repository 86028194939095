import React, { useEffect, useState } from "react";
import { Layout, Spin, Avatar, Typography, Dropdown, Menu, Button } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";

import {
  Spacer,
  StyledHeader,
  MainContent,
  CollapseIcon,
  HeaderComponentsBox,
  SpinBox,
  StyledLayout,
} from "./AppLayout.styled";
import Logo from "components/Logo";
import DevicesPage from "pages/Devices";
import LicensesPage from "pages/Licenses";
import HomePage from "pages/HomePage/Loadable";
import NotFoundPage from "containers/NotFoundPage/Loadable";
import SideMenuContainer from "containers/App/containers/SideMenuContainer";
import TestPage from "pages/TestPage";
import UsersPage from "pages/Users";
import Qlik from "pages/Qlik";
import ConnectWise from "pages/ConnectWise";
import EventViewer from "pages/EventViewer";
import PreferencesPage from "pages/Preferences";
import AlarmsPage from "pages/Alarms";
import GroupsPage from "pages/Groups";
import SwVersionsPage from "pages/SwVersions";
import LumenisXVersionsPage from "pages/LumenisXVersions";
import { hasPermission, PERMISSIONS } from "services/PermissionsService";
import { APP_ROUTES } from "../app.constants";
import APP_CONFIG from "config/AppConfig";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const PADDED_PAGE_CONTENT = "24px 24px 0px 24px";
const NOT_PADDED_PAGE_CONTENT = "0px 0px 0px 0px";
const { Sider } = Layout;
const MenuLogo = styled(Logo)`
  height: 60px;
  padding: 8px 8px 8px 0;
  margin: 0;
`;

const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

const CountryIcon = (props) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => window.open(APP_CONFIG.OTHER_REGION_URL, "_blank")}
    >
      <FormattedMessage {...messages.switchTo} />{" "}
      <img
        style={{ maxWidth: "28px", maxHeight: "28px" }}
        src={APP_CONFIG.OTHER_REGION_ICON_FILE_PATH}
        alt=""
      ></img>
    </div>
  );
};

const AppLayout = (props) => {
  const {
    isAppLoading,
    loginWithToken,
    userName,
    logout,
    location,
    consents,
    areAllConsentsConfirmed,
  } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    loginWithToken();
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const setFormTitle = (title) => {
    setTitle(title);
  };

  const navigateToPreferences = () => {
    props.push("/preferences");
    setTitle("User Preferences");
  };

  const userMenu = (
    <Menu>
      <Menu.Item>
        <Button type="link" onClick={navigateToPreferences}>
          User Preferences
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="link" onClick={logout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return isAppLoading ? (
    <SpinBox centered>
      <Spin spinning={true} size="large" />
    </SpinBox>
  ) : consents?.length === 0 || areAllConsentsConfirmed ? ( // in case there are no consents to confirm we can render the app layout & allow routing to the inner pages of the app
    <StyledLayout>
      <StyledSider trigger={null} collapsible collapsed={collapsed}>
        <MenuLogo small={collapsed} />
        <SideMenuContainer />
      </StyledSider>
      <Layout>
        <StyledHeader>
          <HeaderComponentsBox gutter={12} hAligned>
            <CollapseIcon
              className="trigger"
              type={collapsed ? "menu-unfold" : "menu-fold"}
              onClick={toggle}
            />
            <Typography.Text strong>{title}</Typography.Text>
            <Spacer />
            <CountryIcon>This is flag</CountryIcon>

            <Typography.Text>{userName}</Typography.Text>
            <Dropdown overlay={userMenu}>
              <Avatar icon="user" />
            </Dropdown>
          </HeaderComponentsBox>
        </StyledHeader>
        <MainContent
          padding={
            location.pathname === "/home"
              ? NOT_PADDED_PAGE_CONTENT
              : PADDED_PAGE_CONTENT
          }
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route
              path="/home"
              render={() => <HomePage onClick={setFormTitle("")} />}
            />
            {
              //todo-permissions: need to decouple permissions from UI! VerifyChildPermissions caused problems here
              hasPermission(PERMISSIONS.UI_VIEW_USERS) && (
                <Route
                  path="/users"
                  render={() => (
                    <UsersPage.component onClick={setFormTitle("Users")} />
                  )}
                />
              )
            }
            {hasPermission(PERMISSIONS.UI_VIEW_DEVICES) && (
              <Route
                path="/devices"
                render={() => (
                  <DevicesPage.component onClick={setFormTitle("Devices")} />
                )}
              />
            )}
            {hasPermission(PERMISSIONS.UI_VIEW_DEVICES) && (
              <Route
                path="/licenses"
                render={() => (
                  <LicensesPage.component onClick={setFormTitle("Licenses")} />
                )}
              />
            )}
            {hasPermission(PERMISSIONS.UI_VIEW_QLIK_MENU) && (
              <Route
                path="/qlik"
                render={() => <Qlik.component onClick={setFormTitle("Qlik")} />}
              />
            )}
            <Route
              path="/connectWise"
              render={() => (
                <ConnectWise.component onClick={setFormTitle("Connect Wise")} />
              )}
            />
            <Route
              path="/eventViewer"
              render={() => (
                <EventViewer.component onClick={setFormTitle("Event Viewer")} />
              )}
            />
            <Route
              path="/preferences"
              render={() => (
                <PreferencesPage.component
                  onClick={setFormTitle("User Preferences")}
                />
              )}
            />
            {hasPermission(PERMISSIONS.UI_VIEW_ALARMS) && (
              <Route
                path="/alarms"
                render={() => (
                  <AlarmsPage.component onClick={setFormTitle("Alarms")} />
                )}
              />
            )}
            <Route
              path="/groups"
              render={() => (
                <GroupsPage.component onClick={setFormTitle("Groups")} />
              )}
            />
            <Route
              path="/swVersions"
              render={() => (
                <SwVersionsPage.component
                  onClick={setFormTitle("Sw Versions")}
                />
              )}
            />
            <Route
              path="/lumenisXVersions"
              render={() => (
                <LumenisXVersionsPage.component
                  onClick={setFormTitle("LumenisX Versions")}
                />
              )}
            />
            <Route
              path="/test"
              render={() => <TestPage onClick={setFormTitle("")} />}
            />
            <Route
              path=""
              render={() => <NotFoundPage onClick={setFormTitle("")} />}
            />
          </Switch>
        </MainContent>
      </Layout>
    </StyledLayout>
  ) : (
    <Redirect to={{ pathname: APP_ROUTES.LOGIN, state: { from: location } }} />
  ); //redirect to login incase there are consents to confirm
};

AppLayout.displayName = "AppLayout";
export default React.memo(AppLayout);
