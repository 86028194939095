import { call, takeLatest, put } from 'redux-saga/effects';
import BackendService from 'services/BackendService';
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { alarmsEntityActions } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';

import { parseFetchAllSuccess, parseFetchAlarmStates } from "containers/App/modules/entities/alarms/alarms.parsers";

function* fetchAlarms(action) {
  yield call(api, {
		apiFn: BackendService.fetchAlarms,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema:                 [schemas.alarms],
		params:                 action.payload,
		actions:                [
			alarmsEntityActions.fetchAlarmsPending,
			alarmsEntityActions.fetchAlarmsSuccess,
			alarmsEntityActions.fetchAlarmsFailure
		]
	});

}

function* fetchAlarmStates(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchAlarmStates,
		parseSuccessResponseFn: parseFetchAlarmStates,
		actions:                [
			alarmsEntityActions.fetchAlarmStatesPending,
			alarmsEntityActions.fetchAlarmStatesSuccess,
			alarmsEntityActions.fetchAlarmStatesFailure,
		]
	});
}

function* fetchActiveAlarmsCount(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchAlarms,
		params:					{ limit: 1 },
		actions:                [
			alarmsEntityActions.fetchActiveAlarmsCountPending,
			alarmsEntityActions.fetchActiveAlarmsCountSuccess,
			alarmsEntityActions.fetchActiveAlarmsCountFailure,
		]
	});
}


export default function* watchUsersSaga() {
  yield takeLatest(alarmsEntityActions.fetchAlarms, fetchAlarms);
  yield takeLatest(alarmsEntityActions.fetchAlarmStates, fetchAlarmStates);
  yield takeLatest(alarmsEntityActions.fetchActiveAlarmsCount, fetchActiveAlarmsCount);
}