import { call, takeLatest } from "redux-saga/effects";
import BackendService from "services/BackendService";
import { deviceFilesEntityActions as actions } from "./deviceFilesEntity.slice";
import { errorToast } from "../entities.utils";
import { api } from "containers/App/modules/async.saga";

/* eslint-disable require-yield */

function* downloadDeviceFilesById(action) {
  yield call(api, {
    apiFn: BackendService.downloadFilesByIds,
    params: action.payload,
    actions: [
      actions.downloadDeviceFilesPending,
      actions.downloadDeviceFilesSuccess,
      actions.downloadDeviceFilesFailure,
    ],
  });
}

function* downloadDeviceFilesSuccess(action) {
  window.open(action.payload.data.url, "_blank");
}

function* downloadDeviceFilesFailure(action) {
  yield call(
    errorToast,
    action,
    "Failed for one of the reasons: 1.Not found files to download 2.There are too many files 3.There are heavy files 4.Internal error during download",
    action.payload.message
  );
}

export default function* watchFileSaga() {
  yield takeLatest(actions.downloadDeviceFiles, downloadDeviceFilesById);
  yield takeLatest(
    actions.downloadDeviceFilesSuccess,
    downloadDeviceFilesSuccess
  );
  yield takeLatest(
    actions.downloadDeviceFilesFailure,
    downloadDeviceFilesFailure
  );
}
