import { createSlice } from '@reduxjs/toolkit';
import { groupEntityActions } from 'containers/App/modules/entities/groups/groupEntity.slice';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { DEFAULT_LOADING_PROP } from 'containers/App/modules/redux.contants';
import {
	createResetGridReducer,
	createSetGridParamsReducer,
	createUpdateGridReducer,
	GRID_INITIAL_STATE,
	createSetGridParamsAction,
	createResetGridDataAction,
} from 'containers/Grid/grid.utils';


const GROUPS_STATE_KEY = 'groups';
const GROUPS_GRID_STATE_KEY = 'groupsGrid';
const GROUP_DEVICES_GRID_STATE_KEY = 'groupDevicesGrid';
const setGridParams = createSetGridParamsAction(GROUPS_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(GROUPS_GRID_STATE_KEY);

const setDevicesGridParams = createSetGridParamsAction(GROUP_DEVICES_GRID_STATE_KEY);
const resetDevicesGridData = createResetGridDataAction(GROUP_DEVICES_GRID_STATE_KEY);

export const INITIAL_STATE = {
	editedGroupId:           undefined,
	editedGroup:             undefined,
	[GROUPS_GRID_STATE_KEY]: GRID_INITIAL_STATE,
	[GROUP_DEVICES_GRID_STATE_KEY]: GRID_INITIAL_STATE,
	assignedDevices: null
};

const resetAssignedDevicesStateFn = (state, action) => {
	state.assignedDevices = null;
}

const groupsSlice = createSlice({
	name:          GROUPS_STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		clearEditedGroup:
			(state, action) => {
				delete state.editedGroupId;
				delete state.editedGroup;
				delete state.potentialManagers;
			},
		setAssignedDevices: (state, action) => {
				state.assignedDevices = _.uniqBy(action.payload, (device) => device.id);
			},
		resetAssignedDevices: resetAssignedDevicesStateFn
	},
	extraReducers: {
		[groupEntityActions.fetchGroupSuccess]:              (state, action) => {
			const { data: { result }, metadata: {editable} } = action.payload;
			state.editedGroupId = result;
		},
		//groups GRID
		[groupEntityActions.fetchGroupsSuccess]: createUpdateGridReducer(GROUPS_GRID_STATE_KEY),
		[setGridParams]:                       createSetGridParamsReducer(GROUPS_GRID_STATE_KEY),
    	[resetGridData]:                       createResetGridReducer(GROUPS_GRID_STATE_KEY),
    
    	// devices status grid
		[groupEntityActions.fetchGroupDevicesSuccess]: createUpdateGridReducer(GROUP_DEVICES_GRID_STATE_KEY),
		[setDevicesGridParams]:                        createSetGridParamsReducer(GROUP_DEVICES_GRID_STATE_KEY),
		[resetDevicesGridData]:                        createResetGridReducer(GROUP_DEVICES_GRID_STATE_KEY),
	}
});


const getGroupsState = state => state[GROUPS_STATE_KEY] || INITIAL_STATE;

const selectEditedGroupId = createSelector(
	getGroupsState,
	groupsState => _.get(groupsState, 'editedGroupId')
);

const selectEditedGroupPotentialManagersIds = createSelector(
	getGroupsState,
	groupsState => _.get(groupsState, 'potentialManagers')
);

//todo fix
const selectPotentialGroupsPending = createSelector(
	getGroupsState,
	groupsState => _.get(groupsState, DEFAULT_LOADING_PROP)
);

const selectGroupsGrid = createSelector(
	getGroupsState,
	groupsState => {
		return groupsState[GROUPS_GRID_STATE_KEY]
	}
);

const selectGroupDevicesGrid = createSelector(
	getGroupsState,
	groupsState => {
		return groupsState[GROUP_DEVICES_GRID_STATE_KEY]
	}
);

const selectGroupIdsForPage = (state, props) => {
	const gridState = selectGroupsGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

const selectAssignedDevices = createSelector(
	getGroupsState,
	groupsState => {
		return(
		_.get(groupsState, 'assignedDevices'))
	}
);

export const groupSelectors = {
	selectEditedGroupId,
	selectEditedGroupPotentialManagersIds,
	selectPotentialGroupsPending,
	selectGroupsGrid,
	selectGroupIdsForPage,
	selectAssignedDevices,
  	selectGroupDevicesGrid
};

const {
		  clearEditedGroup,
		  setAssignedDevices,
		  resetAssignedDevices
      } = groupsSlice.actions;
export const groupsActions = {
	clearEditedGroup,
	setGridParams,
	resetGridData,
	setAssignedDevices,
	setDevicesGridParams,
	resetDevicesGridData,
	resetAssignedDevices
};
export default groupsSlice.reducer;