import React from 'react';
import { userNameRenderer } from "containers/Grid/components/ColumnRenderers";
import AlreadyAssignedWarning from './AlreadyAssignedWarning';

export const deviceGridColumns = (groupId) => [
	{
		key:       'isAssigned',
		dataIndex: 'composedGroupId',
		sorter:    false,
		width: 1, // TODO: this is ugly solution, to place warning icon next to the assign checkbox (see AlreadyAssignedWarning component).
				 // when upgrading to ant design V4, just provide custom checkbox implementation.
				 // https://ant.design/components/table/#rowSelection.
		align: "left",
		render: (composedGroupId, device) => (
			composedGroupId && composedGroupId !== groupId && // for mock only. when backend is ready, depend on isAssignedToGroup boolean
			<AlreadyAssignedWarning groupName={device.composedGroupName} />
		)
	},
	{
		key: "deviceInfo.deviceSerialNumber",
		title: "Serial Number",
		dataIndex: "deviceSerialNumber",
		sorter: true,
	},
	{
		key: "deviceInfo.deviceType",
		title: "Device Type",
		dataIndex: "deviceType.type",
		sorter: true,
	},
	{
		key:       'customerDetails.clinicId',
		title:     'Clinic ID',
		dataIndex: 'customerDetails.clinicId',
		sorter:    true,
	},
	{
	  key:       'customerDetails.clinicName',
	  title:     'Clinic Name',
	  dataIndex: 'customerDetails.clinicName',
	  sorter:    true,
	},
	{
		key: "customerDetails.address.region",
		title: "Region",
		dataIndex: "customerDetails.address.region",
		sorter: true,
	},
	{
		key: "customerDetails.address.country",
		title: "Country",
		dataIndex: "customerDetails.address.country",
		sorter: true,
	},
];