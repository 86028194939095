import { defineMessages } from 'react-intl';

export const scope = 'loginForm';

export default defineMessages({
	login: {
		id: `${scope}.login`,
		defaultMessage: 'Login',
	},
	forgotPassword: {
		id: `${scope}.forgotPassword`,
		defaultMessage: 'Forgot password',
	},
});
