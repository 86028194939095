import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { SORT_TYPES_MAP } from 'components/DataGrid/dataGrid.constants';
import { USER_IDPS } from '../../../../../pages/Users/constants';
import AppConfig from '../../../../../config/AppConfig'

export const parseFetchAllRequest = payload => {
	if (!payload) return payload;
	const { pagination, sorter, filters, search, additionalData } = payload;
	const { current, pageSize } = pagination;

	const nonEmptyFilters = _.omitBy(filters, _.isNil); //server cannot handle empty values
	const filter = _.map(nonEmptyFilters, (value, key) => ({
		values: value,
		prop: key
	}));

	const sort = !_.isEmpty(sorter) ?
		sorter.column && sorter.column.keys ?
			_.map(sorter.column.keys, (key) => ({
				prop: key,
				order: SORT_TYPES_MAP[sorter.order]
			})) : [{
				prop: sorter.columnKey || sorter.field,
				order: SORT_TYPES_MAP[sorter.order]
			}] : undefined;

	return {
		limit: pageSize,
		page: current - 1,
		search,
		filter,
		sort,
		...additionalData,
	}
};

export const parseFetchAllSuccess = responseData => {
	return _.map(responseData.data, user => {
		const {
			groupId,
			groupName,
			managerId,
			managerFirstName,
			managerLastName,
			...restOfUser
		} = user;
		return {
			...restOfUser,
			group: {
				id: groupId,
				name: groupName
			},
			manager: {
				id: managerId,
				firstName: managerFirstName,
				lastName: managerLastName,
			}
		}
	});
};

export const parseFetchByIdSuccess = responseData => {
	const {
		groups = [],
		manager,
		assignedResources,
		...restOfUser
	} = responseData.data;

	return {
		group: groups[0],
		manager: manager.userResponse,
		devices: _.map(assignedResources, resource => ({
			id: uuidv4(),
			deviceTypes: resource.deviceTypeItems,
			locations: resource.locationTypeItems,
		})),
		...restOfUser
	}
};

export const parseCreateRequest = payload => {
	const { devices, locked, ...restOfUser } = payload;
	const user = {
		locked,
		resources: {
			assignedResourceGroupList: _.map(devices, deviceLocationPair => {
				return {
					deviceTypeItems: deviceLocationPair.deviceTypes,
					locationTypeItems: deviceLocationPair.locations
				}
			}),
		},
		user: {
			groupId: payload.group,
			phone: payload.phoneNumber,
			managerId: payload.manager,
			...restOfUser
		}
	};
	return user;
};
export const parseCreateSuccess = payload => {
	const { data, requestParams } = payload;
	const { userDetails: { userId } } = data;
	const { resources, user } = requestParams;

	return {
		id: userId,
		devices: resources && resources.list ?
			resources.list.map(resource => ({
				device: { id: resource.deviceTypeId },
				region: resource.region
			})) : [],
		...user,
	}
};

export const parseUpdateRequest = payload => {
	const { userId, userValues } = payload;
	const apiUser = parseCreateRequest(userValues);
	return {
		userId,
		data: apiUser
	};
};

export const parseUpdateSuccess = payload => {
	return {
		id: payload.requestParams.userId,
		//todo cannot update user store. responses are different and request is missing data like user group or manager objects.
		// need to normalize data for updates or fetch users again automatically
	}
};


export const parseFetchPotentialManagersSuccess = response => {
	return _.map(response.lumUsersSet, userObj => userObj.userResponse)
};

export const createUserRequestToSupportUserIdPs = request => {
	const currentIDP = AppConfig.LOGIN_VIA_ADFS === false ? USER_IDPS.UMS : USER_IDPS.ADFS;
	return { ...request, user: { ...request.user, userIdPs: [currentIDP] } }
};

export const updateUserRequestToSupportUserIdPs = request => {
	return { ...request, data: createUserRequestToSupportUserIdPs(request.data) } // currently we support only ADFS
};
