import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Input, TreeSelect } from 'antd';
import userEntitySaga from 'containers/App/modules/entities/users/userEntity.saga';

import FormFactory from 'components/FormFactory';
import FormField from 'components/FormFactory/FormField';
import { FIELD_TYPES } from 'components/FormFactory/constants';
import TextField from 'components/FormFactory/fields/TextField';
import PasswordField from 'components/FormFactory/fields/PasswordField';
import EmailField from 'components/FormFactory/fields/EmailField';
import CheckboxField from 'components/FormFactory/fields/CheckboxField';
import Logo from 'components/Logo';
import CountrySelector from 'containers/CountrySelector';
import RegionSelector from 'containers/RegionSelector';
import DeviceTypeSelector from 'containers/DeviceTypeSelector';
import Box from 'components/Box';
import { deviceTypesActions } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import userReducer, { usersActions } from 'pages/Users/modules/users.slice';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import userSaga from 'pages/Users/modules/users.saga';
import { DAEMON } from 'utils/constants';
import UserGroupSelector from 'containers/UserGroupSelector';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';
import Search from 'components/Search';
import { Menu, Dropdown } from 'antd';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { selectCountriesTreeData, selectRegions } from 'containers/App/modules/countries/countries.selectors';
import DeviceTypeTreeSelector from 'containers/DeviceTypeTreeSelector';
import RegionDevicePairs from 'pages/Users/containers/RegionDevicePairsContainer';
import LocationTreeSelector from 'containers/LocationTreeSelector';


const { SubMenu } = Menu;
const { TreeNode } = TreeSelect;

const onvalueschanged = (props, changed, all) => {
	// console.table(all);
};

const Form = FormFactory.create({ name: 'testForm', onValuesChange: onvalueschanged });


const TestPage = (props) => {
	const [pairs, setPairs] = useState([]);

	const { regions, countriesTree } = props;

	const onRegionClick = (...args) => console.log(args);

	const menu = (
		<Menu>
			{
				regions.map((region, i) =>
					<Menu.Item key={i}
					           data-region={region}
					           onClick={onRegionClick}>{region}</Menu.Item>)
			}

		</Menu>
	);


	const { deviceTypes } = props;
	const userInputRef = React.createRef();

	const fetchDeviceTypes = () => {
		props.fetchDeviceTypes();
	};

	const getUser = () => {
		// props.dispatch( {type: '[users]/fetchById', payload: '874a7095-a163-4206-a1bf-3066c2aa15b4'});
		props.fetchUser(userInputRef.current.input.value); //|| '874a7095-a163-4206-a1bf-3066c2aa15b4'
	};
	const onChange = (value) => setPairs(value);
	return (
		<article>
			<div>Test</div>


			{/*Menu:
			<Dropdown overlay={menu}>
				<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
					Menu
				</a>
			</Dropdown>

			<br />

			Tree Select:
			<TreeSelect
				showSearch
				style={{ width: '100%' }}
				dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
				placeholder="Please select"
				allowClear
				treeDefaultExpandAll
			>
				{
					countriesTree.map((region, i) => {
						return <TreeNode
							key={i}
							value={`r: ${region.label}`} title={region.label}>
							{
								region.children.map((country) =>
									<TreeNode
										key={country}
										value={`c: ${country.label}`}
										title={country.label}
									>

									</TreeNode>
								)
							}

						</TreeNode>
					})
				}
			</TreeSelect>
			<br />*/}

			{/*Device Type Tree: <br/>
			<DeviceTypeTreeSelector  withAll style={{width: 700}}/>*/}

			<Form style={{ width: 700 }}>
				<FormField name="devices"
				           label="Device assignment">

					<LocationTreeSelector onChange={onChange}/>

				</FormField>
			</Form>

			<pre>
				{JSON.stringify(pairs, null, 2)}
			</pre>

			{/*
			<br/>

			Search: <Search onChange={(value) => console.log(value)} />


			<pre>{JSON.stringify(process.env, null, 2)}</pre>


			<Button onClick={fetchDeviceTypes}>Get Device Types</Button>

			<Box direction="column" gutter={30}>
				<CountrySelector />

				<RegionSelector />
				<DeviceTypeSelector />

				<Button onClick={() => props.fetchPotentialManagers('de8cc88d-c99f-4803-8c24-77c8d9a31043')}>get managers</Button>


				<Input ref={userInputRef} />
				<Button onClick={getUser}>fetch one user</Button>


			</Box>
*/}

		</article>
	);
};

const mapStateToProps = (state) => {

	return {
		countries:     dbSelectors.selectAllEntities(ENTITY_TYPES.COUNTRIES)(state),
		regions:       selectRegions(state),
		countriesTree: selectCountriesTreeData(state)
	};
};

const withReducer = injectReducer({
	key:     'users',
	reducer: userReducer
});

const withSaga = injectSaga({
	key:  'userSaga',
	saga: userSaga,
	mode: DAEMON
});

const withUserEntitySaga = injectSaga({
	key:  'userEntitySaga',
	saga: userEntitySaga,
	mode: DAEMON
});

const withConnect = connect(mapStateToProps, { ...deviceTypesActions, ...usersActions, ...userEntityActions });
export default compose(
	memo,
	withReducer,
	withSaga,
	withUserEntitySaga,
	withConnect
)(TestPage);
