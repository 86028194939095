import { connect } from 'react-redux';
import { compose } from 'redux';
import ModalLayout from '../components/ModalLayout';
import {consentSelectors, consentActions} from '../modules/consent.slice';

const mapStateToProps = (state) => ({
    consents:consentSelectors.getConsents(state),
});


const withConnect = connect(mapStateToProps, { ...consentActions });

export default compose(
	withConnect
)(ModalLayout);
