import { createActionWithScope, createFailureReducer, createPendingReducer, createSuccessReducer, getFormattedScopeName } from 'containers/App/modules/redux.util';
import { createSelector, createSlice } from '@reduxjs/toolkit';

const STATE_KEY = 'preferences';

export const INITIAL_STATE = {};

const fetchUserPreferences = createActionWithScope(STATE_KEY, 'fetchUserPreferences');
const updateUserPreferences = createActionWithScope(STATE_KEY, 'updateUserPreferences');


const preferencesSlice = createSlice({
	name:         getFormattedScopeName(STATE_KEY),
	initialState: INITIAL_STATE,
	reducers:     {
		fetchUserPreferencesPending: createPendingReducer(),
		fetchUserPreferencesSuccess: createSuccessReducer(),
		fetchUserPreferencesFailure: createFailureReducer(),

		updateUserPreferencesPending: createPendingReducer(),
		updateUserPreferencesSuccess: createSuccessReducer(),
		updateUserPreferencesFailure: createFailureReducer(),

	}
});


const selectPreferencesState = state => state[STATE_KEY] || INITIAL_STATE;

export const selectPreferencesIsLoading = createSelector(
	selectPreferencesState,
	preferencesState => preferencesState.isLoading
);

export const selectPreferences = createSelector(
	selectPreferencesState,
	preferencesState => preferencesState?.data?.data
);

export const preferencesSelectors = {
	selectPreferencesIsLoading,
	selectPreferences,
};

export const preferencesActions = {
	fetchUserPreferences,
	updateUserPreferences,
	...preferencesSlice.actions
};

export default preferencesSlice.reducer;
