import { createSelector } from '@reduxjs/toolkit';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import entitySchemas from 'containers/App/modules/api/schema';


const selectLoginState = state => state.login;
export const selectLoginIsLoading = createSelector(
	selectLoginState,
	loginState => loginState.isLoading
);


export const selectLoggedInUser = createSelector(
	selectLoginState,
	loginState => loginState.loggedInUser
);
