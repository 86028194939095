import saga from './modules/forgotPassword.saga';

import ForgotPasswordPage from './components/ForgotPasswordPage';
import { forgotPasswordActions, forgotPasswordSelectors } from 'pages/ForgotPasswordPage/modules/forgotPassword.slice';

export default {
	component: ForgotPasswordPage,
	actions: forgotPasswordActions,
	selectors: forgotPasswordSelectors,
	saga
};