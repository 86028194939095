export const DEVICE_STATUS = {
	INACTIVE: 'Inactive',
	ACTIVE: 'Active',
	DISABLED: 'Disabled',
	DEACTIVATED: 'Deactivated'	
}

// here for translations
const statusFormatter = {
	0: DEVICE_STATUS.INACTIVE,
	10: DEVICE_STATUS.ACTIVE,
	20: DEVICE_STATUS.DISABLED,
	30: DEVICE_STATUS.DEACTIVATED
};

const DeviceStatus = ({status}) => {
	return statusFormatter[status];
};

export const deviceStatus = status => statusFormatter[status];

export default DeviceStatus;
