import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { DAEMON } from 'utils/constants';

import deviceEntitySaga from 'containers/App/modules/entities/devices/deviceEntity.saga';

import DevicesGrid from 'pages/Devices/components/DevicesGrid';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { deviceEntityActions, deviceEntitySelectors } from 'containers/App/modules/entities/devices/deviceEntity.slice';
import { devicesActions, deviceSelectors } from 'pages/Devices/modules/devices.slice';
import devicesReducer from 'pages/Devices/modules/devices.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import schema from 'containers/App/modules/api/schema';
import _ from 'lodash';


const withEntitySaga = injectSaga({
	key:  'deviceEntitySaga',
	saga: deviceEntitySaga,
	mode: DAEMON
});


const withReducer = injectReducer({
	key:     'devices',
	reducer: devicesReducer
});

const mapStateToProps = (state, props) => {
	const grid = deviceSelectors.selectDevicesGrid(state);
	const currentDeviceIds = _.get(state, 'devices.devicesGrid.currentIds');
	const devices = dbSelectors.selectEntitiesDenormalized([schema.device], currentDeviceIds)(state);

	return {
		isDevicesLoading: deviceEntitySelectors.selectDevicesPending(state),
		devices,
		grid
	}
};

const withConnect = connect(mapStateToProps, { ...deviceEntityActions, ...modalActions, ...devicesActions });

export default compose(
	withEntitySaga,
	withReducer,
	withConnect
)(DevicesGrid);
