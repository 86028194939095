import { createSelector } from 'reselect';
import { INITIAL_STATE } from './async.reducer';
import _ from 'lodash';

const getAsyncState = state => state.async || INITIAL_STATE;

export const createRequestStatusSelector = actionType =>
	createSelector(
		getAsyncState,
		asyncState => {
			return asyncState[actionType];
		}
	);

export const createIsLoadingSelector = actionType =>
    createSelector(
        createRequestStatusSelector(actionType),
        requestState => {
        	return _.get(requestState, 'isLoading')
        }
    );

export default {
	createRequestStatusSelector,
    createIsLoadingSelector
};


