import React from 'react';
import { Icon, Typography } from 'antd';
import FormFactory from 'components/FormFactory';
import Box from 'components/Box';

const {Title} = Typography;

const Form = FormFactory.create({ name: 'forgotPasswordForm' });
const emailIcon = <Icon type="mail" />;

const ForgotPasswordForm = (props) => {

	const onSubmit = (values) => props.forgotPassword(values);

	const { isLoading } = props;

	const fields = [
		{
			name:        'email',
			type:        'text',
			style:       { width: 220 },
			rules:       [
				{ required: true, message: 'Please input your email address' },
				{ type: 'email', message: 'Must be a valid email address' }
			],
			// initialValue: "nadav@biot-med.com",
			prefix:      emailIcon,
			placeholder: 'john@mail.com"'
		}
	];

	return (
		<Box direction="column" gutter={20}>
			<Title level={4} style={{color: 'white', textAlign: 'center'}}>Password Reset</Title>
		<Form
			onSubmit={onSubmit}
			fields={fields}
			submitButtonText="Send Email"
			isButtonLoading={isLoading}
		/>
		</Box>
	);
};

export default ForgotPasswordForm;