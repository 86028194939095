import { takeLatest, put, call, all, race, delay } from 'redux-saga/effects';
import { appActions } from 'containers/App/modules/app.slice';
import { deviceTypesActions } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice'
import { countriesActions } from 'containers/App/modules/entities/countries/countries.slice';
import { userGroupsActions } from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';
import { alarmsEntityActions } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';
import { consentActions } from '../../../pages/Consent/modules/consent.slice';
import { encryptionSha256 } from 'services/CheckSumService';


function* appStart(action) {
	const { userId } = action.payload;
	const actions = deviceTypesActions;
		yield all([
		put(userEntityActions.fetchLoggedInUser(userId)),
		put(countriesActions.fetchCountries()),
		put(countriesActions.fetchLocationsHierarchy()),
		put(deviceTypesActions.fetchDeviceTypes()),
		put(deviceTypesActions.fetchDeviceTypesHierarchy()),
		put(userGroupsActions.fetchUserGroups()),
		put(consentActions.fetchUserConsents()),
		put(consentActions.fetchAllUserConfirmedConsents()),
	]);
}

function* pullActiveAlarmsCount() {
	const PULLING_INTERVAL_MILLIS = 5000;
	while (true) {
		yield put(alarmsEntityActions.fetchActiveAlarmsCount());
		yield race([
			alarmsEntityActions.fetchActiveAlarmsCountSuccess,
			alarmsEntityActions.fetchActiveAlarmsCountFailure
		]);
		yield delay(PULLING_INTERVAL_MILLIS)
	  }
}

export function* calculateFileChecksum(fileToUpload) {
	const checkSum = yield call(encryptionSha256, fileToUpload);
	return checkSum;
}

export default function* watchAppSaga() {
	yield takeLatest(appActions.appStart, appStart);
	yield takeLatest(appActions.pullActiveAlarmsCount, pullActiveAlarmsCount);
};