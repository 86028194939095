import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { selectDeviceTypesTreeDataNew } from 'containers/App/modules/app.slice';
import TreeSelector from 'components/TreeSelector';

const DeviceTypeTreeSelector = React.forwardRef((props, ref) => {
	const { deviceTypesTreeData, ...treeSelectProps } = props;

	return (
		<TreeSelector
			treeData={deviceTypesTreeData}
			placeholder="Device Types"
			{...treeSelectProps}
		/>
	)
});

DeviceTypeTreeSelector.propTypes = {
	withAll: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const deviceTypesTreeData = selectDeviceTypesTreeDataNew(state);
	return { deviceTypesTreeData };
};

export default compose(
	connect(
		mapStateToProps,
		null,
		null,
		{ forwardRef: true }
	)
)(DeviceTypeTreeSelector);