import React from 'react';
import { Switch } from 'antd';
import FormField from 'components/FormFactory/FormField';


const SwitchField = (props) => {

	return (
		<FormField
			valuePropName="checked"
			{...props}>

			<Switch />
			
		</FormField>
	);
};

SwitchField.propTypes = {
	...FormField.propTypes
};

export default SwitchField;