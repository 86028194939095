import { connect } from "react-redux";
import { compose } from "redux";
import AssignDevicesModal from "pages/Groups/components/AssignDevices/AssignDevicesModal";
import injectReducer from "utils/injectReducer";
import groupReducer, {
	groupsActions,
	groupSelectors,
} from "../modules/groups.slice";
import { groupEntityActions } from "containers/App/modules/entities/groups/groupEntity.slice";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import _ from "lodash";
import { deviceSelectors } from "pages/Devices/modules/devices.slice";

const withReducer = injectReducer({
	key: "groups",
	reducer: groupReducer,
});

const mapStateToProps = (state, ownProps) => {
	const currentDeviceIds = _.get(state, "devices.devicesGrid.currentIds");
	const groupId = ownProps.row.id;
	const groupName = ownProps.row.groupName;
	const isAtLeastOneDeviceAttachedToComposedGroup = _.get(
		state,
		"devices.isAtLeastOneDeviceAttachedToComposedGroup"
	);
	const assignedDevices = groupSelectors.selectAssignedDevices(state) ||  deviceSelectors.selectDevicesAssignedToGroup(state, groupId);
	const devicesGrid = deviceSelectors.selectDevicesGrid(state);
	
	return {
		assignedDevices,
		currentDeviceIds,
		groupId,
		groupName,
		isAtLeastOneDeviceAttachedToComposedGroup,
		devicesGrid,
	};
};

const withConnect = connect(mapStateToProps, {
	...groupEntityActions,
	...modalActions,
	...groupsActions,
});

export default compose(withReducer, withConnect)(AssignDevicesModal);
