import { call, takeEvery } from 'redux-saga/effects';
import { toastsActions } from 'containers/App/modules/toasts/toasts.actions';
import { notification } from 'antd';

export function* showSuccessToast(action) {
	const { title, description } = action.payload;
	yield call(notification.success, { message: title, description });
}

export function* showErrorToast(action) {
	const { title, description } = action.payload;
	yield call(notification.error, { message: title, description });
}

export default function* watchLoginSaga() {
	yield takeEvery(toastsActions.showSuccessToast, showSuccessToast);
	yield takeEvery(toastsActions.showErrorToast, showErrorToast);
}