import { takeLatest, call } from 'redux-saga/effects';
import { parseFetchAllSuccess, userGroupsActions } from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import schemas from 'containers/App/modules/api/schema';
import { api } from 'containers/App/modules/async.saga';
import BackendService from 'services/BackendService';


function* fetchAllUserGroups(action) {
	yield call(api, {
		apiFn:   BackendService.fetchUserGroups,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.userGroup],
		actions: [
			userGroupsActions.fetchUserGroupsPending,
			userGroupsActions.fetchUserGroupsSuccess,
			userGroupsActions.fetchUserGroupsFailure,
		]
	});
}

export default function* watchUserGroupsSaga() {
	yield takeLatest(userGroupsActions.fetchUserGroups, fetchAllUserGroups)
}