import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';

import V2CHistoryGrid from 'pages/Devices/components/V2C/V2CHistoryGrid';
import { actions as v2cEntityActions } from 'containers/App/modules/entities/v2c/v2cEntity.slice';
import v2cHistoryReducer, { v2cHistorySelectors, v2cHistoryActions } from 'pages/Devices/modules/v2cHistory.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import schema from 'containers/App/modules/api/schema';
import _ from 'lodash';

const withReducer = injectReducer({
	key:     'v2c',
	reducer: v2cHistoryReducer
});

const mapStateToProps = (state, props) => {
    const currentV2CHistoryIds = _.get(state, 'v2c.v2cGrid.currentIds');
    const v2cHistoryData = dbSelectors.selectEntitiesDenormalized([schema.v2cHistory], currentV2CHistoryIds)(state);
	const grid = v2cHistorySelectors.selectV2CHistoryGrid(state);
	
	return {
		v2cHistoryData,
		isLoading: state.entities.v2c.fetchV2CHistoryStatus === "RUNNING",
		grid
	}
};

const withConnect = connect(mapStateToProps, { ...v2cEntityActions, ...v2cHistoryActions });

export default compose(
	withReducer,
	withConnect
)(V2CHistoryGrid);
