/**
 *  an object to transltate messages at non-react code, like redux actions.
 *
 *  inspired by this issue on github:
 *  https://github.com/formatjs/react-intl/issues/416
 *
 *  react-intl@^3 implements "createIntl" which helps creating intl object for non-react code, which is what we want.
 *  but "react-boilerplate" (part of the base seed for this project) relies heavily on react-intl@2.8.0
 *  tried upgrading the version in package.json - app just doesn't run (i18n.js problems obviously).
 *  this is the open issue to upgrade react-intl for react-boilerplate, it seems it isn't easy:
 *  https://github.com/react-boilerplate/react-boilerplate/pull/2721
 */

import { injectIntl } from 'react-intl';

/**
 * Should only use this when not inside a React component (such as redux actions), see:
 * https://github.com/yahoo/react-intl/issues/416
 */
export let intl = null;

const IntlGlobalProvider = (props) => {
	intl = props.intl;
	return props.children;
};

export default injectIntl(IntlGlobalProvider);
