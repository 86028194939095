import { connect } from 'react-redux';
import { compose } from 'redux';

import GroupForm from 'pages/Groups/components/GroupForm';
import { groupEntitySelectors } from 'containers/App/modules/entities/groups/groupEntity.slice'

import injectReducer from 'utils/injectReducer';
import groupReducer, { groupsActions, groupSelectors } from '../modules/groups.slice';
import { groupEntityActions } from 'containers/App/modules/entities/groups/groupEntity.slice';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { injectIntl } from "react-intl";
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import entitySchemas from 'containers/App/modules/api/schema';
import { selectCountriesTreeData } from 'containers/App/modules/countries/countries.selectors';


const withReducer = injectReducer({
	key:     'groups',
	reducer: groupReducer
});

const mapStateToProps = (state, ownProps) => {
	const editedGroupId = ownProps.groupId; //groupSelectors.selectEditedGroupId(state);
	// const group = dbSelectors.selectEntitiesDenormalized(entitySchemas.group,  editedGroupId)(state);

	return {
		isGroupFetchLoading:     groupEntitySelectors.selectFetchGroupPending(state),
		isGroupOperationLoading: groupEntitySelectors.selectCreateGroupPending(state),
	}
};

const withConnect = connect(mapStateToProps, { ...groupEntityActions, ...modalActions, ...groupsActions });

export default compose(
	withReducer,
	withConnect,
	injectIntl
)(GroupForm);