export const GENERATE_LOGS_COMMAND_NAME = "GENERATE_LOGS";

export const GENERATE_LOGS_PARAMS = {
  RETENTION_DAYS: "retentionDays",
  LOG_STATES: "logStates",
  DAYS_AGO: "daysAgo",
};

export const COMMAND_STATES = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  ABORTED: "ABORTED",
};
