import { createSelector } from "@reduxjs/toolkit";
import { NOOP } from "containers/App/app.constants";
import { ENTITY_TYPES } from "containers/App/modules/entities/entities.constants";
import { createNewEntitySlice } from "../entities.utils";

export const STATE_KEY = ENTITY_TYPES.DEVICE_FILES;

const extraAsyncActions = [
  {
    type: "downloadDeviceFiles",
    setStateDataFn: NOOP,
  },
];

const deviceFilesEntitySlice = createNewEntitySlice({
  name: ENTITY_TYPES.DEVICE_FILES,
  extraAsyncActions,
});

const { reducer, actions } = deviceFilesEntitySlice;
const {
  downloadDeviceFiles,
  downloadDeviceFilesPending,
  downloadDeviceFilesSuccess,
  downloadDeviceFilesFailure,
} = actions;

export const deviceFilesEntityActions = {
  downloadDeviceFiles,
  downloadDeviceFilesPending,
  downloadDeviceFilesSuccess,
  downloadDeviceFilesFailure,
};

const getDeviceFilesState = (state) => state.entities.deviceFiles;
const selectDownloadDeviceFilesLoading = createSelector(
  getDeviceFilesState,
  (downloadDeviceFilesState) =>
    downloadDeviceFilesState.downloadDeviceFilesLoading
);

export const deviceFilesEntitySelectors = {
  selectDownloadDeviceFilesLoading,
};

export default reducer;
