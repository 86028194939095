import _ from "lodash";
import { SORT_TYPES_MAP } from "components/DataGrid/dataGrid.constants";
import { parseFetchAllRequest as parseAllRequest } from "containers/App/modules/entities/users/users.parsers";

export const parseFetchAllRequest = (payload) => parseAllRequest(payload);

const prepareDevice = (device) => {
  const { deviceInfo, customerDetails, deviceFotaInfo, lastEvent } = device;
  return {
    ...deviceInfo,
    ...deviceFotaInfo,
    customerDetails,
    lastEvent,
    id: device.deviceInfo.manufacturerId,
  };
};

export const parseFetchAllSuccess = (responseData) => {
  const data = _.map(_.get(responseData, "data", []), prepareDevice);
  return data;
};

export const parseFetchDevicePropertiesSuccess = (responseData) =>
  prepareDevice(responseData);
export const parseCreateRequest = (payload) => {
  const {
    deviceSerialNumber,
    deviceType,
    city,
    country,
    state,
    street,
    streetNumber,
    zipCode,
    comments,
    email,
    firstName,
    lastName,
    phoneNumber,
    clinicId,
    clinicName,
    classification,
  } = payload;
  const countryName = _.get(country, "[1]");

  const deviceApiObject = {
    createLumDeviceRequest: {
      deviceSerialNumber,
      deviceType: deviceType.type,
      classification,
    },

    submitCustomerDetailsRequest: {
      address: {
        city,
        country: countryName,
        state,
        street,
        streetNumber,
        zipCode,
      },
      comments,
      email,
      firstName,
      lastName,
      phoneNumber,
      clinicId,
      clinicName,
    },
  };
  return deviceApiObject;
};

export const parseCreateSuccess = (payload) => {
  const { data, requestParams } = payload;
  const { createLumDeviceRequest, submitCustomerDetailsRequest } =
    requestParams;
  const { manufacturerId: id } = data;
  return {
    id,
    ...createLumDeviceRequest,
    ...submitCustomerDetailsRequest,
  };
};

export const parseFetchAssignedUsersSuccess = (responseData) => {
  return responseData.assignedUsers;
};

export const parseDeviceCommandsSuccess = (responseData) => {
  return responseData.commands;
};

export const parseSearchDeviceLogFiles = (payload) => {
  if (!payload) return payload;
  const { pagination, sorter, filters, search, additionalData } = payload;

  const nonEmptyFilters = _.omitBy(filters, _.isNil);

  const filter = {};
  Object.entries(nonEmptyFilters).forEach(([key, value]) => {
    filter[key] = { like: value };
  });

  //filter["metadata.fileType"] = { like: "logs" }; when multiple file types exist, return this line

  const sort = !_.isEmpty(sorter)
    ? sorter.column && sorter.column.keys
      ? _.map(sorter.column.keys, (key) => ({
          prop: key,
          order: SORT_TYPES_MAP[sorter.order],
        }))
      : [
          {
            prop: sorter.columnKey || sorter.field,
            order: SORT_TYPES_MAP[sorter.order],
          },
        ]
    : undefined;

  const jsonString = JSON.stringify({
    limit: 1000,
    page: 0,
    freeTextSearch: search,
    filter,
    sort,
    ...additionalData,
  });

  return { searchRequest: jsonString };
};

export const prepareUpdateAssignedUsersRequest = (payload) => {
  const { deviceType, deviceSerialNumber, explicitAssignedUserIds } = payload;
  return {
    payload: {
      deviceType,
      deviceSerialNumber,
      data: {
        userIds: explicitAssignedUserIds,
      },
    },
  };
};

export const prepareUpdateDevicePropertiesRequest = (payload) => {
  const {
    city,
    country,
    state,
    street,
    streetNumber,
    zipCode,
    classification,
    ...restOfCustomerDetails
  } = payload.deviceValues;

  const region = _.get(country, "[0]");
  const countryName = _.get(country, "[1]");
  return {
    payload: {
      ...payload,
      data: {
        customerDetails: {
          address: {
            city,
            country: countryName,
            region,
            state,
            street,
            streetNumber,
            zipCode,
          },
          ...restOfCustomerDetails,
        },
        deviceDetails: {
          classification,
        },
      },
    },
  };
};

export const prepareUpdateDeviceLogsModeRequest = (payload) => {
  const { deviceType, deviceSerialNumber, data } = payload;
  return {
    payload: {
      deviceType,
      deviceSerialNumber,
      data: {
        data: data,
      },
    },
  };
};

export const prepareDeviceCommandsRequest = (payload) => {
  const { deviceType, deviceSerialNumber } = payload;
  return {
    payload: {
      deviceType,
      deviceSerialNumber,
    },
  };
};
