import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { deviceTypesSelectors } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';


function getDeviceTypesCascadingData(deviceTypesList) {
	return getDeviceTypesTreeDataWithParams(deviceTypesList);
}

const  getDeviceTypesTreeData = (deviceTypesList) => getDeviceTypesTreeDataWithParams(deviceTypesList, 'title', true);

function getDeviceTypesTreeDataWithParams(deviceTypesList, labelProp = 'label', fullName = false) {
	let treeData = [];

	_.forEach(deviceTypesList, (deviceType => {
			const { productFamily1, materialFamily, type } = deviceType;
			if (productFamily1) { //protect bug - productFamily1 is empty - test data that's returned to UI
				let prodFamObj = _.find(treeData, (pFam => pFam[labelProp] === productFamily1));
				if (!prodFamObj) {
					prodFamObj = { value: productFamily1, [labelProp]: productFamily1, children: [] };
					treeData.push(prodFamObj);
				}

				let matFamObj = _.find(prodFamObj.children, (matFam => matFam[labelProp] === materialFamily));
				if (!matFamObj) {
					const matValue = fullName ? `${prodFamObj.value}/${materialFamily}` : materialFamily;
					matFamObj = { value: matValue, [labelProp]: materialFamily, children: [] };
					prodFamObj.children.push(matFamObj);
				}

				const typeValue = fullName ? `${matFamObj.value}/${type}` : type;
				matFamObj.children.push({ value: typeValue, [labelProp]: type, object: deviceType });
			}
		})
	);

	const sortedTreeData = _.sortBy(treeData, labelProp);

	return sortedTreeData;
}


const selectAllDeviceTypes = dbSelectors.selectAllEntities(ENTITY_TYPES.DEVICE_TYPES);

export const selectDeviceTypesCascadingData = createSelector(
	selectAllDeviceTypes,
	deviceTypes => getDeviceTypesCascadingData(deviceTypes)
);

export const selectDeviceTypesTreeData = createSelector(
	selectAllDeviceTypes,
	deviceTypes => getDeviceTypesTreeData(deviceTypes)
);