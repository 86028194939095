import React from 'react';
import { Checkbox } from 'antd';
import FormField from 'components/FormFactory/FormField';


const CheckboxField = (props) => {
	const { label, ...rest } = props;

	return (
		<FormField
			valuePropName="checked"
			{...rest}>

			<Checkbox>{label}</Checkbox>

		</FormField>
	);
};

CheckboxField.propTypes = {
	...FormField.propTypes
};

export default CheckboxField;