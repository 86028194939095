import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import _ from 'lodash';

export const parseFetchAllSuccess = responseData =>
	_.map(
		responseData.userGroupResponseSet,
		userGroup => ({ id: userGroup.userGroupId, name: userGroup.userGroupName })
	);

const userGroupEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.USER_GROUPS,
	// parseFetchAllSuccess
});

const { reducer, actions, selectors } = userGroupEntitySlice;

const {
	      fetchAll:        fetchUserGroups,
	      fetchAllPending: fetchUserGroupsPending,
	      fetchAllSuccess: fetchUserGroupsSuccess,
	      fetchAllFailure: fetchUserGroupsFailure
      } = actions;


export const userGroupsActions = {
	fetchUserGroups,
	fetchUserGroupsPending,
	fetchUserGroupsSuccess,
	fetchUserGroupsFailure
};

const {
	      selectAll: selectUserGroups,
	      selectFetchAllPending: selectUserGroupsPending
      } = selectors;

export const userGroupSelectors = {
	selectUserGroups,
	selectUserGroupsPending
};

export default reducer;