import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants'

import resetPasswordSaga from '../modules/resetPassword.saga';

import ForgotPasswordForm from '../components/ResetPasswordForm';
import injectReducer from 'utils/injectReducer';
import resetPasswordReducer, { resetPasswordActions, resetPasswordSelectors, STATE_KEY } from 'pages/ResetPasswordPage/modules/resetPassword.slice';



const mapStateToProps = (state, props) => {
	const { search } = state.router.location;
	const urlParams = new URLSearchParams(search);
	return {
		isLoading: resetPasswordSelectors.selectResetPasswordPending(state),
		userid:    urlParams.get('userId'),
		token:     urlParams.get('token')
	}
};

const withConnect = connect(mapStateToProps, resetPasswordActions);

const withSaga = injectSaga({
	key:  'resetPasswordSaga',
	saga: resetPasswordSaga,
	mode: DAEMON,
});

const withReducer = injectReducer({
	key: STATE_KEY,
	reducer: resetPasswordReducer
});


export default compose(
	withSaga,
	withReducer,
	withConnect
)(ForgotPasswordForm);

