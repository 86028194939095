import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';


const hAlignCSS = css`
	align-items: center;
`;

const vAlignCSS = css`
	justify-content: center;
`;

const centeredCSS = css`
   ${hAlignCSS}
   ${vAlignCSS}
`;

const rowDirectionCSS = css`
	flex-direction: row;
	& > *:not(:last-child) {
      margin-right: ${props => props.gutter}px;
    }
`;

const columnDirectionCSS = css`
	flex-direction: column;
	& > *:not(:last-child) {
      margin-bottom: ${props => props.gutter}px;
    }
`;

const BoxElement = styled.section`
    display: flex;
	flex-direction: ${props => props.direction};    
	
	${props => props.direction === 'row' ? rowDirectionCSS : columnDirectionCSS};
	
	${props => props.hAligned && hAlignCSS};	
	${props => props.vAligned && vAlignCSS};	
	${props => props.centered && centeredCSS}
`;

const Box = (props) => {
	return <BoxElement className={props.className} {...props}>{props.children}</BoxElement>
};

Box.propTypes = {
	direction: PropTypes.oneOf(['row', 'column']),
	centered:  PropTypes.bool,
	hAligned:  PropTypes.bool,
	vAligned:  PropTypes.bool,
	gutter:    PropTypes.number,
};

Box.defaultProps = {
	direction: 'row',
	centered:  false,
	gutter:    8
};

export default Box;