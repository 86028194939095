import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Cascader } from "antd";
import { injectIntl } from "react-intl";
import messages from "./actionMessages";
import _ from "lodash";
import styled from "styled-components";

const StyledCascader = styled(Cascader)`
  .ant-cascader-picker-label {
    font-weight: 600;
  }
`;

const ActionSelector = React.forwardRef((props, ref) => {
  const { onChange, dispatch, ...rest } = props;
  const { formatMessage } = props.intl;

  const filter = (inputValue, path) => {
    return path.some((option) =>
      _.includes(option.label.toLowerCase(), inputValue.toLowerCase())
    );
  };

  const options = [
    {
      value: logsMode.information,
      label: formatMessage(messages.information),
    },
    {
      value: logsMode.debug,
      label: formatMessage(messages.debug),
    },
  ];

  return (
    <StyledCascader
      ref={ref}
      options={options}
      onChange={onChange}
      showSearch={{ filter }}
      placeholder={formatMessage(messages.selectLogMode)}
      {...rest}
    />
  );
});

export const logsMode = {
  information: "INFORMATION",
  debug: "DEBUG",
};

export const actions = {
  changeLogMode: "changeLogMode",
};

export default compose(
  connect(null, null, null, { forwardRef: true }),
  injectIntl
)(ActionSelector);
