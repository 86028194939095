import React, { useState, useEffect } from "react";
import styled from "styled-components";


const PaddedDiv = styled.div`
  padding-bottom: 1.5em;
`;

const ViewLicenseModal = (props) => {
    const {
        decryptedLicense,
    } = props;

    return (
        <PaddedDiv>
            {decryptedLicense}
        </PaddedDiv>
    );
};

export default ViewLicenseModal;