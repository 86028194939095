import { call, takeLatest, put } from 'redux-saga/effects';
import BackendService from 'services/BackendService';
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { deviceAlarmsEntityActions } from 'containers/App/modules/entities/deviceAlarms/deviceAlarmsEntity.slice';

import { parseFetchAllSuccess } from "containers/App/modules/entities/deviceAlarms/deviceAlarms.parsers";

function* fetchDeviceAlarms(action) {
	const params = {
		deviceSerialNumber: action.payload.deviceSerialNumber,
		deviceType: action.payload.deviceType,
		params: {
			...action.payload
		}
	}
	
	delete params.params.deviceSerialNumber;
	delete params.params.deviceType;

  yield call(api, {
		apiFn: BackendService.fetchDeviceAlarms,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema:                 [schemas.deviceAlarms],
		params,
		actions:                [
			deviceAlarmsEntityActions.fetchDeviceAlarmsPending,
			deviceAlarmsEntityActions.fetchDeviceAlarmsSuccess,
			deviceAlarmsEntityActions.fetchDeviceAlarmsFailure
		]
	});
}

export default function* watchUsersSaga() {
  yield takeLatest(deviceAlarmsEntityActions.fetchDeviceAlarms, fetchDeviceAlarms);
}