import React from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const AlredyAssignedWarningWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: -7.3em;
`;

const AlreadyAssignedWarning = (props) => (
    <AlredyAssignedWarningWrapper>
    <Tooltip title={`This device is already assigned to the group: ${props.groupName}`}>
        <WarningTwoTone twoToneColor="#cc3300"/>
    </Tooltip>
    </AlredyAssignedWarningWrapper>
);

export default AlreadyAssignedWarning;