import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import logo from 'images/logo.webp';

import AppVersion from 'components/AppVersion';
import Box from 'components/Box';

const Version = styled(AppVersion)`
	font-size: 10px;
	color: white;
`;

const normalCSS = css`
	img {
    	width: 100%;
    	height: 100%;
    }
`;

const smallCSS = css`
	width: 36px;
	
	img {
    	height: 100%;
    }
`;

const ImgContainer = styled(Box).attrs({
	centered: true
})`
	 
	position: relative;
    text-align: center;
`;

const Mask = styled.div`
	overflow: hidden;
	height: 100%;
	
	${props => props.small ? smallCSS : normalCSS};
`;

const Logo = (props) => {
	const {
		      className,
		      small = false
	      } = props;

	return (
		<ImgContainer className={className} small={small}>

			<Mask small={small}>
				<img src={logo} alt="LumenisX" />
			</Mask>

			<Version small={small} />

		</ImgContainer>
	);
};

Logo.propTypes = {
	className: PropTypes.string,
	small:     PropTypes.bool
};

export default Logo;