
export const parseFetchAllSuccess = responseData => {
	return responseData.consents;
};

export const createConfirmConsentRequest = consentDetails => {
	return {
		localeName: consentDetails?.localeName,
		revisionId: consentDetails?.revisionId
	}
};
