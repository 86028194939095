import React from "react";
import moment from "moment";
import EntityGrid, { loadData as entityGridLoadData } from "containers/Grid/components/EntityGrid";
import V2CGridToolbar from "./V2CGridToolbar";

const columns = [
	{
		key: "name",
		title: "File Name",
		dataIndex: "name",
	},
	{
		key: "updatedTime",
		title: "Upload Date",
		dataIndex: "updatedTime",
		render: (updatedTime) => updatedTime ? moment(updatedTime).format("DD-MMM-YYYY HH:mm") : '-',
	},
	{
		key: "description",
		title: "Comments",
		dataIndex: "description",
	},
];

const V2CHistoryGrid = (props) => {
	const { fetchV2CHistory, v2cHistoryData, isLoading, setGridParams, resetGridData, grid, deviceId } = props;

	const v2cHistoryGrid = {
		...grid,
		params: {
			...grid.params,
			additionalData: {
				clientIds: [deviceId],
				updatedOnly: true
			}
		}
	}

	const refreshGrid = () => {
		entityGridLoadData(v2cHistoryGrid, fetchV2CHistory);
	}

	const toolbarComponent = (
		<V2CGridToolbar
			resetGridData={resetGridData}
			setGridParams={setGridParams}
			grid={grid}
			refreshGrid={refreshGrid}
			showAddNew={false}
		/>
	);

	return (
		<EntityGrid
			toolbarComponent={toolbarComponent}
			grid={v2cHistoryGrid}
			fetchData={fetchV2CHistory}
			fetchDataPending={isLoading}
			data={v2cHistoryData}
			columns={columns}
			setGridParams={setGridParams}
			resetGridData={resetGridData}
		/>
	);
};
export default V2CHistoryGrid;
