import { createSlice } from '@reduxjs/toolkit';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { DEFAULT_LOADING_PROP } from 'containers/App/modules/redux.contants';
import {
	createResetGridReducer,
	createSetGridParamsReducer,
	createUpdateGridReducer,
	GRID_INITIAL_STATE,
	createSetGridParamsAction,
	createResetGridDataAction,
} from 'containers/Grid/grid.utils';


const USERS_STATE_KEY = 'users';
const USERS_GRID_STATE_KEY = 'usersGrid';
const setGridParams = createSetGridParamsAction(USERS_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(USERS_GRID_STATE_KEY);

export const INITIAL_STATE = {
	editedUserId:           undefined,
	editedUser:             undefined,
	potentialManagers:      undefined,
	[USERS_GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const usersSlice = createSlice({
	name:          USERS_STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		clearEditedUser:
			(state, action) => {
				delete state.editedUserId;
				delete state.editedUser;
				delete state.potentialManagers;
			},
	},
	extraReducers: {
		[userEntityActions.fetchPotentialManagersSuccess]: (state, action) => {
			state.potentialManagers = _.get(action, 'payload.data.result');
		},
		[userEntityActions.fetchUserSuccess]:              (state, action) => {
			const { data: { result }, metadata: {editable} } = action.payload;
			state.editedUserId = result;
		},

		//GRID
		[userEntityActions.fetchUsersSuccess]: createUpdateGridReducer(USERS_GRID_STATE_KEY),
		[setGridParams]:                       createSetGridParamsReducer(USERS_GRID_STATE_KEY),
		[resetGridData]:                       createResetGridReducer(USERS_GRID_STATE_KEY),
	}
});


const getUsersState = state => state[USERS_STATE_KEY] || INITIAL_STATE;

const selectEditedUserId = createSelector(
	getUsersState,
	usersState => _.get(usersState, 'editedUserId')
);

const selectEditedUserPotentialManagersIds = createSelector(
	getUsersState,
	usersState => _.get(usersState, 'potentialManagers')
);

//todo fix
const selectPotentialUsersPending = createSelector(
	getUsersState,
	usersState => _.get(usersState, DEFAULT_LOADING_PROP)
);

const selectUsersGrid = createSelector(
	getUsersState,
	usersState => {
		return usersState[USERS_GRID_STATE_KEY]
	}
);

const selectUserIdsForPage = (state, props) => {
	const gridState = selectUsersGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

export const userSelectors = {
	selectEditedUserId,
	selectEditedUserPotentialManagersIds,
	selectPotentialUsersPending,
	selectUsersGrid,
	selectUserIdsForPage
};

const {
	      clearEditedUser,
      } = usersSlice.actions;
export const usersActions = {
	clearEditedUser,
	setGridParams,
	resetGridData
};
export default usersSlice.reducer;