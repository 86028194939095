import endPoints from "../../src/constants/endPoints";

const ENDPOINTS = {
  SELF_REGISTRATION: {
    uri: 'users/selfRegistration',
    method: 'POST',
    requireAuth: false,
    headers: {}
  },
  LOGIN: {
    uri: 'users/loginCredentials',
    method: 'POST',
    requireAuth: false
  },
  LOGIN_WITH_TOKEN: {
    uri: 'users/current/login',
    method: 'POST',
    requireAuth: false,
    headers: {}
  },
  LOGOUT: {
    uri: 'users/current/logout',
    method: 'POST',
    requireAuth: false,
    headers: {}
  },
  REFRESH_TOKEN: {
    uri: 'users/current/refreshToken',
    method: 'POST',
    requireAuth: false,
    headers: {}
  },
  PERMISSIONS: {
    uri: 'permissions/ui',
    method: 'GET',
    requireAuth: true,
    headers: {},
  },
  RESET_PASSWORD: {
    uri: 'users/changePassword',
    method: 'PUT',
    requireAuth: false,
    headers: {},
  },
  CHANGE_USER_PASSWORD: {
    uri: 'users/current/changePassword',
    method: 'PUT',
    requireAuth: true,
  },
  FORGOT_PASSWORD: {
    uri: 'users/forgotPassword?tenantId=',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  LOGIN_BY_PHONE_NUMBER: {
    uri: 'users/loginByPhoneNumber?tenantId=',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  SELF_REGISTRATION_BY_PHONE_NUMBER: {
    uri: 'users/selfRegistrationByPhoneNumber',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
  GENERATE_LOGIN_CODE: {
    uri: 'users/generateLoginCode',
    method: 'POST',
    requireAuth: false,
    headers: {},
  },
}

// Here we place endpoint creators:
const ENDPOINT_CREATORS = {
  VERIFY_EMAIL: userId => ({
    uri: `users/${userId}/verifyEmail`,
    method: 'POST',
    requireAuth: false,
    headers: {},
  }),
}

export { ENDPOINTS, ENDPOINT_CREATORS };
