import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import {parseFetchAllRequest} from './swVersions.parsers';

const swVersionEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.SW_VERSIONS,
	parseFetchAllRequest
});

const { reducer, actions, selectors } = swVersionEntitySlice;
const {
	      fetchAll:        fetchSwVersions,
	      fetchAllPending: fetchSwVersionsPending,
	      fetchAllSuccess: fetchSwVersionsSuccess,
	      fetchAllFailure: fetchSwVersionsFailure,

	      fetchById: fetchSwVersion,
	      fetchByIdPending: fetchSwVersionPending,
	      fetchByIdSuccess: fetchSwVersionSuccess,
	      fetchByIdFailure: fetchSwVersionFailure,

	      create: createSwVersion,
	      createPending: createSwVersionPending,
	      createSuccess: createSwVersionSuccess,
	      createFailure: createSwVersionFailure,

	      update: updateSwVersion,
	      updatePending: updateSwVersionPending,
	      updateSuccess: updateSwVersionSuccess,
	      updateFailure: updateSwVersionFailure,
      } = actions;

export const swVersionEntityActions = {
	fetchSwVersions,
	fetchSwVersionsPending,
	fetchSwVersionsSuccess,
	fetchSwVersionsFailure,

	fetchSwVersion,
	fetchSwVersionPending,
	fetchSwVersionSuccess,
	fetchSwVersionFailure,

	createSwVersion,
	createSwVersionPending,
	createSwVersionSuccess,
	createSwVersionFailure,

	updateSwVersion,
	updateSwVersionPending,
	updateSwVersionSuccess,
	updateSwVersionFailure,
};


const {
	      selectAll:              selectSwVersions,
	      selectFetchAllPending:  selectSwVersionsPending,
	      createSelectByIdOrIds:  createSelectSwVersionsByIdOrIds,
	      selectCreatePending:    selectCreateSwVersionPending,
	      selectFetchByIdPending: selectFetchSwVersionPending,
      } = selectors;


export const swVersionEntitySelectors = {
	selectSwVersions,
	selectSwVersionsPending,
	createSelectSwVersionsByIdOrIds,
	selectCreateSwVersionPending,
	selectFetchSwVersionPending,
};

export default reducer;