import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import messages from "./messages";
import {
  GENERATE_LOGS_COMMAND_NAME,
  COMMAND_STATES,
} from "../general/CommandsConstants";
import DeviceLogsTab from "pages/Devices/components/DeviceServices/DeviceLogsTab";

const { TabPane } = Tabs;

const DeviceServicesTab = (props) => {
  const {
    device,
    commands,
    commandsHistory,
    intl,
    startCommand,
    updateCommand,
    fetchCommandsLoading,
    startCommandLoading,
    updateCommandsLoading,
    canManageLogs,
    generateLogs,
    searchCommands,
    clearCommandState,
    loggedInUserId,
    fetchDeviceFiles,
    availableFiles,
  } = props;

  const deviceTypeFilter = {
    prop: "deviceType",
    values: device.deviceType.type,
  };
  const deviceSerialNumberFilter = {
    prop: "deviceSerialNumber",
    values: device.deviceSerialNumber,
  };

  const commandTypeFilter = (commandType) => ({
    prop: "commandType",
    values: commandType,
  });

  const startCommandCallback = (commandType, params) => {
    const deviceType = device.deviceType.type;
    const deviceSerialNumber = device.deviceSerialNumber;

    startCommand({
      deviceType,
      deviceSerialNumber,
      commandType,
      params,
      customMessage: commandType,
    });
  };

  const generateLogsCallback = (params) => {
    const deviceType = device.deviceType.type;
    const deviceSerialNumber = device.deviceSerialNumber;

    generateLogs({
      deviceType,
      deviceSerialNumber,
      params,
      customMessage: "",
    });
  };

  const updateCommandCallback = (commandType, params, commandStatus) => {
    const deviceType = device.deviceType.type;
    const deviceSerialNumber = device.deviceSerialNumber;
    updateCommand({
      deviceType,
      deviceSerialNumber,
      commandType,
      params,
      status: commandStatus,
    });
  };

  return (
    <Tabs defaultActiveKey="logs" destroyInactiveTabPane>
      {canManageLogs && (
        <TabPane tab={intl.formatMessage(messages.logs)} key="logs">
          <DeviceLogsTab
            availableFiles={availableFiles}
            device={device}
            intl={intl}
            changeLogMode={props.changeLogMode}
            downloadDeviceFiles={props.downloadDeviceFiles}
            isDownloadDeviceFilesLoading={props.isDownloadDeviceFilesLoading}
            onMount={() => {
              fetchDeviceFiles({
                filters: {
                  deviceId: device.id,
                },
              });
              searchCommands({
                limit: 5,
                filter: [
                  deviceSerialNumberFilter,
                  deviceTypeFilter,
                  commandTypeFilter(GENERATE_LOGS_COMMAND_NAME),
                ],
              });
            }}
            command={commands[GENERATE_LOGS_COMMAND_NAME]}
            history={commandsHistory[GENERATE_LOGS_COMMAND_NAME]}
            generateLogs={(params) => {
              generateLogsCallback({
                userId: loggedInUserId,
                ...params,
              });
            }}
            onUnmount={() => clearCommandState(GENERATE_LOGS_COMMAND_NAME)}
          ></DeviceLogsTab>
        </TabPane>
      )}
    </Tabs>
  );
};
DeviceServicesTab.propTypes = {
  device: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
export default DeviceServicesTab;
