import styled from 'styled-components';
import Box from 'components/Box';
import { Icon, Layout } from 'antd';


const { Header, Content } = Layout;

export const SpinBox = styled(Box)`
	height: 100vh;
`;

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

export const StyledHeader = styled(Header).attrs({
	size: 'small'
})`
	border-bottom: solid 1px #ccc;
	background: #fff;
	 padding: 0;
`;

export const MainContent = styled(Content)`
  margin: 0; //24px 16px;
  padding: ${props => props.padding};
  background: #fff;
  min-height: 280px;
`;

export const HeaderComponentsBox = styled(Box).attrs({
	hAligned: true,
	gutter:   12
})`
	padding-right: 20px;
`;

export const Spacer = styled.div`
	flex-grow: 2;
`;

export const CollapseIcon = styled(Icon)`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
`;