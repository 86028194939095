import saga from './modules/login.saga';
import selectors from 'containers/App/modules/async.selectors';

import LoginPage from './components/LoginPage';
import { loginActions } from 'pages/LoginPage/modules/login.slice';

export default {
    component: LoginPage,
    actions: loginActions,
    selectors,
    saga
};