import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { selectLocationsTreeDataNew } from 'containers/App/modules/app.slice';
import TreeSelector from 'components/TreeSelector';

const LocationTreeSelector = React.forwardRef((props, ref) => {
	const { locationsTreeData, ...treeSelectProps } = props;

	return (
		<TreeSelector
			treeData={locationsTreeData}
			placeholder="Locations"
			// multiSelect={false}
			// nonSelectableTreeLevels={[0,1]}
			// nonSelectableMessage="You can select only states"
			{...treeSelectProps}
		/>
	)
});

LocationTreeSelector.propTypes = {
	withAll: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const locationsTreeData = selectLocationsTreeDataNew(state);
	return { locationsTreeData };
};

export default compose(
	connect(
		mapStateToProps,
		null,
		null,
		{ forwardRef: true }
	)
)(LocationTreeSelector);