import { UserManagementActionTypes } from './actions';
// User data constants:
export const ACCESS_TOKEN = 'accessToken'
export const EMAIL_VERIFIED = 'emailVerified'
export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const GROUP_NAMES = 'groupNames'
export const REFRESH_TOKEN = 'refreshToken'
export const TENANT_ID = 'tenantId'
export const USER_ID = 'userId'
export const EMAIL_ADDRESS = 'email'
export const PHONE_NUMBER = 'phone'
export const PHONE_VERIFIED = 'phoneVerified'
export const USER_PERMISSIONS = 'userPermissions'
export const ACCESS_JWT = 'accessJwt'
export const REFRESH_JWT = 'refreshJwt'

/**
|--------------------------------------------------
| Here we define our initial state - 
|   Also help us understand what the state has.
|--------------------------------------------------
*/

export const emptyLoggedInUserState = {
  [ACCESS_TOKEN]: "",
  [EMAIL_VERIFIED]: false,
  [FIRST_NAME]: "",
  [LAST_NAME]: "",
  [REFRESH_TOKEN]: "",
  [TENANT_ID]: "",
  [USER_ID]: "",
  [GROUP_NAMES]: [],
  [EMAIL_ADDRESS]: "",
  [PHONE_NUMBER]: "",
  [PHONE_VERIFIED]: false,
  [USER_PERMISSIONS]: [],
  [ACCESS_JWT]: {},
  [REFRESH_JWT]: {},
}

const initialState = {
 ...emptyLoggedInUserState,
}

/**
|--------------------------------------------------
| Here we define our action handler - 
|  If you add new action, you should add an handler
|  that will listen to when that action dispatched. 
|--------------------------------------------------
*/
const ACTION_HANDLERS = {
  [UserManagementActionTypes.USER_DATA_CHANGED]: (state, action) => {
    const oldUserData = { ...state }
    const newState =
    {
      ...oldUserData,
      ...action.value
    }
    return newState
  },
  [UserManagementActionTypes.RESET_USER_DATA]: (state) => ({
    ...state,
    ...emptyLoggedInUserState,
  }),
}

/**
|--------------------------------------------------
|  Our exported reducer
|--------------------------------------------------
*/
export default function userReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[ action.type ]
  return handler ? handler(state, action) : state
}
