import React from "react";
import styled from "styled-components";
import FormFactory from "components/FormFactory";
import { Col, Row, Select } from "antd";
import { withErrorBoundary } from "components/ErrorBoundary";
import SelectField from "components/FormFactory/fields/SelectField";
import TextField from "components/FormFactory/fields/TextField";
import _ from "lodash";
import CheckboxField from "components/FormFactory/fields/CheckboxField";
import messages from "../messages";
import { intl } from "containers/IntlGlobalProvider";

// const { Option } = Select;
const DisabledTextField = styled(TextField)`
  color: #000000 !important;
`;

const Form = FormFactory.create({
  name: "updateGroupVersionForm",
});

const NoMarginContainer = styled.div`
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

//TODO: change to functional component
class UpdateGroupVersions extends React.Component {
  componentDidMount() {
    const { groupId } = this.props;
    this.props.fetchVersions({ groupId });
  }

  publishUpdate = (data) => {
    this.props.publishVersionToGroup({
      data,
      groupId: this.props.groupId,
    });
  };

  onCancel = (form) => {
    this.props.hideModal();
  };

  render() {
    const { isCreateUpdateLoading, potentialVersions, group } = this.props;
    const swVersions =
      !!potentialVersions &&
      _.uniqBy(potentialVersions.swVersions, (version) => version.name);
    const lumXVersions =
      !!potentialVersions &&
      _.uniqBy(potentialVersions.lumXVersions, (version) => version.name);
    const swInitialValue =
      swVersions &&
      swVersions.find((swVersion) => swVersion.name === group.softwareVersion);
    const lumxInitialValue =
      lumXVersions &&
      lumXVersions.find(
        (lumxVersion) => lumxVersion.name === group.lumxVersion
      );

    return (
      <>
        <Form
          submitButtonText={intl.formatMessage(messages.publishUpdateButton)}
          hideSubmitButton={false}
          onSubmit={this.publishUpdate}
          isLoading={isCreateUpdateLoading}
          hideCancelButton={false}
          onCancel={this.onCancel}
        >
          {(form) => {
            const swVersionValue = form.getFieldValue("softwareVersion");
            const lumXVersionValue = form.getFieldValue("lumenisXVersion");

            const isSwVersionsRequired = !lumXVersionValue;
            const isLumXVersionsRequired = !swVersionValue;

            function validateVersionisNotLowerThanTheExistVersion(
              rule,
              value,
              callback
            ) {
              const currentVersion = lumXVersions.find(
                (lumVersion) => lumVersion.id === value
              );
              if (
                lumxInitialValue &&
                currentVersion.name < lumxInitialValue.name
              ) {
                callback(
                  "The version should be greater than the current version " +
                    lumxInitialValue.name
                );
              } else {
                callback();
              }
            }

            return (
              <Row gutter={24}>
                <Col>
                  <DisabledTextField
                    name="groupName"
                    label={intl.formatMessage(messages.groupName)}
                    initialValue={this.props.group.groupName}
                    disabled={true}
                  />
                  <SelectField
                    name="lumenisXVersion"
                    label={intl.formatMessage(messages.lumXVersion)}
                    required={isLumXVersionsRequired}
                    loading={this.props.isFetchVersionsLoading}
                    initialValue={_.get(lumxInitialValue, "id")}
                    rules={[
                      {
                        validator: validateVersionisNotLowerThanTheExistVersion,
                      },
                    ]}
                  >
                    {lumXVersions &&
                      lumXVersions.map((version) => {
                        return (
                          <Select.Option key={version.id} value={version.id}>
                            {version.name}
                          </Select.Option>
                        );
                      })}
                  </SelectField>
                  <NoMarginContainer>
                    <SelectField
                      name="softwareVersion"
                      label={intl.formatMessage(messages.swVersion)}
                      required={isSwVersionsRequired}
                      loading={this.props.isFetchVersionsLoading}
                      initialValue={_.get(swInitialValue, "id")}
                    >
                      {swVersions &&
                        swVersions.map((version) => {
                          return (
                            <Select.Option key={version.id} value={version.id}>
                              {version.name}
                            </Select.Option>
                          );
                        })}
                    </SelectField>
                  </NoMarginContainer>
                  <CheckboxField
                    name="allowRemoteSoftwareUpdate"
                    label={intl.formatMessage(
                      messages.allowRemoteSoftwareUpdate
                    )}
                    initialValue={group.allowRemoteSoftwareUpdate}
                  ></CheckboxField>
                </Col>
              </Row>
            );
          }}
        </Form>
        <div style={{ fontSize: "10px" }}>
          (*) Approval is required only for LumenisX version 2.1 or above
        </div>
      </>
    );
  }
}

export default withErrorBoundary(UpdateGroupVersions);
