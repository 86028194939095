import { connect } from 'react-redux';
import { compose } from 'redux';
import AdfsLogoutComplete from '../components/AdfsLogoutComplete'
import {loginActions} from '../modules/login.slice';


const withConnect = connect(null, loginActions);

export default compose(
	withConnect
)(AdfsLogoutComplete);

