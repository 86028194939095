import { createActionWithScope } from 'containers/App/modules/redux.util';


const SCOPE = 'toasts';

export const showSuccessToast = createActionWithScope(SCOPE, 'showSuccessToast');
export const showErrorToast = createActionWithScope(SCOPE, 'showErrorToast');

export const toastsActions = {
	showSuccessToast,
	showErrorToast
};