import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import { parseFetchAllRequest } from './deviceAlarms.parsers';

const deviceAlarmsEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.DEVICE_ALARMS,
	parseFetchAllRequest
});

const { reducer, actions, selectors } = deviceAlarmsEntitySlice;
const {
	      fetchAll:        fetchDeviceAlarms,
	      fetchAllPending: fetchDeviceAlarmsPending,
	      fetchAllSuccess: fetchDeviceAlarmsSuccess,
	      fetchAllFailure: fetchDeviceAlarmsFailure,
      } = actions;

export const deviceAlarmsEntityActions = {
	fetchDeviceAlarms,
	fetchDeviceAlarmsPending,
	fetchDeviceAlarmsSuccess,
	fetchDeviceAlarmsFailure,
};

const {
	      selectAll:              selectDeviceAlarms,
	      selectFetchAllPending:  selectDeviceAlarmsPending,
		  selectFetchByIdPending: selectFetchDeviceAlarmsPending,
} = selectors;


export const deviceAlarmsEntitySelectors = {
	selectDeviceAlarms,
	selectDeviceAlarmsPending,
	selectFetchDeviceAlarmsPending,
};

export default reducer;