/* eslint import/no-mutable-exports: 0 */
/* eslint prefer-const: 0 */

let PROD_CHINA_CONFIG = {
  API_URL: "https://api.prod.lumenisx.lumenis.com.cn",
  GOOGLE_ANALYTICS_TRACKER_ID: "",
  QLIK_URL: "about:blank",
  LOGIN_VIA_ADFS: process.env.REACT_APP_LOGIN_VIA_ADFS,
  EVENT_VIEWER_URL: 'https://eventviewer.prod.lumenisx.lumenis.com.cn/setup',
  LOG_SUPPORT_VERSION: process.env.REACT_APP_MIN_LMX_VERSION_SUPPORT_LOGS,
  LOG_V2_SUPPORT_VERSION: process.env.REACT_APP_MIN_LMX_VERSION_SUPPORT_LOGS_V2,
  OTHER_REGION_URL: "https://web.prod.lumenisx.lumenis.com/",
  OTHER_REGION_ICON_FILE_PATH: "/global_icon.png",
  HIDE_LICENSES: process.env.REACT_APP_HIDE_LICENSES.toLowerCase() === "true"
};


export default PROD_CHINA_CONFIG;
