import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ALL_VALUE } from 'containers/App/app.constants';
import { SORT_TYPES_MAP } from 'components/DataGrid/dataGrid.constants';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';
import { parseFetchAllRequest as parseAllRequest } from 'containers/App/modules/entities/users/users.parsers';

export const parseFetchAllRequest = payload =>
	parseAllRequest(payload);

export const parseFetchAllSuccess = responseData => {
	return responseData.data;
};

export const prepareUpdateAssignedDevicesRequest = payload => {
	const { devicesIds, groupId } = payload;
	return {
		payload: {
			groupId,
			data: {
				devicesIds
			}
		}
	}
};

export const prepareUpdateAssignedDevicesWithAllModeRequest = payload => {
	const { excludeDevices, search, deviceTypes, locations, groupId } = payload;
	return {
		payload: {
			groupId,
			data: {
				excludeDevices,
				search,
				deviceTypes,
				locations,
			}
		}
	}
};

export const parseFetchByIdSuccess = responseData => {
	const {
		      groups = [],
		      manager,
		      assignedResources,
		      ...restOfgroup
	      } = responseData.data;

	return {
		group:   groups[0],
		manager: manager.groupResponse,
		devices: _.map(assignedResources, resource => ({
			id:          uuidv4(),
			deviceTypes: resource.deviceTypeItems,
			locations:   resource.locationTypeItems,
		})),
		...restOfgroup
	}
};

export const parseCreateRequest = payload => {
	const { deviceType, locations, name } = payload;
	const group = {
		locations,
		name,
		deviceTypeId: deviceType
	};

	return group;
};
export const parseCreateSuccess = payload => {
	const { data, requestParams } = payload;
	const { groupDetails: { groupId } } = data;
	const { resources, group } = requestParams;

	return {
		id:      groupId,
		devices: resources && resources.list ?
			         resources.list.map(resource => ({
				         device: { id: resource.deviceTypeId },
				         region: resource.region
			         })) : [],
		...group,
	}
};

export const parseUpdateRequest = payload => {
	const { groupId, groupValues } = payload;
	const apigroup = parseCreateRequest(groupValues);
	return {
		groupId,
		data: apigroup
	};
};

export const parseUpdateSuccess = payload => {
	return {
		id: payload.requestParams.groupId,
		//todo cannot update group store. responses are different and request is missing data like group group or manager objects.
		// need to normalize data for updates or fetch groups again automatically
	}
};


export const parseFetchPotentialManagersSuccess = response => {
	return _.map(response.lumgroupsSet, groupObj => groupObj.groupResponse)
};
