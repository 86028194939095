import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Upload, Icon, Input } from 'antd';
import { ACTION_STATUS } from 'containers/App/app.constants';

const {TextArea} = Input;

const UploadFile = (props) => {
  const { updateV2CStatus, showSuccessToast, showErrorToast } = props;
  const [updateClicked, setUpdateClicked] = useState(false);

  useEffect(() => {
    if (updateClicked){
      if (updateV2CStatus === ACTION_STATUS.SUCCESS) {
        setUpdateClicked(false);
        showSuccessToast({
          title: 'V2C file was uploaded Successfully',
        })
      } else if (updateV2CStatus === ACTION_STATUS.ERROR) {
        setUpdateClicked(false);
        showErrorToast({
          title: 'V2C file was uploaded Failed',
        })
      }
    }
  }, [updateV2CStatus])


  const formItemLayout = {
    labelCol: {
      span: 3
    },
    wrapperCol: {
      span: 10
    }
  };

  // need to do it (didn't completely understand why)
  const normFile = (e) => {
    return e && e.fileList;
  }
  
  const onBeforeFileUpload = (file) => {
    // cancel uploading the file automatically
    return false;
  }

  const onUpdateV2C = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        setUpdateClicked(true);
        props.updateV2C(values);
      }
    });
  }

  return (
    <div>
      <Form {...formItemLayout} labelAlign="left" onSubmit={onUpdateV2C} >
        <Form.Item label="Upload">
          {props.form.getFieldDecorator('V2C File', {
            valuePropName: 'fileList',
            getValueFromEvent: normFile,
            rules:  [{ required: true, type: 'array', max: 1}]
          })(
            <Upload name="V2C File" beforeUpload={onBeforeFileUpload} >
              <Button>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>,
          )}
        </Form.Item>
        <Form.Item label="Comments">
          {props.form.getFieldDecorator('comments')(
            <TextArea rows={5} />
          )}
        </Form.Item>
        <Form.Item>        
          <Button type="primary" htmlType="submit"
             loading={props.updateV2CStatus === ACTION_STATUS.RUNNING}
          >
            Update Device
          </Button>
        </Form.Item>
      </Form>


      
    </div>
	);
};

UploadFile.propTypes = {
  updateV2C: PropTypes.func.isRequired,
  updateV2CStatus: PropTypes.string.isRequired,
  // showSuccessToast: PropTypes.func.isRequired,
  // showErrorToast: PropTypes.func.isRequired,
};

const validateMessages = {
  required: '%s is required',
  array: {
    max: '%s cannot contain more than %s file'
  }
}

const WrappedUploadFile = Form.create({ name: 'preferences', validateMessages })(UploadFile);

export default WrappedUploadFile;