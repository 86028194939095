import { defineMessages } from 'react-intl';

export const scope = 'entities';

export default defineMessages({
	createEntitySuccess: {
		id: `${scope}.createEntitySuccess`,
		defaultMessage: 'Create {entityType} successful',
		defaultValues: {
			entityType: ''
		}
	},
	updateEntitySuccess: {
		id: `${scope}.updateEntitySuccess`,
		defaultMessage: 'Update {entityType} successful',
		defaultValues: {
			entityType: ''
		}
	},
	users: {
		id: `${scope}.users`,
		defaultMessage: 'Users',
	},
	devices: {
		id: `${scope}.devices`,
		defaultMessage: 'Devices',
	},
	qlik: {
		id: `${scope}.qlik`,
		defaultMessage: 'Qlik',
	},
	user: {
		id: `${scope}.user`,
		defaultMessage: 'User',
	},
	device: {
		id: `${scope}.device`,
		defaultMessage: 'Device',
	},
	group: {
		id: `${scope}.group`,
		defaultMessage: 'Group',
	},
	lumenisxVersion: {
		id: `${scope}.lumenisxVersion`,
		defaultMessage: 'LumenisX Version',
	},
	softwareVersion: {
		id: `${scope}.softwareVersion`,
		defaultMessage: 'Software Version',
	},
	v2c: {
		id: `${scope}.v2c`,
		defaultMessage: 'V2C',
	},
	alarms: {
		id: `${scope}.alarms`,
		defaultMessage: 'Alarms',
	},
	deviceAlarms: {
		id: `${scope}.deviceAlarms`,
		defaultMessage: 'Device Alarms',
	},
});
