const SCOPE = '[api]';

export const ASYNC_ACTION_TYPES = {
	ASYNC_REQUEST_UPDATE: `${SCOPE}/ASYNC_REQUEST_UPDATE`,
	API_CALL:             `${SCOPE}/API_CALL`,
	// API_SUCCESS:          `[${scope}]/API_SUCCESS`,
	// API_FAILURE:          `[${scope}]/API_ERROR`,
};

export const updateRequestStatus = ({ requestName, isLoading, status, error }) => ({
	type:    ASYNC_ACTION_TYPES.ASYNC_REQUEST_UPDATE,
	payload: { requestName, isLoading, status, error }
});

export const makeApiRequest = ({ apiFn, params, actions }) => ({
	type:    ASYNC_ACTION_TYPES.API_CALL,
	payload: { apiFn, params, actions },
	// meta: apiRequestId
});

// export const apiSuccess = (apiRequestId, data) => ({
// 	type: ASYNC_ACTION_TYPES.API_SUCCESS,
// 	payload: data,
// 	meta: apiRequestId
// });
//
// export const apiError = (apiRequestId, error) => ({
// 	type: ASYNC_ACTION_TYPES.API_FAILURE,
// 	payload: error,
// 	meta: apiRequestId
// });

export const ASYNC_ACTION_CREATORS = {
	updateRequestStatus,
	makeApiRequest,
	// apiSuccess,
	// apiError
};