import DeviceServicesTab from "../components/DeviceServicesTab";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  deviceSelectors,
  devicesActions,
} from "pages/Devices/modules/devices.slice";
import {
  deviceEntityActions,
  deviceEntitySelectors,
} from "../../../containers/App/modules/entities/devices/deviceEntity.slice";
import { selectLoggedInUser } from "src/pages/LoginPage/modules/login.selectors";

const mapStateToProps = (state, props) => {
  const loggedInUserId = selectLoggedInUser(state);
  const commands = deviceSelectors.selectCommands(state);
  const commandsHistory = deviceSelectors.selectCommandsHistory(state);

  const fetchCommandsLoading =
    deviceEntitySelectors.selectFetchDeviceCommandsPending(state);
  const startCommandLoading =
    deviceEntitySelectors.selectStartDeviceCommandPending(state);
  const updateCommandsLoading =
    deviceEntitySelectors.selectUpdateDeviceCommandPending(state);
  const availableFiles = deviceSelectors.selectAvailableFiles(state);

  return {
    loggedInUserId,
    commands,
    commandsHistory,
    fetchCommandsLoading,
    startCommandLoading,
    updateCommandsLoading,
    availableFiles,
  };
};

const withConnect = connect(mapStateToProps, {
  ...deviceEntityActions,
  ...devicesActions,
});

export default compose(withConnect)(DeviceServicesTab);
