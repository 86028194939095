import React from 'react';
import PropTypes from 'prop-types';
import invariant from 'invariant';

import { Cascader, Checkbox, Form, Input, Select } from 'antd';
import FormContext from 'components/FormFactory/FormContext';


const requiredRule = { required: true, message: 'This field is required' };
const maxLengthRule = (maxLength) => ({ max: maxLength, message: `Max ${maxLength} characters allowed` });
const lettersOnlyRule = { pattern: /^[a-zA-Z\s]*$/, message: `Only letters allowed` };
const numbersOnlyRule = { pattern: /^[0-9]*$/, message: `Only digits allowed` };

class FormField extends React.Component {

	static propTypes = {
		initialValue:  PropTypes.any,
		label:         PropTypes.any,
		lettersOnly:   PropTypes.bool,
		maxLength:     PropTypes.number, //TODO: maybe this should be only in text fields?
		name:          PropTypes.string.isRequired,
		numbersOnly:   PropTypes.bool,
		required:      PropTypes.bool,
		rules:         PropTypes.array,
		valuePropName: PropTypes.string,
		errorMessage:PropTypes.string,
	};

	render() {
		const {
			      children,
			      initialValue,
			      label,
			      lettersOnly,
			      maxLength,
			      name,
			      numbersOnly,
			      required,
			      rules         = [],
			      valuePropName = 'value',

			      ...componentProps
		      } = this.props;

		invariant(!!children, 'Cannot render FormField without children. For Text input use TextField');

		if (required) {
			rules.push(requiredRule);
		}

		if (maxLength) {
			rules.push(maxLengthRule(maxLength));
		}

		if (lettersOnly) {
			rules.push(lettersOnlyRule);
		}

		if (numbersOnly) {
			rules.push(numbersOnlyRule);
		}
		const {errorMessage}=this.props;

		return (
			<FormContext.Consumer>
				{
					form => {
						if (!form) throw new Error('FormField must be nested within a <Form /> element');

						const { getFieldDecorator } = form;
						const decoratorOptions = {
							rules,
							initialValue,
							valuePropName
						};

						return (
							<Form.Item label={label}  
								help={errorMessage}
								validateStatus={errorMessage ? 'error' : undefined }
							>
								{getFieldDecorator(name, decoratorOptions)(React.cloneElement(children, componentProps))}
							</Form.Item>
						);
					}
				}
			</FormContext.Consumer>
		);
	}
}

export default FormField;