import { call, put, takeLatest, select } from "redux-saga/effects";
import BackendService from "services/BackendService";
import { groupEntityActions } from "containers/App/modules/entities/groups/groupEntity.slice";
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { parseFetchAllSuccess, parseFetchAllRequest } from "containers/App/modules/entities/groups/group.parsers";
import { createEntityFailure, createEntitySuccess } from "containers/App/modules/entities/entities.utils";
import { showErrorToast, showSuccessToast, } from "containers/App/modules/toasts/toasts.actions";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import { ENTITY_TYPES } from "../entities.constants";
import {groupSelectors} from '../../../../../pages/Groups/modules/groups.slice';

function* fetchGroups(action) {
	yield call(api, {
		apiFn: BackendService.fetchGroups,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.group],
		params: action.payload,
		actions: [
			groupEntityActions.fetchGroupsPending,
			groupEntityActions.fetchGroupsSuccess,
			groupEntityActions.fetchGroupsFailure,
		],
	});
}

function* createGroup(action) {
	const params = action.payload;
	yield call(api, {
		apiFn: BackendService.createGroup,
		params,
		actions: [
			groupEntityActions.createGroupPending,
			groupEntityActions.createGroupSuccess,
			groupEntityActions.createGroupFailure,
		],
	});
}

function* updateAssignedDevices(action) {
	const { groupId } = action.payload;

	const data = {
		devices: action.payload.data.devicesIds,
		removeOldGroupIfExists: true
	}

	yield call(api, {
		apiFn: BackendService.updateAssignedDevices,
		params: {data, groupId},
		actions: [
			groupEntityActions.updateAssignedDevicesPending,
			groupEntityActions.updateAssignedDevicesSuccess,
			groupEntityActions.updateAssignedDevicesFailure,
		],
	});
}

function* updateAssignedDevicesSuccess(action) {
	yield put(modalActions.hideModal());
	yield put(
		showSuccessToast({
			title: "Assigned device(s) to group successfully",
		})
	);
}

function* updateAssignedDevicesFailure(action) {
	yield put(
		showErrorToast({
			title: "Assign device(s) to group failed",
		})
	);
}

function* updateAssignedDevicesWithAllMode(action) {
	const { data, groupId } = action.payload;
	data.removeOldGroupIfExists = true

	yield call(api, {
		apiFn: BackendService.updateAssignedDevicesWithAllMode,
		params: {data, groupId},
		actions: [
			groupEntityActions.updateAssignedDevicesWithAllModePending,
			groupEntityActions.updateAssignedDevicesWithAllModeSuccess,
			groupEntityActions.updateAssignedDevicesWithAllModeFailure,
		]})
}

function* fetchVersions(action) {
	const { groupId } = action.payload;

	yield call(api, {
		apiFn:   BackendService.fetchPotentialGroupVersions,
		params:  groupId,
		actions: [
			groupEntityActions.fetchVersionsPending,
			groupEntityActions.fetchVersionsSuccess,
			groupEntityActions.fetchVersionsFailure
		]
	});
}

function* publishVersionToGroup(action) {
	const { payload } = action;

	const request = {
		lumXVersionId: payload.data.lumenisXVersion,
		swVersionId: payload.data.softwareVersion,
		allowRemoteSoftwareUpdate: payload.data.allowRemoteSoftwareUpdate,
	}

	if(!request.lumXVersionId) {
		delete request.lumXVersionId
	}

	if(!request.swVersionId) {
		delete request.swVersionId;
	}

	const groupId = payload.groupId;

	yield call(api, {
		apiFn:   BackendService.patchGroup,
		params:  {data: request, groupId},
		actions: [
			groupEntityActions.publishVersionToGroupPending,
			groupEntityActions.publishVersionToGroupSuccess,
			groupEntityActions.publishVersionToGroupFailure
		]
	});
}

function* publishVersionToGroupSuccess(action) {
  yield put(modalActions.hideModal());
  const groupsGrid = yield select(groupSelectors.selectGroupsGrid);
  yield put(groupEntityActions.fetchGroups(groupsGrid.params));
	yield put(showSuccessToast({
    title: 'Version published to group successfully',
  }))
}

function* publishVersionToGroupFailure(action) {
	yield put(showErrorToast({
    title: 'Version publish to group failed',
  }))
}

function* fetchGroupDevices(action) {
	const params = parseFetchAllRequest(action.payload);
	const groupId = params.groupId;
	delete params.groupId;

	yield call(api, {
		apiFn:   BackendService.fetchGroupDevices,
		params:  {params, groupId},
		actions: [
			groupEntityActions.fetchGroupDevicesPending,
			groupEntityActions.fetchGroupDevicesSuccess,
			groupEntityActions.fetchGroupDevicesFailure
		]
	});
}

function* updateGroup(action) {
	const { groupId, groupValues } = action.payload
	const data = {
		name: groupValues.name
	}

	yield call(api, {
		apiFn:   BackendService.patchGroup,
		params:  {data, groupId},
		actions: [
			groupEntityActions.updateGroupPending,
			groupEntityActions.updateGroupSuccess,
			groupEntityActions.updateGroupFailure
		]
	});
}

function* onUpdateGroupSuccess(action) {
	yield put(modalActions.hideModal());
	const groupsGrid = yield select(groupSelectors.selectGroupsGrid);
	yield put(groupEntityActions.fetchGroups(groupsGrid.params));
	yield put(showSuccessToast({
    title: 'Updated group successfully',
  }))
}

function* updateEntityFailure(action) {
	yield put(modalActions.hideModal());
	yield put(showErrorToast({
    title: 'Update group failed',
  }))
}

export default function* watchGroupsSaga() {
	yield takeLatest(groupEntityActions.fetchGroups, fetchGroups);
	// yield takeLatest(groupEntityActions.fetchGroup, fetchGroup);

	yield takeLatest(groupEntityActions.createGroup, createGroup);
	yield takeLatest(groupEntityActions.createGroupSuccess, createEntitySuccess, ENTITY_TYPES.GROUPS);
	yield takeLatest(groupEntityActions.createGroupFailure, createEntityFailure);

	yield takeLatest(groupEntityActions.updateGroup, updateGroup);
	yield takeLatest(groupEntityActions.updateGroupSuccess, onUpdateGroupSuccess);
	yield takeLatest(groupEntityActions.updateGroupFailure, updateEntityFailure);

	// yield takeLatest(groupEntityActions.updatePreferences, updatePreferences);
	// yield takeLatest(groupEntityActions.updatePreferencesSuccess, onUpdatePreferencesSuccess);
	// yield takeLatest(groupEntityActions.updatePreferencesFailure, onUpdatePreferencesFailure);

	yield takeLatest(groupEntityActions.updateAssignedDevices, updateAssignedDevices);
	yield takeLatest(groupEntityActions.updateAssignedDevicesSuccess, updateAssignedDevicesSuccess);
	yield takeLatest(groupEntityActions.updateAssignedDevicesFailure, updateAssignedDevicesFailure);

	yield takeLatest(groupEntityActions.updateAssignedDevicesWithAllMode, updateAssignedDevicesWithAllMode);
	yield takeLatest(groupEntityActions.updateAssignedDevicesWithAllModeSuccess, updateAssignedDevicesSuccess);
	yield takeLatest(groupEntityActions.updateAssignedDevicesWithAllModeFailure, updateAssignedDevicesFailure);
  	// yield takeLatest(groupEntityActions.updatePreferencesFailure, onUpdatePreferencesFailure);
  
  	yield takeLatest(groupEntityActions.fetchVersions, fetchVersions);
  	yield takeLatest(groupEntityActions.publishVersionToGroup, publishVersionToGroup);
  	yield takeLatest(groupEntityActions.publishVersionToGroupSuccess, publishVersionToGroupSuccess);
  	yield takeLatest(groupEntityActions.publishVersionToGroupFailure, publishVersionToGroupFailure);
  	// yield takeLatest(groupEntityActions.fetchVersionsFailure, fetchVersionsFailure); // TODO: need to handle it. UX is TBD
	
  	yield takeLatest(groupEntityActions.fetchGroupDevices, fetchGroupDevices);
}
