import { call, put, takeLatest } from 'redux-saga/effects';
import BackendService from 'services/BackendService';
import { showErrorToast, showSuccessToast } from 'containers/App/modules/toasts/toasts.actions';
import { api } from 'containers/App/modules/async.saga';
import { preferencesActions } from './preferences.slice';

const parseUpdateUserPreferencesSuccessResponse = response => (
	{
		emailAlarm: response?.settings?.emailAlarm === true, // we might receive it as a string and not a boolean
		smsAlarm: response?.settings?.smsAlarm === true
	});

function* updateUserPreferences(action) {
	const alarmSet = new Set(action?.payload?.alarmChannel);
	const data = {
		settings: { 
			emailAlarm: alarmSet.has('emailAlarm'),
			smsAlarm: alarmSet.has('smsAlarm')
		}
	}

	yield call(api, {
		apiFn:   BackendService.updateUserSettings,
		params:                 { data },
		parseSuccessResponseFn: parseUpdateUserPreferencesSuccessResponse,
		actions: [
			preferencesActions.updateUserPreferencesPending,
			preferencesActions.updateUserPreferencesSuccess,
			preferencesActions.updateUserPreferencesFailure
		]
	});
}

const parseFetchUserPreferencesSuccessResponse = response => (
	{
		emailAlarm: response?.settings?.emailAlarm === true, // we might receive it as a string and not a boolean
		smsAlarm: response?.settings?.smsAlarm === true
	});

function* fetchUserPreferences(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchUserSettings,
		parseSuccessResponseFn: parseFetchUserPreferencesSuccessResponse,
		actions:                [
			preferencesActions.fetchUserPreferencesPending,
			preferencesActions.fetchUserPreferencesSuccess,
			preferencesActions.fetchUserPreferencesFailure
		]
	});
}

function* actionSuccess(title, description) {
    yield put(showSuccessToast({ title, description}));
}

function* actionFailure(title) {
	yield put(showErrorToast({ title }));
}

function* updateUserPreferencesSuccess(action) {
	yield call(actionSuccess, 'Alarms have been updated');
}

function* updateUserPreferencesFailure(action) {
    let errorMessage = "Failed to update user's alarms";

	yield call(actionFailure, errorMessage)
}

function* fetchUserPreferencesFailure(action) {
    let errorMessage = "Failed to fetch user's alarms";

	yield call(actionFailure, errorMessage)
}

export default function* watchUsersSaga() {
	yield takeLatest(preferencesActions.updateUserPreferences, updateUserPreferences);
	yield takeLatest(preferencesActions.updateUserPreferencesSuccess, updateUserPreferencesSuccess);
	yield takeLatest(preferencesActions.updateUserPreferencesFailure, updateUserPreferencesFailure);

	yield takeLatest(preferencesActions.fetchUserPreferences, fetchUserPreferences);
	yield takeLatest(preferencesActions.fetchUserPreferencesFailure, fetchUserPreferencesFailure);

}