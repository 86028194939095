const SCOPE = '[users]';

export const USER_ACTION_TYPES = {

	UPDATE_USER:         `${SCOPE}/UPDATE_USER`,
	UPDATE_USER_REQUEST: `${SCOPE}/UPDATE_USER_REQUEST`,
	UPDATE_USER_SUCCESS: `${SCOPE}/UPDATE_USER_SUCCESS`,
	UPDATE_USER_FAILURE: `${SCOPE}/UPDATE_USER_FAILURE`,

	DELETE_USER:         `${SCOPE}/DELETE_USER`,
	DELETE_USER_REQUEST: `${SCOPE}/DELETE_USER_REQUEST`,
	DELETE_USER_SUCCESS: `${SCOPE}/DELETE_USER_SUCCESS`,
	DELETE_USER_FAILURE: `${SCOPE}/DELETE_USER_FAILURE`,

	DISABLE_USER:         `${SCOPE}/DISABLE_USER`,
	DISABLE_USER_REQUEST: `${SCOPE}/DISABLE_USER_REQUEST`,
	DISABLE_USER_SUCCESS: `${SCOPE}/DISABLE_USER_SUCCESS`,
	DISABLE_USER_FAILURE: `${SCOPE}/DISABLE_USER_FAILURE`,

	ENABLE_USER:         `${SCOPE}/ENABLE_USER`,
	ENABLE_USER_REQUEST: `${SCOPE}/ENABLE_USER_REQUEST`,
	ENABLE_USER_SUCCESS: `${SCOPE}/ENABLE_USER_SUCCESS`,
	ENABLE_USER_FAILURE: `${SCOPE}/ENABLE_USER_FAILURE`,

};


export const clearEditedUser = () => ({
	type: USER_ACTION_TYPES.CLEAR_EDIT_USER
});


export const updateUser = (userId, user) => ({
	type:    USER_ACTION_TYPES.UPDATE_USER,
	payload: { userId, user }
});

export const deleteUser = (userId) => ({
	type:    USER_ACTION_TYPES.DELETE_USER,
	payload: userId
});

export const disableUser = (user) => ({
	type:    USER_ACTION_TYPES.DISABLE_USER,
	payload: user
});

export const enableUser = (user) => ({
	type:    USER_ACTION_TYPES.ENABLE_USER,
	payload: user
});

export const USER_ACTION_CREATORS = {
	updateUser,
	deleteUser,
	disableUser,
	enableUser,
	clearEditedUser
};