import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";

import DeviceProperties from "../components/DeviceProperties";
import {
  deviceEntityActions,
  deviceEntitySelectors,
} from "containers/App/modules/entities/devices/deviceEntity.slice";
import {
  actions as v2cActions,
  selectors as v2cSelectors,
} from "containers/App/modules/entities/v2c/v2cEntity.slice";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import {
  devicesActions,
  deviceSelectors,
} from "pages/Devices/modules/devices.slice";
import { dbSelectors } from "containers/App/modules/entities/entities.selectors";
import entitySchemas from "containers/App/modules/api/schema";
import { hasPermission, PERMISSIONS } from "services/PermissionsService";
import { toastsActions } from "containers/App/modules/toasts/toasts.actions";
import {
  deviceFilesEntityActions,
  deviceFilesEntitySelectors,
} from "containers/App/modules/entities/deviceFiles/deviceFilesEntity.slice";

const mapStateToProps = (state, ownProps) => {
  const editedDeviceId = ownProps.deviceId;
  const device = dbSelectors.selectEntitiesDenormalized(
    entitySchemas.device,
    editedDeviceId
  )(state);

  const assignedUsers = deviceSelectors.selectAssignedUsers(state);
  const canAssignUsers = hasPermission(PERMISSIONS.UI_ASSIGN_USERS_FOR_DEVICE);
  const canUploadV2C = hasPermission(PERMISSIONS.UI_UPLOAD_V2C);
  const canManageLogs = hasPermission(PERMISSIONS.UI_MANAGE_LOGS);

  const fetchAssignedUsersPending =
    deviceEntitySelectors.selectFetchAssignedUsersPending(state);
  const updateAssignedUsersPending =
    deviceEntitySelectors.selectUpdateAssignedUsersPending(state);
  const fetchCustomerDetailsPending =
    deviceEntitySelectors.selectFetchCustomerDetailsPending(state);
  const updateDevicePropertiesPending =
    deviceEntitySelectors.selectUpdateDevicePropertiesPending(state);
  const isLoading = fetchAssignedUsersPending || fetchCustomerDetailsPending;
  const selectDeactivateDevicePending =
    deviceEntitySelectors.selectDeactivateDevicePending(state);
  const selectReactivateDevicePending =
    deviceEntitySelectors.selectReactivateDevicePending(state);
  const selectResetDevicePending =
    deviceEntitySelectors.selectResetDevicePending(state);
  const selectUpdateV2CStatus = v2cSelectors.selectUpdateV2CStatus(state);
  const selectDownloadFilesLoading =
    deviceFilesEntitySelectors.selectDownloadDeviceFilesLoading(state);
  return {
    isLoading,
    assignedUsers,
    device,
    canAssignUsers,
    canUploadV2C,
    canManageLogs,
    isDeactivateDeviceLoading: !!selectDeactivateDevicePending,
    isReactivateDeviceLoading: !!selectReactivateDevicePending,
    isResetDeviceLoading: !!selectResetDevicePending,
    updateV2CStatus: selectUpdateV2CStatus,
    isDownloadFilesLoading: selectDownloadFilesLoading,
  };
};

const withConnect = connect(mapStateToProps, {
  ...devicesActions,
  ...deviceEntityActions,
  ...v2cActions,
  ...toastsActions,
  ...modalActions,
  ...deviceFilesEntityActions,
});

export default compose(withConnect, injectIntl)(DeviceProperties);
