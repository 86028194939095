import React, {useEffect} from 'react';
import styled from 'styled-components';
import Box from 'components/Box';
import { STORAGE_LOGIN_DATA_KEY } from '../../../containers/App/app.constants';
import AdfsLoginContainer from '../containers/AdfsLoginContainer';

export const Page = styled(Box).attrs({
	centered: true
})`
  min-height: 100vh;
  background-color: #142846;
`;

const AdfsLoginPage = () => {

  useEffect(() => {
      sessionStorage.removeItem(STORAGE_LOGIN_DATA_KEY);
  }, [])

  return <Page><AdfsLoginContainer/></Page> 

}

export default AdfsLoginPage;