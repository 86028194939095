import CryptoJS from 'crypto-js';

export const encryptionSha256 = async (file) => {
    let sha256 = CryptoJS.algo.SHA256.create();
    const sliceSize = 10_485_760; // 10 MiB
    let start = 0;

    while (start < file.size) {
        const slice = await readSlice(file, start, sliceSize);
        const wordArray = CryptoJS.lib.WordArray.create(slice);
        sha256 = sha256.update(wordArray);
        start += sliceSize;
    }

    sha256.finalize();
    return sha256._hash.toString(CryptoJS.enc.Hex);
}
export const readSlice = (file, start, size) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        const slice = file.slice(start, start + size);

        fileReader.onload = () => resolve(new Uint8Array(fileReader.result));
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(slice);
    });
}