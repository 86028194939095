import styled from 'styled-components';
import { Button, Typography } from 'antd';
import Box from 'components/Box';


const { Text, Title } = Typography;

export const Page = styled(Box).attrs({
	centered: true
})`
  min-height: 100vh;
  background-color: #142846;
`;

export const FormContainer = styled(Box).attrs({
	direction: 'column',
	centered:  true,
	gutter: 20
})`
    max-width: 310px;
  `;

export const ForgotPasswordLink = styled(Button).attrs({
	type: 'link'
})`
    float: left;
    padding-left: 0 !important; //override antd css
    //margin-right: 50px !important;
  `;

export const WhiteText = styled(Text)`
  &.ant-typography {
    color: #fff;
  }
`;

export const MainTitle = styled(Title)
	.attrs({ level: 2})`
	&.ant-typography {
    color: #fff;
  }
`;