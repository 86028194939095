import { createActionWithScope, createFailureReducer, createPendingReducer, createSuccessReducer, getFormattedScopeName } from 'containers/App/modules/redux.util';
import { createSelector, createSlice } from '@reduxjs/toolkit';


export const STATE_KEY = 'resetPassword';
export const INITIAL_STATE = {};

const prepareResetPasswordRequestFn = payload => {
	const { userid: userId, token: forgotPasswordToken, password } = payload;
	return {
		payload: {
			userId, forgotPasswordToken, password
		}
	};
};

const resetPassword = createActionWithScope(STATE_KEY, 'resetPassword', prepareResetPasswordRequestFn);

const resetPasswordSlice = createSlice({
	name:         getFormattedScopeName(STATE_KEY),
	initialState: INITIAL_STATE,
	reducers:     {
		// resetPassword: NOOP,
		resetPasswordPending: createPendingReducer(),
		resetPasswordSuccess: createSuccessReducer(),
		resetPasswordFailure: createFailureReducer(),
	}
});

export const resetPasswordActions = {
	resetPassword,
	...resetPasswordSlice.actions
};

const getResetPasswordState = state => state.resetPassword;
const selectResetPasswordPending = createSelector(
	getResetPasswordState,
	fpState => fpState.isLoading
);

export const resetPasswordSelectors = {
	selectResetPasswordPending
};
export default resetPasswordSlice.reducer;