import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { GridContainer } from 'components/DataGrid/DataGrid.styled';


const DataGrid = React.forwardRef((props, ref) => {

	const {
		      className,
		      columns,
		      data,
		      filterFormElements,
		      isLoading,
		      onAddNew,
		      onResetFilters,
			  GridTitle,
		      toolbarComponent,

		      ...tableProps
	      } = props;


	return (
		<GridContainer
			className={className}>
			{GridTitle}
			{toolbarComponent}

			<Table
				ref={ref}
				columns={columns}
				dataSource={data}
				loading={isLoading}
				rowKey="id"
				{...tableProps}
			>
				{props.children}
			</Table>

		</GridContainer>
	);
});

DataGrid.propTypes = {
	columns:            PropTypes.array,
	data:               PropTypes.array,
	filterFormElements: PropTypes.element,
	isLoading:          PropTypes.bool,
	onAddNew:           PropTypes.func,
	onResetFilters:     PropTypes.func,
	toolbarComponent:   PropTypes.element,
	GridTitle:			PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default DataGrid;