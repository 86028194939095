import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import _ from 'lodash';
import { NOOP } from 'containers/App/app.constants';
import { getHierarchyData } from 'containers/App/app.utils';
import { createSelector } from '@reduxjs/toolkit';



export const parseFetchAllSuccess = responseData => {
	return _.map(responseData.listOfCountries, country => ({ ...country, id: country.country }));
};

export const parseFetchHierarchySuccess = responseData => {
	return getHierarchyData(responseData.locationItems);

};

export const countriesSlice = createNewEntitySlice({
	name: ENTITY_TYPES.COUNTRIES,
	extraAsyncActions: [
		{
			type:           'fetchLocationsHierarchy',
			setStateDataFn: NOOP
		}
	]
});

const { reducer, actions, selectors } = countriesSlice;
const {
	      fetchAll:        fetchCountries,
	      fetchAllPending: fetchCountriesPending,
	      fetchAllSuccess: fetchCountriesSuccess,
	      fetchAllFailure: fetchCountriesFailure,

	      fetchLocationsHierarchy,
	      fetchLocationsHierarchyPending,
	      fetchLocationsHierarchySuccess,
	      fetchLocationsHierarchyFailure
      } = actions;

export const countriesActions = {
	fetchCountries,
	fetchCountriesPending,
	fetchCountriesSuccess,
	fetchCountriesFailure,

	fetchLocationsHierarchy,
	fetchLocationsHierarchyPending,
	fetchLocationsHierarchySuccess,
	fetchLocationsHierarchyFailure,
};


const {
	      selectAll: selectCountries,
	      selectFetchAllPending: selectCountriesPending
      } = selectors;

const getCountriesState = state => state.entities.countries;
const selectLocationsHierarchyLoading = createSelector(
	getCountriesState,
	locationsState => locationsState.fetchLocationsHierarchyLoading
);

export const countriesSelectors = {
	selectCountries,
	selectCountriesPending,
	selectLocationsHierarchyLoading
};

export default reducer;