"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ApiService = require("./src/ApiService");

var _ApiService2 = _interopRequireDefault(_ApiService);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _ApiService2.default;