import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import { Select } from "antd";
import { appSelectors } from "containers/App/modules/app.slice";
import { dbSelectors } from "containers/App/modules/entities/entities.selectors";
import { ENTITY_TYPES } from "containers/App/modules/entities/entities.constants";

const UserGroupSelector = React.forwardRef((props, ref) => {
  const {
    userGroups,
    dispatch,
    // value,
    // onChange,
    ...rest
  } = props;

  // const newValue = JSON.stringify(value);
  // const onUserGroupSelectorChange = (userGroupStringValue) => onChange && onChange(JSON.parse(userGroupStringValue));

  return (
    <Select
      showArrow={false}
      ref={ref}
      // defaultValue={newValue}
      // onChange={onUserGroupSelectorChange}
      {...rest}
    >
      {userGroups &&
        userGroups.map((userGroup, i) => {
          /*const userGroupString = JSON.stringify(userGroup);
					return (
						<Select.Option key={i} value={userGroupString}>{userGroup.name}</Select.Option>
					);*/
          return (
            <Select.Option key={i} value={userGroup.id}>
              {userGroup.name}
            </Select.Option>
          );
        })}
    </Select>
  );
});

const mapStateToProps = (state, ownProps) => {
  const { allowedOnly = true } = ownProps;
  const selectAllUserGroups = dbSelectors.selectAllEntities(
    ENTITY_TYPES.USER_GROUPS
  );
  const allUserGroups = _.sortBy(selectAllUserGroups(state), "name");
  return {
    userGroups: allowedOnly
      ? appSelectors.selectAllowedUserGroups(state)
      : allUserGroups,
  };
};

export default compose(
  connect(mapStateToProps, null, null, { forwardRef: true })
)(UserGroupSelector);
