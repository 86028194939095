import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormField from "components/FormFactory/FormField";
import { Button, Upload, Icon } from "antd";
import FormContext from "components/FormFactory/FormContext";
import { injectIntl } from "react-intl";
import messages from "../messages";

const FileBrowserField = (props) => {
	const { autoFocus, intl } = props;
	const inputRef = React.createRef();
	const clickToUploadText = intl.formatMessage(messages.clickToUpload);

	useEffect(() => {
		if (autoFocus) {
			inputRef.current.focus();
		}
	}, []);

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}

		return e && e.fileList;
	};

	return (
		<FormField {...props}>
			<FormContext.Consumer>
				{(form) =>
					form.getFieldDecorator("upload", {
						valuePropName: "fileList",
						getValueFromEvent: normFile,
						rules:  [{ required: true, type: 'array', max: 1}]
					})(
						<Upload
							name="upload"
							beforeUpload={() => {
								return false;
							}}
						>
							<Button>
								<Icon type="upload" />
								{clickToUploadText}
							</Button>
						</Upload>
					)
				}
			</FormContext.Consumer>
		</FormField>
	);
};

FileBrowserField.propTypes = {
	...FormField.propTypes,
	autoFocus: PropTypes.bool,
	intl: PropTypes.object.isRequired,
};

export default injectIntl(FileBrowserField);
