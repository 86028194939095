import { createAction, createSlice } from '@reduxjs/toolkit';
import { EMPTY_OBJECT, NOOP } from 'containers/App/app.constants';
import _ from 'lodash';
import invariant from 'invariant';
import { DEFAULT_DATA_PROP, DEFAULT_ERROR_PROP, DEFAULT_LOADING_PROP, ERROR_PROP_SUFFIX, FAILURE_ACTION_TYPE_SUFFIX, PENDING_ACTION_TYPE_SUFFIX, PENDING_PROP_SUFFIX, SUCCESS_ACTION_TYPE_SUFFIX } from 'containers/App/modules/redux.contants';


export const getFormattedScopeName = sliceName => `[${sliceName}]`;

export const createActionWithScope = (scope, actionType, prepareFn) =>
	createAction(`${getFormattedScopeName(scope)}/${actionType}`, prepareFn);


export const createPendingReducer = ({
	                                     loadingProp = DEFAULT_LOADING_PROP
                                     } = EMPTY_OBJECT) =>
	(state, action) => {
		state[loadingProp] = true;
	};

const defaultSetStateFn = (state, action) => state[DEFAULT_DATA_PROP] = action.payload;

export const createSuccessReducer = ({
	                                     loadingProp = DEFAULT_LOADING_PROP,
	                                     errorProp = DEFAULT_ERROR_PROP,
	                                     setStateDataFn = defaultSetStateFn
                                     } = EMPTY_OBJECT) =>
	(state, action) => {
		state[loadingProp] = false;
		delete state[errorProp];
		setStateDataFn(state, action);
	};

export const createFailureReducer = ({
	                                     loadingProp = DEFAULT_LOADING_PROP,
	                                     errorProp = DEFAULT_ERROR_PROP,
                                     } = EMPTY_OBJECT) =>
	(state, action) => {
		state[loadingProp] = false;
		state[errorProp] = action.payload;
	};

const defaultPrepareFn = payload => ({ payload });
/**
 *
 * @param action { type, requestPrepareFn?, successPrepareFn?, failurePrepareFn? }
 * @returns object with async case reducers for action
 */
export const createAsyncReducer = ({
	                                   type,
	                                   requestPrepareFn = defaultPrepareFn,
	                                   successPrepareFn = defaultPrepareFn,
	                                   failurePrepareFn = defaultPrepareFn,
	                                   setStateDataFn
                                   }) => {
	// checkAction(action);
	invariant(!!type, 'type is required');

	const loadingProp = `${type + PENDING_PROP_SUFFIX}`;
	const errorProp = `${type + ERROR_PROP_SUFFIX}`;

	return {
		[type]: {
			reducer: NOOP, //TODO find a better way? probably drop this redundant action
			prepare: requestPrepareFn
		},

		[`${type + PENDING_ACTION_TYPE_SUFFIX}`]: {
			reducer: createPendingReducer({ loadingProp }),
		},

		[`${type + SUCCESS_ACTION_TYPE_SUFFIX}`]:
			{
				reducer: createSuccessReducer({ loadingProp, errorProp, setStateDataFn }),
				prepare: successPrepareFn
			},

		[`${type + FAILURE_ACTION_TYPE_SUFFIX}`]:
			{
				reducer: createFailureReducer({ loadingProp, errorProp }),
				prepare: failurePrepareFn
			},
	}
};


export const createEntitySlice = ({
	                                  name,
	                                  schema,
	                                  asyncActionsObjects = [],
	                                  extraReducers,
	                                  extraActions
                                  }) => {
	const caseReducers = asyncActionsObjects.map(action => createAsyncReducer(action));
	const reducers = _.reduce(caseReducers, (acc, reducer) => ({ ...acc, ...reducer }), {});
	return createSlice({
		name:         getFormattedScopeName(name),
		initialState: ASYNC_INITIAL_STATE,
		reducers,
		extraReducers
	});
};

