import produce from 'immer';
import { ASYNC_ACTION_TYPES } from 'containers/App/modules/async.actions';

export const ASYNC_STATE_KEY = 'async';

export const INITIAL_STATE = {};

const ACTION_HANDLERS = {
    [ASYNC_ACTION_TYPES.ASYNC_REQUEST_UPDATE]: (state, action) => {
        const { requestName, isLoading, status, error } = action.payload;

        return produce(state, draft => {
            draft[requestName] = {
                isLoading, status, error
            };
        });
    },
};

//TODO remove or convert to slice
export default function asyncReducer(state = INITIAL_STATE, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}