import { call, put, fork, takeLatest, select} from 'redux-saga/effects';
import { push, getLocation } from 'connected-react-router';
import { consentActions, consentSelectors} from './consent.slice';
import { api } from 'containers/App/modules/async.saga';
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import BackendService from '../../../services/BackendService'
import { MODAL_TYPES } from '../../../containers/ModalRoot/components/constants';
import { parseFetchAllSuccess, createConfirmConsentRequest } from "./consent.parsers";
import { loginActions } from 'pages/LoginPage/modules/login.slice';
import { showErrorToast } from 'containers/App/modules/toasts/toasts.actions';
import { APP_ROUTES } from '../../../containers/App/app.constants';

export function* fetchUserConsents() {
	yield call(api, {
		apiFn:   BackendService.fetchWaitingForApproveConsents,
        parseSuccessResponseFn: parseFetchAllSuccess,
		actions: [
			consentActions.fetchUserConsentsPending,
			consentActions.fetchUserConsentsSuccess,
			consentActions.fetchUserConsentsFailure,
		]
	});
}

export function* fetchUserConsentsSuccess(action) {
    const consents = action.payload.data;
    if(consents && consents.length > 0){
        // at least one consent is waiting to be approved
        for (const consent of consents) {
            yield fork(getConsent,consent)
        }
        
        yield put(modalActions.showModal({
            modalType:      MODAL_TYPES.CONSENT_MODAL,
            modalProps:     {
                title:  "Terms & Conditions",
                footer: null,
                width:  700,
                closable: false,
                confirmLoading: true,
            }
        }));
    } else {
        const locationObj = yield select(getLocation);
        if(locationObj.pathname === APP_ROUTES.LOGIN || locationObj.pathname === APP_ROUTES.ADFS_LOGIN ){
            yield put(push('/'));
        }// else - keep the url as it is and redirect to there
    }
}

export function* fetchUserConsentsFailure() {
	yield put(
		showErrorToast({
			title: 'Failed to load consents',
		}));
}

function* getConsent(consentData) {
    const localeName = consentData?.availableLocales[0].name;
    const consentId = consentData?.id;
    yield call(api, {
        apiFn:   BackendService.getConsent,
        params:  {consentId, localeName},
        actions: [
            consentActions.getConsentPending,
            consentActions.getConsentSuccess,
            consentActions.getConsentFailure,
        ]
    });
}

function* confirmConsent(index) {
    const consentDetails = yield select(consentSelectors.getConsentDetails,index)
    yield call(api, {
        apiFn:   BackendService.confirmConsent,
        params:  {data: createConfirmConsentRequest(consentDetails)},
        actions: [
            consentActions.confirmConsentPending,
            consentActions.confirmConsentSuccess,
            consentActions.confirmConsentFailure,
        ]
    });
}

function* confirmConsentSuccess() {
    const areAllConfirmed = yield select(consentSelectors.getAreAllConsentsConfirmed);
    if(areAllConfirmed){
        yield put(modalActions.hideModal());
        yield put(push('/'));
    } 
}

function* confirmConsentFailure() {
	yield put(modalActions.hideModal());
	yield put(
		showErrorToast({
			title: 'Consent Confirmation Failed',
		}));
}

function* fetchAllUserConfirmedConsents() {
    yield call(api, {
        apiFn:   BackendService.getAllUserConfirmedConsents,
        actions: [
            consentActions.fetchUserConfirmedConsentsPending,
            consentActions.fetchUserConfirmedConsentsSuccess,
            consentActions.fetchUserConfirmedConsentsFailure,
        ]
    });
}

function* fetchUserConfirmedConsentsSuccess(action) {
    const consentId = action.payload?.data?.consents?.[0]?.id;
    yield call(api, {
        apiFn:   BackendService.getUserConfirmedConsent,
        params:  {consentId},
        actions: [
            consentActions.getUserConfirmedConsentPending,
            consentActions.getUserConfirmedConsentSuccess,
            consentActions.getUserConfirmedConsentFailure,
        ]
    });
}

export default function* watchConsentSaga() {
	yield takeLatest([loginActions.loginSuccess, consentActions.fetchUserConsents], fetchUserConsents);
    yield takeLatest(consentActions.fetchUserConsentsSuccess, fetchUserConsentsSuccess);
    yield takeLatest(consentActions.fetchUserConsentsFailure, fetchUserConsentsFailure);
    yield takeLatest(consentActions.confirmConsent, confirmConsent);
    yield takeLatest(consentActions.confirmConsentSuccess, confirmConsentSuccess);
    yield takeLatest(consentActions.confirmConsentFailure, confirmConsentFailure);
    yield takeLatest(consentActions.fetchAllUserConfirmedConsents, fetchAllUserConfirmedConsents);
    yield takeLatest(consentActions.fetchUserConfirmedConsentsSuccess, fetchUserConfirmedConsentsSuccess);
}